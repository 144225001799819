import { useNavigate, Link } from 'react-router-dom';
import EncabezadoCredGp from '../../assets/images/fondo-slide-campana-credito-forjadoras-de-corazon.jpg';
import EncabezadoCredGp2 from '../../assets/images/fondo-slide-campana-credito-forjadoras-de-corazon-movil.jpg';
import CredGrupal from "../../assets/images/credito_grupal.png";
import CredAdi from "../../assets/images/credito-adicional.png";
import Credinter from "../../assets/images/credito-interciclo.png";
import AsisMed from "../../assets/images/icono-inicio-asistencia-medica.png";
import AsisFun from "../../assets/images/icono-inicio-asistencia-funeraria.png";
import AsisDen from "../../assets/images/icono-inicio-asistencia-dental.png";
import CobHogar from "../../assets/images/icono-inicio-cobertura-hogar.png";
import PagareForj from "../../assets/images/grafico-pagare-forjadores.png";
import ForjaCEDE from "../../assets/images/grafico-forjadores-cede.png";
import RecomGana from "../../assets/images/grafico-recomienda-y-gana.png";
import BannerTips from "../../assets/images/banner-tips.jpg";
import BannerTips2 from "../../assets/images/banner-tips-movil.jpg";
import BannerCov from "../../assets/images/banner-informacion-de-interes-covid.jpg";
import BannerCov2 from "../../assets/images/banner-informacion-de-interes-covid-movil.jpg";
import LogoBuro from "../../assets/images/Logos/buro.png";
import BancoForja from "../../assets/images/banco-forjadores-medios-de-denuncia.jpg";
import BancoForja2 from "../../assets/images/banco-forjadores-medios-de-denuncia-movil.jpg";

import '../../style/inicio.css';


function Inicio() {
	return (
		<div className="site-content">

			<div className="headsite">
				<div className="textcont">
					<div className="textfix">
						<div className="texthead">
							Te <b>mejoramos</b> las condiciones de tu <b>crédito grupal</b> actual
						</div>
						<div className="conocebtn">
							<Link className="" to="/forjadoras-de-corazon" tabindex="0">
							<div> <div>CONOCE MÁS</div> </div>
							</Link>
						</div>
					</div>

				</div>
				<picture>
					<source media="(min-width: 768px)" srcset={EncabezadoCredGp} />
					<source media="(min-width: 320px)" srcset={EncabezadoCredGp2} />
					<img src={EncabezadoCredGp} alt='' />
				</picture>
			</div>
			<p className="homeIntro">En <b>Banco Forjadores</b> tenemos <b>productos</b> que se adaptan a cada una de tus <b>necesidades</b>.</p>

			<div id="personalCredits" className="service-preview">
				<h2>CRÉDITOS <br></br> <Link to="/creditos/">VER TODOS LOS CRÉDITOS &gt;</Link></h2>
				<div className="container">
					<Link to="/creditos/credito-grupal/">
						<img src={CredGrupal} />
						<p><big>Crédito Grupal</big> <b>Más información &gt;</b></p>
					</Link>
					<Link to="/creditos/credito-adicional/">
						<img src={CredAdi} />
						<p><big>Crédito Adicional</big> <b>Más información &gt;</b></p>
					</Link>
					<Link to="/creditos/credito-interciclo/">
						<img src={Credinter} />
						<p><big>Crédito Interciclo</big> <b>Más información &gt;</b></p>
					</Link>
				</div>
			</div>
			<div id="careOptions" className="service-preview">
				<h2>ASISTENCIAS <br></br> 
				<Link to="/seguros/">VER COBERTURAS DISPONIBLES &gt;</Link>
				</h2>
				<div className="container">
					<a>
						<img src={AsisMed} />
						<p><big>Asistencia Médica</big></p>
					</a>
					<a>
						<img src={AsisFun} />
						<p><big>Asistencia Funeraria</big></p>
					</a>
					<a>
						<img src={AsisDen} />
						<p><big>Asistencia Dental</big></p>
					</a>
					<a>
						<img src={CobHogar} />
						<p><big>Cobertura Hogar</big></p>
					</a>

					<Link to="/asistencias-combo/">
						<p><b>Más información &gt;</b></p>
					</Link>
				</div>
			</div>
			<div id="investmentOptions" className="service-preview">
				<h2>INVERSIONES <br></br> <Link to="/seguros/">VER OPCIONES DE INVERSIÓN &gt;</Link></h2>
				<div className="container">
					<Link to="/inversiones/pagare-forjadores/">
						<img src={PagareForj} alt='PAGARE Forjadores' />
						<p><big><b>PAGARÉ</b> Forjadores<small>®</small></big> <b>Más información &gt;</b></p>
					</Link>
					<Link to="/inversiones/forjadores-cede/">
						<img src={ForjaCEDE} alt='Forjadores CEDE' />
						<p><big>Forjadores <b>CEDE<small>®</small></b></big> <b>Más información &gt;</b></p>
					</Link>
				</div>
			</div>
			<div id="rewardsOptions" className="service-preview">
				<h2>PROGRAMAS DE BENEFICIOS <br></br><Link to="/recompensas/">VER RECOMPENSAS Y PROMOCIONES &gt;</Link></h2>
				<div className="container">
					<Link to="/recompensas/recomienda-y-gana/">
						<img src={RecomGana} alt='Recomienda y Gana' />
						<p><big>Recomienda y Gana</big> <b>Más información &gt;</b></p>
					</Link>
				</div>
			</div>
			<div id="tipsBanner" className="home-banner">
				<picture>
					<source media="(min-width: 768px)" srcset={BannerTips}/>
					<source media="(min-width: 320px)" srcset={BannerTips2}/>
					<img src={BannerTips} alt='Banner Tips para Mejorar tu Negocio' />
				</picture>
				<div>
					<h2>TIPS PARA 
						<big>MEJORAR</big> 
						<b>TU NEGOCIO</b>
					</h2>
					<Link className="forjadores-btn" to="/quienes-somos/tips-para-mejorar-tu-negocio/">
						Conocer tips
					</Link>
				</div>
			</div>
			<div id="covidBanner" className="home-banner">
				<picture>
					<source media="(min-width: 768px)" srcset={BannerCov} />
					<source media="(min-width: 320px)" srcset={BannerCov2} />
					<img src={BannerCov} alt='Banner Información de interés sobre COVID' />
				</picture>
				<div>
					<h2><b>INFORMACIÓN</b> DE INTERÉS SOBRE <big>COVID</big></h2>
					<Link className="forjadores-btn" to="/seguros/covid/informacion-de-interes-sobre-covid/">ver información</Link>
				</div>
			</div>
			<span id="homeFooter">
				<img src={LogoBuro} alt='Buro' />
				<p>El Buró de Entidades Financieras contiene información de Banco Forjadores S.A., Institución de Banca Múltiple sobre las características de nuestros productos y nuestro desempeño frente a los Usuarios en la prestación de servicios. Te invitamos a consultarlo en la página <a href="https://www.buro.gob.mx/" rel="noopener" target="_blank">https://www.buro.gob.mx/</a> o en nuestra página de internet <Link to="/buro-de-entidades-financieras/" rel="noopener" target="_blank">https://www.forjadores.mx/buro-de-entidades-financieras/</Link>
				</p>
			</span>
			<div id="complaintBanner">
				<picture>
					<source media="(min-width: 768px)" srcset={BancoForja} />
					<source media="(min-width: 320px)" srcset={BancoForja2} />
					<img src={BancoForja} alt='Banner Medios de denuncia' />
				</picture>
				<div>
					<h2>Ponemos a su disposición <b>los siguientes medios de</b> <big>denuncia</big></h2>

					<p><big>En caso de que hayas sido víctima de algún cobro indebido, mala práctica o algún incidente que haya afectado sus intereses por parte de nuestro personal.</big></p>

					<p>Línea directa <a href="tel:5589110149">55 89 11 01 49</a><br /> Conmutador <a href="tel:5556112450;1822">55 56 11 24 50 ext. 1822</a> y al <br /> correo electrónico <a href="mailto:somosforjadores@forjadores.com.mx">somosforjadores@forjadores.com.mx</a></p>
				</div>
			</div>
			
		</div>
);
}

export default Inicio;
