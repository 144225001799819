import { create } from 'zustand';

const UsePuntosPagoStore = create((set) => ({
	puntosPago: [],
	loadingPuntosPago: false,
	getPuntosPago: async () => {
		try {
			set({ loadingPuntosPago: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					'/api/Informacion/PuntosPago',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ puntosPago: data });
			set({ loadingPuntosPago: false });

			return data;
		} catch (error) {
			console.error('Error al obtener los PuntosPago');
			set({ loadingPuntosPago: false });
		}
	},
}));

export default UsePuntosPagoStore;
