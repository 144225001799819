import { create } from 'zustand';

const UseCorreosStore = create((set) => ({
	enviarCorreo: async (correo) => {
		try {
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/api/Envio',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
					body: JSON.stringify(correo),
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
				return false;
			}

			console.log('¡Correo enviado con exito!');
			return true;
		} catch (error) {
			console.error('Error al eliminar el acuerdo AGOA');
			return false;
		}
	},
}));

export default UseCorreosStore;
