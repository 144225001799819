import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import carpetaverde from '../../../assets/images/Iconos/informacion_carpeta_corporativa.png';

function EstatutosSociales() {
	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ESTATUTOS SOCIALES</small>
					</h1>
				</div>
				<div className="estatutos-folders folders">
					<Link to="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/estatutos-sociales/">
						<img src={carpetaverde} />
						Estatutos Sociales
					</Link>

					<Link to="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/modificacion-a-los-estatutos/">
						<img src={carpetaverde} /> Modificaciones a los
						Estatutos
					</Link>
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default EstatutosSociales;
