import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import icono_pdf from '../../../assets/images/Iconos/icono_pdf.png';

import Acuerdos_Asamblea_de_Tenedores_Bforja1_20 from '../../../assets/documents/Acuerdos-Asamblea-de-Tenedores-Bforja1-20.pdf';
import Asamblea_Tenedores_BForja_2_20 from '../../../assets/documents/Asamblea-Tenedores-BForja-2-20.pdf';
import Acuerdos_Asamblea_de_Tenedores_Bforja1_21 from '../../../assets/documents/Acuerdos-Asamblea-de-Tenedores-Bforja1-21.pdf';
import Asamblea_Tenedores_BForja_1_22 from '../../../assets/documents/Asamblea-Tenedores-BForja-1-22.pdf';

function AsambleasTenedores2022() {
	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ASAMBLEAS DE TENEDORES 2022</small>
					</h1>
				</div>
				<div className="estatutos-folders information-files">
					<Link
						className="file"
						style={{
							width: '15%',
                            padding: "2.5%",
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Acuerdos_Asamblea_de_Tenedores_Bforja1_20}
					>
						<img src={icono_pdf} />
						Asamblea de Tenedores Bforja1-20
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
                            padding: "2.5%",
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Asamblea_Tenedores_BForja_2_20}
					>
						<img src={icono_pdf} />
						Asamblea Tenedores BForja 2-20
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
                            padding: "2.5%",
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Acuerdos_Asamblea_de_Tenedores_Bforja1_21}
					>
						<img src={icono_pdf} />
						Asamblea de Tenedores Bforja1-21
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
                            padding: "2.5%",
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Asamblea_Tenedores_BForja_1_22}
					>
						<img src={icono_pdf} />
						Asamblea Tenedores BForja 1-22
					</Link>
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default AsambleasTenedores2022;
