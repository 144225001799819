import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import Estados_Financieros_12013 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2013.pdf';
import Estados_Financieros_22013 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2013.pdf';
import Estados_Financieros_12015 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2015.pdf';
import Estados_Financieros_22015 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2015.pdf';
import Estados_Financieros_32015 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2015.pdf';
import Estados_Financieros_42015 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2015.pdf';
import Estados_Financieros_12016 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2016.pdf';
import Estados_Financieros_22016 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2016.pdf';
import Estados_Financieros_32016 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2016.pdf';
import Estados_Financieros_42016 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2016.pdf';
import Estados_Financieros_12017 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2017.pdf';
import Estados_Financieros_22017 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2017.pdf';
import Estados_Financieros_32017 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2017.pdf';
import Estados_Financieros_42017 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2017.pdf';
import Estados_Financieros_12018 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2018.pdf';
import Estados_Financieros_22018 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2018.pdf';
import Estados_Financieros_32018 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2018.pdf';
import Estados_Financieros_42018 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2018.pdf';
import Estados_Financieros_12019 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2019.pdf';
import Estados_Financieros_22019 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2019.pdf';
import Estados_Financieros_32019 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2019.pdf';
import Estados_Financieros_42019 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2019.pdf';
import Estados_Financieros_12020 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2020.pdf';
import Estados_Financieros_22020 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2020.pdf';
import Estados_Financieros_32020 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2020.pdf';
import Estados_Financieros_42020 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2020.pdf';
import Estados_Financieros_12021 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2021.pdf';
import Estados_Financieros_22021 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2021.pdf';
import Estados_Financieros_32021 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2021.pdf';
import Estados_Financieros_42021 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-financieros-4T-2021.pdf';
import Estados_Financieros_12022 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2022-1.pdf';
import Estados_Financieros_22022 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2022.pdf';
import Estados_Financieros_32022 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2022-NOTAS.pdf';
import Estados_Financieros_42022 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2022.pdf';
import Estados_Financieros_12023 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2023.pdf';
import Estados_Financieros_22023 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2023.pdf';
import Estados_Financieros_32023 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-3T-2023.pdf';
import Estados_Financieros_42023 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-4T-2023.pdf';
import Estados_Financieros_12024 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-1T-2024.pdf';
import Estados_Financieros_22024 from '../../../../assets/documents/InformacionFinanciera/InformacionTrimestral/Estados-Financieros-2T-2024.pdf';

import '../../../../style/quienessomos.css';

function InfoTrim() {
    return (
        <div class="post-inner">
            <div class="entry-content">
                <div class="information-intro">
                    <img src={encabezado} decoding="async" class="lazyloaded" data-ll-status="loaded" />
                    <h1><small>INFORMACIÓN TRIMESTRAL</small></h1>
                </div>
                <div class="information-files">                    
                    <a href={Estados_Financieros_12013} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2013</a>
                    <a href={Estados_Financieros_22013} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2013</a>

                    <a href={Estados_Financieros_12015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2015</a>
                    <a href={Estados_Financieros_22015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2015</a>
                    <a href={Estados_Financieros_32015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2015</a>
                    <a href={Estados_Financieros_42015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2015</a>

                    <a href={Estados_Financieros_12016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2016</a>
                    <a href={Estados_Financieros_22016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2016</a>
                    <a href={Estados_Financieros_32016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2016</a>
                    <a href={Estados_Financieros_42016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2016</a>

                    <a href={Estados_Financieros_12017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2017</a>
                    <a href={Estados_Financieros_22017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2017</a>
                    <a href={Estados_Financieros_32017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2017</a>
                    <a href={Estados_Financieros_42017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2017</a>

                    <a href={Estados_Financieros_12018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2018</a>
                    <a href={Estados_Financieros_22018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2018</a>
                    <a href={Estados_Financieros_32018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2018</a>
                    <a href={Estados_Financieros_42018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2018</a>

                    <a href={Estados_Financieros_12019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2019</a>
                    <a href={Estados_Financieros_22019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2019</a>
                    <a href={Estados_Financieros_32019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2019</a>
                    <a href={Estados_Financieros_42019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2019</a>

                    <a href={Estados_Financieros_12020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2020</a>
                    <a href={Estados_Financieros_22020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2020</a>
                    <a href={Estados_Financieros_32020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2020</a>
                    <a href={Estados_Financieros_42020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2020</a>

                    <a href={Estados_Financieros_12021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2021</a>
                    <a href={Estados_Financieros_22021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2021</a>
                    <a href={Estados_Financieros_32021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2021</a>
                    <a href={Estados_Financieros_42021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2021</a>

                    <a href={Estados_Financieros_12022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2022</a>
                    <a href={Estados_Financieros_22022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2022</a>
                    <a href={Estados_Financieros_32022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2022</a>
                    <a href={Estados_Financieros_42022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2022</a>

                    <a href={Estados_Financieros_12023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2023</a>
                    <a href={Estados_Financieros_22023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2023</a>
                    <a href={Estados_Financieros_32023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 3T 2023</a>
                    <a href={Estados_Financieros_42023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 4T 2023</a>

                    <a href={Estados_Financieros_12024} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 1T 2024</a>
                    <a href={Estados_Financieros_22024} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros 2T 2024</a>
                </div>
                <p></p>
            </div>
        </div>
    );
}

export default InfoTrim;