
import '../../style/infohead.css';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';

import encabezado from '../../assets/images/Encabezados/encabezado-oficinas-de-servicio.jpg';
import sucursales_lineas from '../../assets/images/sucursales_lineas.png';
import aguascalientes from '../../assets/images/sucursales_Aguascalientes_.png';
import edomex from '../../assets/images/sucursales_EdoMex.png';
import guanajuato from '../../assets/images/sucursales_Guanajuato.png';
import michoacan from '../../assets/images/sucursales_Michoacan.png';
import morelos from '../../assets/images/sucursales_Morelos.png';
import oaxaca from '../../assets/images/sucursales_Oaxaca.png';
import puebla from '../../assets/images/sucursales_Puebla.png';
import queretaro from '../../assets/images/sucursales_Queretaro.png';
import veracruz from '../../assets/images/sucursales_Veracruz.png';
import tlaxcala from '../../assets/images/sucursales_tlaxcala.png';

function OficinaServicio() {
    const [openModal_aguascalientes, setOpenModal_aguascalientes] = useState(false);
    const [openModal_edomex, setOpenModal_edomex] = useState(false);
    const [openModal_guanajuato, setOpenModal_guanajuato] = useState(false);
    const [openModal_michoacan, setOpenModal_michoacan] = useState(false);
    const [openModal_morelos, setOpenModal_morelos] = useState(false);
    const [openModal_oaxaca, setOpenModal_oaxaca] = useState(false);
    const [openModal_puebla, setOpenModal_puebla] = useState(false);
    const [openModal_queretaro, setOpenModal_queretaro] = useState(false);
    const [openModal_tlaxcala, setOpenModal_tlaxcala] = useState(false);
    const [openModal_veracruz, setOpenModal_veracruz] = useState(false);
    return (
        <div className="entry-content infoedos">

            <div className="branchesIntro">
                <img src={encabezado} alt=''/>
                <p>Banco Forjadores tiene presencia en <b>10 estados</b> de la República Mexicana con <b>31 Oficinas de Servicio</b>. Da clic en el estado que desees consultar.</p>
            </div>

            <div className="branchesContainer">
                <div data-target="aguascalientes">
                    <img src={aguascalientes} />
                    <p onClick={() => {
                        setOpenModal_aguascalientes(true);
                    }}><b>Aguascalientes</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="edomex">
                    <img src={edomex} />
                    <p onClick={() => {
                        setOpenModal_edomex(true);
                    }}><b>Estado de México</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="guanajuato">
                    <img src={guanajuato} />
                    <p onClick={() => {
                        setOpenModal_guanajuato(true);
                    }}><b>Guanajuato</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="michoacan">
                    <img src={michoacan} />
                    <p onClick={() => {
                        setOpenModal_michoacan(true);
                    }}><b>Michoacán</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="morelos">
                    <img src={morelos} />
                    <p onClick={() => {
                        setOpenModal_morelos(true);
                    }}><b>Morelos</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="oaxaca">
                    <img src={oaxaca} />
                    <p onClick={() => {
                        setOpenModal_oaxaca(true);
                    }}><b>Oaxaca</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="puebla">
                    <img src={puebla} />
                    <p onClick={() => {
                        setOpenModal_puebla(true);
                    }}><b>Puebla</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="queretaro">
                    <img src={queretaro} />
                    <p onClick={() => {
                        setOpenModal_queretaro(true);
                    }}><b>Querétaro</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="tlaxcala">
                    <img src={tlaxcala} />
                    <p onClick={() => {
                        setOpenModal_tlaxcala(true);
                    }}><b>Tlaxcala</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="veracruz">
                    <img src={veracruz} />
                    <p onClick={() => {
                        setOpenModal_veracruz(true);
                    }}><b>Veracruz</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <img src={sucursales_lineas} />
            </div>

            {/* MODALES */}
            <Modal open={openModal_aguascalientes} setOpen={setOpenModal_aguascalientes}>
                <h3 className="subtitulo-3"> Aguascalientes</h3>
                <span>
                    <p>Aguascalientes</p> <a href="tel:4499156927">449 915 6927</a> <br/>
                    <a href="https://goo.gl/maps/gJ7YZiPpkdwUYTd29" target="_blank">José María Chávez No. 453, Col. Obraje, C.P. 20120, Municipio de Aguascalientes, Aguascalientes.</a>
                </span>
            </Modal>
            <Modal open={openModal_edomex} setOpen={setOpenModal_edomex}>
                <h3 className="subtitulo-3">edomex</h3>
                <span>
                    <p>Atizapán</p><a href="tel:5516681295">55 1668 1295</a><br />
                    <a href="https://goo.gl/maps/Y52Ysn1XnSqX26LJ6" target="_blank">Calle Paseo de las Palomas No. 2  MZ 16, LT. 29, 30, 31, Col. Fraccionamiento Las Alamedas, Municipio de Atizapán, C.P 52970</a>
                </span>
                <span>
                    <p>Atlacomulco</p><a href="tel:7121200811">712 120 0811</a><br />
                    <a href="https://goo.gl/maps/5nF4XAxgaLt3U9MRA" target="_blank">Calle Antonio Velez No. 3 en Plaza Estrella, Local 23, 24 y 25, Col. Centro Atlacomulco, Edo. de México, C.P. 50450</a>
                </span>
                <span>
                    <p>Ecatepec</p><a href="tel:5511154852">55 1115 4852</a><br />
                    <a href="https://goo.gl/maps/8AMwX2ekDNPJbfyp8" target="_blank">Av. Morelos Oriente No. 167 local 6, Col. San Cristobal Centro, C.P. 55080</a>
                </span>
                <span>
                    <p>Nezahualcóyotl</p><a href="tel:5511083580">55 1108 3580</a><br />
                    <a href="https://goo.gl/maps/yty9b4ez57FuQ9sKA" target="_blank">Avenida Tepozanes No. 420, Mz 34, Lt 1, Col. Ampliación Perla, Nezahualcóyotl, Estado de México, C.P. 57849</a>
                </span>
                <span>
                    <p>Toluca</p><a href="tel:7222145143">722 214 5143</a><br />
                    <a href="https://goo.gl/maps/6pWcvh9HQWCacbVH9" target="_blank">Juan Álvarez No. 412 Local 3 PB esq. con Galeana, Col. Francisco Murguía, Ciudad de Toluca, C.P. 50130, Estado de México.</a>
                </span>
                <span>
                    <p>Toluca Mexiquense</p><a href="tel:7222365390">722 236 5390</a><br />
                    <a href="https://goo.gl/maps/qki8FsHvRWMVySbJ8" target="_blank">Av. Jose Lopez Portillo No. 601, Col. San Lorenzo Tepaltitlán, Municipio de Toluca, Edo. de Méx., C.P. 50010</a>
                </span>
                <span>
                    <p>Tultitlán</p><a href="tel:5522393577">55 2239 3577</a><br />
                    <a href="https://goo.gl/maps/Dt33rf1dDCH8euv48" target="_blank">Av. Boulevard Tultitlán Oriente N. 10 D, Col. Barrio de Santiaguito, Tultitlán, C.P. 54900</a>
                </span>
                <span>
                    <p>Villa Victoria</p><a href="tel:7262511012">726 251 1012</a><br />
                    <a href="https://goo.gl/maps/nZpcZZ722VdiBBvv5" target="_blank">Av. Lázaro Cardenas No. 619 Col. Jesús María, Municipio de Villa Victoria, C.P. 50960, Edo. de Méx.</a>
                </span>
            </Modal>
            <Modal open={openModal_guanajuato} setOpen={setOpenModal_guanajuato}>
                <h3 className="subtitulo-3">guanajuato</h3>
                <span>
                    <p>Salamanca</p><a href="tel:4646415925">464 641 5925</a><br />
                    <a href="https://goo.gl/maps/yasnX6wwdzGdfVkDA" target="_blank">Calle Juárez No. 301 B, Col. Centro, C.P. 36700, Salamanca, Guanajuato</a>
                </span>
            </Modal>
            <Modal open={openModal_michoacan} setOpen={setOpenModal_michoacan}>
                <h3 className="subtitulo-3">michoacan</h3>
                <span>
                    <p>Zitácuaro</p><a href="tel:7151537133">715 153 7133</a><br />
                    <a href="https://goo.gl/maps/oCfaanxgSQDrKjs47" target="_blank">Calle de Degollado Oriente No. 19 Local 1, Col. Cuauhtemoc, C.P. 61506, Zitácuaro, Michoacán</a>
                </span>
            </Modal>
            <Modal open={openModal_morelos} setOpen={setOpenModal_morelos}>
                <h3 className="subtitulo-3">morelos</h3>                
                <span>
                    <p>Cuautla</p><a href="tel:7353545213">735 354 5213</a><br />
                    <a href="https://goo.gl/maps/tJYCpYpyWmw9XEvC6" target="_blank">Av. Reforma No. 95, Col. Emiliano Zapata, C.P. 62744, Cuautla, Morelos</a>
                </span>
                <span>
                    <p>Yautepec</p><a href="tel:7353947478">735 394 7478</a><br />
                    <a href="https://goo.gl/maps/sKtMr6CTXAuvCYrdA" target="_blank">Paseo Tlahuica No. 130, Col. Otilio Montaño, C.P. 62731, Yautepec, Morelos. Entre calle Victor M. Guerrero y Gral. Ignacio Bastida</a>
                </span>
            </Modal>
            <Modal open={openModal_oaxaca} setOpen={setOpenModal_oaxaca}>
                <h3 className="subtitulo-3">oaxaca</h3>
                <span>
                    <p>Matías Romero</p><a href="tel:9727222808">972 722 2808</a><br />
                    <a href="https://goo.gl/maps/y2fVcmdLXLqVtUqB6" target="_blank">Calle 16 de Septiembre No. 205, Col. Centro, C.P. 70300, Municipio de Matías Romero Avendaño, Oaxaca.</a>
                </span>
                <span>
                    <p>Juchitán</p><a href="tel:9712810864">971 281 0864</a><br />
                    <a href="https://goo.gl/maps/Gyjhfm6fWHjZdeJn7" target="_blank">Calle 2 de Abril No. 61, Col. Segunda Sección, C.P. 70000, Juchitan de Zaragoza, Oaxaca. Entre la calle de Álvaro Obregón y Aldama</a>
                </span>
                <span>
                    <p>Tuxtepec</p><a href="tel:2878754690">287 875 4690</a><br />
                    <a href="https://goo.gl/maps/nmcSzsga6SA91LQk9" target="_blank">Av. Independencia No. 1011, Col. Piragua, C.P. 68360, Tuxtepec, Oaxaca. Entre Agustín de Iturbide y Santos Degollado.</a>
                </span>
            </Modal>
            <Modal open={openModal_puebla} setOpen={setOpenModal_puebla}>
                <h3 className="subtitulo-3">puebla</h3>
                <span>
                    <p>Cholula</p><a href="tel:2222477155">222 247 7155</a><br />
                    <a href="https://goo.gl/maps/Pe7pkKxYxAMe6AqRA" target="_blank">Av. 5 Oriente No. 3 PB, Col. Centro, C.P. 72760, San Pedro Cholula, Puebla.</a>
                </span>
                <span>
                    <p>Puebla Capu</p><a href="tel:2222266347">222 226 6347</a><br />
                    <a href="https://goo.gl/maps/rkriH5jhYezXME7k9" target="_blank">Av. 26 poniente No. 2412, local 5, plaza Aqua, Col. Valle Dorado, municipio Puebla C.P. 72070, Puebla.</a>
                </span>
                <span>
                    <p>Satélite</p><a href="tel:2222266347">222 226 6347</a><br />
                    <a href="https://goo.gl/maps/rPRBLpj5ZXs4eT4r8" target="_blank">Av. 26 poniente No. 2412, local 5, plaza Aqua, Col. Valle Dorado, municipio Puebla C.P. 72070, Puebla.</a>
                </span>
                <span>
                    <p>Tecamachalco</p><a href="tel:2494226248">249 422 6248</a><br />
                    <a href="https://goo.gl/maps/F51jqmr8ke7EtsVF9" target="_blank">Calle 6 Sur No. 518 PB, Col. Centro, C.P. 75480, Municipio de Tecamachalco, Puebla. </a>
                </span>
                <span>
                    <p>Tehuacán</p><a href="tel:2383829869">238 382 9869</a><br />
                    <a href="https://www.google.com/maps/dir/19.5482074,-99.2005361/Banco+Forjadores+Tehuac%C3%A1n/@19.0050551,-99.435572,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x85c5bcd42794cb09:0x56b2c86a982fba63!2m2!1d-97.391353!2d18.4673057?hl=es-419" target="_blank">Calle 3 Norte No. 406, Col. Centro, C.P. 75700, Tehuacan, Puebla. Entre 6 y 4 Oriente</a>
                </span>
                <span>
                    <p>Teziutlán</p><a href="tel:2313132895">231 313 2895</a><br />
                    <a href="https://goo.gl/maps/8e8TLMPTubxLafNM8" target="_blank">Javier Mina No. 213 esq. Av. Cuauhtémoc, Col. Centro, C.P. 73800, Teziutlán, Puebla.</a>
                </span>
            </Modal>
            <Modal open={openModal_queretaro} setOpen={setOpenModal_queretaro}>
                <h3 className="subtitulo-3">queretaro</h3>                
                <span>
                    <p>San Juan del Río</p><a href="tel:4272728752">427 272 8752</a><br />
                    <a href="https://goo.gl/maps/g2uLEMW27Q4HWS7x5" target="_blank">Av. Benito Juárez Oriente No. 90 Col. Centro esq. calle Panamericana, C.P. 76800, San Juan del Río, Querétaro.</a>
                </span>
                <span>
                    <p>Querétaro</p><a href="tel:4422424073">442 242 4073</a><br />
                    <a href="https://goo.gl/maps/88MXrv3XSb8xiUKo9" target="_blank">Calle Prolongación Pino Suárez No. 423 Local C, Col. Las Galindas, C.P. 76177, Municipio de Querétaro, Querétaro.</a>
                </span>

            </Modal>
            <Modal open={openModal_tlaxcala} setOpen={setOpenModal_tlaxcala}>
                <h3 className="subtitulo-3">tlaxcala</h3>                
                <span>
                    <p>Huamantla</p><a href="tel:2474722579">247 472 2579</a><br />
                    <a href="https://goo.gl/maps/D4BWFduhtDWi2hkQ6" target="_blank">Calle Aldama Oriente No. 206-B, Col. Centro, C.P. 90500, Huamantla, Tlaxcala.</a>
                </span>
                <span>
                    <p>Tlaxcala</p><a href="tel:2464667538">246 466 7538</a><br />
                    <a href="https://goo.gl/maps/LPRRmF9C23rxMhHp8" target="_blank">Calle 1 No. 448 PB, Col. La Loma Xicohtencatl, C.P. 90062, Municipio de Tlaxcala, Tlaxcala.</a>
                </span>
            </Modal>
            <Modal open={openModal_veracruz} setOpen={setOpenModal_veracruz}>
                <h3 className="subtitulo-3">veracruz</h3>
                <span>
                    <p>Córdoba</p><a href="tel:2717176228">271 717 6228</a><br />
                    <a href="https://goo.gl/maps/FtGbHGiobqaSsShj9" target="_blank">Av. 5 No. 501, esq. con Calle 6, Col. Centro, C.P. 94500, Municipio de Córdoba, Veracruz.</a>
                </span>
                <span>
                    <p>Medellín</p><a href="tel:2291650354">229 165 0354</a><br />
                    <a href="https://goo.gl/maps/WCZSWekasXZTxuQP9" target="_blank">Av. Matamoros esq. Calle Carmén Pérez Local 52 del Centro Comercial "Plaza Tokyo", Fracc. Los Pinos, Veracruz, Veracruz.</a>
                </span>
                <span>
                    <p>Orizaba</p><a href="tel:2721069591">272 106 9591</a><br />
                    <a href="https://goo.gl/maps/B4airtG7qsKJJnSQ9" target="_blank">Av. Oriente 8 No. 825, Col. Centro, C.P. 94300, Orizaba, Veracruz. Entre las calles Sur 17 y Sur 19</a>
                </span>
                <span>
                    <p>Tres Valles</p><a href="tel:2878710595 ">287 871 0595</a><br />
                    <a href="" target="_blank">Plaza Center Local 17 Y 18, Calle Francisco I madero S/N Esq. Avenida Flores Magón y Miguel Hidalgo, Tres Valles, Veracruz, CP 95300.</a>
                </span>
                <span>
                    <p>Veracruz</p><a href="tel:2299810337">229 981 0337</a><br />
                    <a href="" target="_blank">Calle Manuel de Jesús Clouthier S/N Lt.1 Mz.1, Local 13 del Centro Comercial "La Placita", Col. La Loma, Municipio de Veracruz, Veracruz, C.P. 91967</a>
                </span>
                <span>
                    <p>Xalapa</p><a href="tel:2288406776">228 840 6776</a><br />
                    <a href="https://goo.gl/maps/BzYHn657Dr7afdFi9" target="_blank">Calle Jorge Cerdán Norte No. 90, Col. El Mirador, C.P. 91170, Xalapa, Veracruz.</a>
                </span>
            </Modal>

        </div>
    );
}

export default OficinaServicio;