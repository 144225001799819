import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import icono_pdf from '../../../assets/images/Iconos/icono_pdf.png';
import UseInformacionCorporativa from '../../../stores/UseInformacionCorporativa';
import { useEffect } from 'react';

function EstatutosSocialesFile() {
	const {
		estatutosSociales,
		loadingEstatutosSociales,
		getEstatutosSociales,
	} = UseInformacionCorporativa();

	useEffect(() => {
		getEstatutosSociales();
	}, []);

	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ESTATUTOS SOCIALES</small>
					</h1>
				</div>
				<div className="estatutos-folders information-files">
					{loadingEstatutosSociales ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							Cargando estatutos...
						</p>
					) : !estatutosSociales ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							No hay archivo para mostrar
						</p>
					) : (
						<Link
							className="file"
							target="_blank"
							rel="noopener noreferrer"
							to={estatutosSociales.documentoRuta}
						>
							<img src={icono_pdf} />
							Estatutos Sociales Banco Forjadores
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

export default EstatutosSocialesFile;
