import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import RepAnim_2014 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2014.pdf';
import RepAnim_2015 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2015.pdf';
import RepAnim_2016 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2016.pdf';
import RepAnim_2017 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2017.pdf';
import RepAnim_2018 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2018.pdf';
import RepAnim_2019 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administración-2019.pdf';
import RepAnim_2020 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administracion-2020.pdf';
import RepAnim_2021 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-Administracion-2021-1.pdf';
import RepAnim_2022 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-de-la-administracion-2022.pdf';
import RepAnim_2023 from '../../../../assets/documents/InformacionFinanciera/ReporteDeAdminAnual/Reporte-Administracion-2023.pdf';

import '../../../../style/quienessomos.css';

function RepAnim() {
    return (
        <div class="post-inner">
            <div class="entry-content">
                <div class="information-intro">
                    <img src={encabezado} decoding="async" class="lazyloaded" data-ll-status="loaded" />
                    <h1><small>REPORTE DE ADMINISTRACIÓN ANUAL</small></h1>
                </div>
                <div class="information-files">
                    <a href={RepAnim_2014} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                    <a href={RepAnim_2023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Reporte de la Administración 2014</a>
                </div>

                <p></p>
            </div>
        </div>
    );
}

export default RepAnim;