import { useEffect, useState } from 'react';
import encabezado_recomienda_y_gana from '../../assets/images/Encabezados/encabezado-recomienda-y-gana.png';
import UseBeneficiosStore from '../../stores/UseBeneficiosStore';
import DOMPurify from 'dompurify';
function RecomiendaGana() {
	const [currentBeneficio, setCurrentBeneficio] = useState(null);
	const { beneficios, loadingBeneficios, getBeneficios } =
		UseBeneficiosStore();

	useEffect(() => {
		getBeneficios();
	}, []);

	useEffect(() => {
		const newCurrentBeneficio = beneficios.find((beneficio) => {
			return beneficio.titulo === 'Recomienda y Gana';
		});
		setCurrentBeneficio(newCurrentBeneficio);
	}, [beneficios]);

	return (
		<div className="recomienda-y-gana">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_recomienda_y_gana}
					alt="Encabezado recomienda y gana"
				/>
				{loadingBeneficios ? (
					<p className="simple-text">Cargando descripción...</p>
				) : !currentBeneficio ? (
					<p className="simple-text">
						No hay descripción para mostrar
					</p>
				) : (
					<p
						className="simple-text"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								currentBeneficio.descripcion,
							),
						}}
					></p>
				)}
			</section>
		</div>
	);
}
export default RecomiendaGana;
