import '../../style/inicio.css';
import Excel from '../../assets/documents/FSI-07-FORMULARIO-DERECHOS-ARCO-1.xlsx';
import PDF from '../../assets/documents/guia-de-usuario-para-el-ejercicio-de-derechos-arco-3.pdf';
import UseAvisoPrivacidadStore from '../../stores/UseAvisoPrivacidadStore';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';

function AvisoPrivacidad() {
	const { avisoPrivacidad, loadingAvisoPrivacidad, getAvisoPrivacidad } =
		UseAvisoPrivacidadStore();

	useEffect(() => {
		getAvisoPrivacidad();
	}, []);

	return (
		<div class="entry-content">
			{loadingAvisoPrivacidad ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					Cargando aviso de privacidad...
				</p>
			) : !avisoPrivacidad ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					No hay aviso de privacidad para mostrar
				</p>
			) : (
				<div
					id="privacyContainer"
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(avisoPrivacidad.cuerpo),
					}}
				></div>
			)}
            
			<div id="privacyContainer">
				<a href={Excel} target="_blank" rel="noopener">
					<b>FORMULARIO PARA EL EJERCICIO DE LOS DERECHOS ARCO</b> DA
					CLIC PARA VER FORMULARIO
				</a>
				<a href={PDF} target="_blank" rel="noopener">
					<b>GUÍA DE USUARIO PARA EL EJERCICIO DE DERECHOS ARCO</b> DA
					CLIC PARA VER FORMULARIO
				</a>
			</div>
		</div>
	);
}

export default AvisoPrivacidad;
