import { create } from 'zustand';

const UseContactoStore = create((set) => ({
	contacto: [],
	loadingContacto: false,
	getContacto: async () => {
		try {
			set({ loadingContacto: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/api/Informacion/Contacto',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();

			const telefonoFormateado = data[0].telefono.replace(
				/(\d{3})(\d{3})(\d{4})/,
				'$1 $2 $3',
			);
			const whatsAppFormateado = data[0].whatsApp.replace(
				/(\d{2})(\d{4})(\d{4})/,
				'$1 $2 $3',
			);

			const contactoToReturn = {
				...data[0],
				telefonoFormateado: telefonoFormateado,
				whatsAppFormateado: whatsAppFormateado,
			};

			set({ contacto: contactoToReturn });
			set({ loadingContacto: false });

			return contactoToReturn;
		} catch (error) {
			console.error('Error al obtener el Contacto');
			set({ loadingContacto: false });
		}
	},
}));

export default UseContactoStore;
