import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import Estados_Financieros_Dic_2012 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2012.pdf';
import Estados_Financieros_Dic_2013 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2013.pdf';
import Estados_Financieros_Dic_2014 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2014.pdf';
import Estados_Financieros_Dic_2015 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2015.pdf';
import Estados_Financieros_Dic_2016 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2016.pdf';
import Estados_Financieros_Dic_2017 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2017.pdf';
import Estados_Financieros_Dic_2018 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2018.pdf';
import Estados_Financieros_Dic_2019 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2019.pdf';
import Estados_Financieros_Dic_2020 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2020.pdf';
import Estados_Financieros_Dic_2021 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2021.pdf';
import Estados_Financieros_Dic_2022 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/Estados-Financieros-Dictaminados-2022.pdf';
import Estados_Financieros_Dic_2023 from '../../../../assets/documents/InformacionFinanciera/EstadosFinancierosDictaminados/ESTADOS-FINANCIEROS-DICTAMINADOS-2023.pdf';

import '../../../../style/quienessomos.css';

function EdosFinan() {
    return (
        <div class="post-inner">
            <div class="entry-content">
                <div class="information-intro">
                    <img src={encabezado} decoding="async" class="lazyloaded" data-ll-status="loaded" />
                    <h1><small>ESTADOS FINANCIEROS DICTAMINADOS</small></h1>
                </div>
                <div class="information-files">
                    <a href={Estados_Financieros_Dic_2012} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2012</a>
                    <a href={Estados_Financieros_Dic_2013} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2013</a>
                    <a href={Estados_Financieros_Dic_2014} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2014</a>
                    <a href={Estados_Financieros_Dic_2015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2015</a>
                    <a href={Estados_Financieros_Dic_2016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2016</a>
                    <a href={Estados_Financieros_Dic_2017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2017</a>
                    <a href={Estados_Financieros_Dic_2018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2018</a>
                    <a href={Estados_Financieros_Dic_2019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2019</a>
                    <a href={Estados_Financieros_Dic_2020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2020</a>
                    <a href={Estados_Financieros_Dic_2021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2021</a>
                    <a href={Estados_Financieros_Dic_2022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2022</a>
                    <a href={Estados_Financieros_Dic_2023} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Estados Financieros Dictaminados 2023</a>
                </div>
                
                <p></p>
            </div>
        </div>
    );
}

export default EdosFinan;