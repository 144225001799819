import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import icono_pdf from '../../../assets/images/Iconos/icono_pdf.png';
import UseInformacionCorporativa from '../../../stores/UseInformacionCorporativa';
import { useEffect } from 'react';

function ModificacionesEstatutos() {
	const {
		modificacionesEstatutos,
		loadingModificacionesEstatutos,
		getModificacionesEstatutos,
	} = UseInformacionCorporativa();

	useEffect(() => {
		getModificacionesEstatutos();
	}, []);

	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>MODIFICACIONES A LOS ESTATUTOS</small>
					</h1>
				</div>
				<div className="estatutos-folders information-files">
					{loadingModificacionesEstatutos ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							Cargando estatutos...
						</p>
					) : modificacionesEstatutos.length === 0 ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							No hay archivos para mostrar
						</p>
					) : (
						modificacionesEstatutos.map((modificacion) => {
							return (
								<Link
									key={modificacion.id}
									className="file"
									target="_blank"
									rel="noopener noreferrer"
									to={modificacion.documentoRuta}
								>
									<img src={icono_pdf} />
									{modificacion.nombre}
								</Link>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
}

export default ModificacionesEstatutos;
