import { create } from 'zustand';

const UseGATStore = create((set) => ({
	inversionesGAT: [],
	vigencias: {},
	loadingInversiones: false,
	loadingVigenias: false,
	getInversionesGAT: async () => {
		try {
			set({ loadingInversiones: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/api/GAT/TipoInversion',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ inversionesGAT: data });
			set({ loadingInversiones: false });
		} catch (error) {
			console.error('Error al obtener los beneficios');
			set({ loadingInversiones: false });
		}
	},
	getVigencias: async () => {
		try {
			set({ loadingVigenias: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/api/GAT/Vigencias',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();

			set({ vigencias: data[0] });
			set({ loadingVigenias: false });
		} catch (error) {
			set({ loadingVigenias: false });
			console.error('Error al obtener las vigencias');
		}
	},
}));

export default UseGATStore;
