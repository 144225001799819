import '../../style/inicio.css';
import Encabezado from '../../assets/images/Encabezados/encabezado-forjadoras-de-corazon-campana-credito.jpg';
import Mujer from '../../assets/images/cliente-campana-credito-forjadoras-de-corazon.png';
import linea_formulario_contrata from '../../assets/images/Individual_lineas_contrata.png';
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UseCorreosStore from '../../stores/UseCorreosStore';
import UseContactoStore from '../../stores/UseContactoStore';

function ForjadorasCorazon() {
	const [emailNombre, setEmailNombre] = useState('');
	const [emailApellido, setEmailApellido] = useState('');
	const [emailEmail, setEmailEmail] = useState('');
	const [emailTelefono, setEmailTelefono] = useState('');
	const [emailEstado, setEmailEstado] = useState('Aguascalientes');
	const [emailCiudad, setEmailCiudad] = useState('');
	const [emailPais, setEmailPais] = useState('us +1');
	const [emailCreditoPrevio, setEmailCreditoPrevio] = useState('No');
	const [emailClicloCompleto, setEmailClicloCompleto] = useState('No');
	const [emailMensaje, setEmailMensaje] = useState('');
	const [emailAceptoPoliticas, setEmailAceptoPoliticas] = useState(0);
	const [emailEnviado, setEmailEnviado] = useState(false);
	const [emailEnviando, setEmailEnviando] = useState(false);

	const { enviarCorreo } = UseCorreosStore();
	const { contacto, getContacto } = UseContactoStore();

	useEffect(() => {
		getContacto();
	}, []);

	async function handleEmailSubmit(e) {
		setEmailEnviando(true);
		e.preventDefault();

		if (!emailAceptoPoliticas) {
			alert('Debes aceptar las políticas de privacidad');
			setEmailEnviando(false);
			return;
		}

		const emailTemplate = `
        <body style="font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0;">
            <div style="width: 80%; margin: 20px auto; background-color: #fff; padding: 20px; border-radius: 5px; box-shadow: 0 2px 3px rgba(0,0,0,0.1);">
                <h2 style="color: #333;">Nuevo Mensaje de Contacto</h2>
                <p><span style="font-weight: bold;">Nombre:</span> ${emailNombre}</p>
                <p><span style="font-weight: bold;">Apellido:</span> ${emailApellido}</p>
                <p><span style="font-weight: bold;">Email:</span> ${emailEmail}</p>
                <p><span style="font-weight: bold;">Teléfono:</span> ${emailPais} ${emailTelefono}</p>
                <p><span style="font-weight: bold;">Estado:</span> ${emailEstado}</p>
                <p><span style="font-weight: bold;">Municipio:</span> ${emailCiudad}</p>
                <p><span style="font-weight: bold;">¿Has tenido un crédito con Banco Forjadores?</span> ${emailCreditoPrevio}</p>
                <p><span style="font-weight: bold;">¿Concluiste el ciclo completo de tu crédito?</span> ${emailClicloCompleto}</p>
                <p><span style="font-weight: bold;">Mensaje:</span></p>
                <p style="margin-bottom: 10px;">${emailMensaje}</p>
            </div>
        </body>
        `;

		const correo = {
			Asunto: 'Nuevo mensaje de contacto en Forjadoras de Corazón',
			Mensaje: emailTemplate,
		};

		const resultado = await enviarCorreo(correo);

		setEmailEnviado(resultado);
		setEmailEnviando(false);
	}

	return (
		<div className="entry-content">
			<div id="forjadorasIntro" className="product-intro">
				<img
					src={Encabezado}
					decoding="async"
					alt="Encabezado Campaña para Crédito Grupal - Forjadoras de Corazón"
					data-ll-status="loaded"
				/>
			</div>
			<div id="forjadorasDescription" className="product-description">
				<p>
					Si tienes un <b>crédito grupal</b> en otra institución
					financiera <b>te mejoramos las condiciones</b> de tu crédito
					con una tasa de interés <b>preferencial*</b> y además te
					igualamos o <b>mejoramos el monto de tu préstamo*</b> y, por
					si fuera poco, recibirás todos los beneficios al ser parte
					de nuestro grupo de clientes <b>“Forjadoras de Corazón”</b>.
				</p>
				<div>
					<p>
						<b>
							Asesoría personalizada con un trato amable y
							respetuoso:
						</b>
						<br />
						Cada semana nuestros asesores te visitan en el domicilio
						de reunión del grupo para brindarte asesoría financiera
						y en tu primer desembolso lo celebramos con un pastel.
					</p>
					<p>
						<b>Grupos pequeños: </b>
						<br />
						El mínimo de integrantes para grupos nuevos es de 8
						personas por lo que podrás reunirte con las personas de
						tu mayor confianza.
					</p>
					<p>
						<b>Montos adecuados a tu capacidad de pago</b>
						<br />
						Si ya tienes experiencia con otros créditos te podemos
						mejorar el monto de tu préstamo actual, sin que tengan
						que empezar con monto limitado por su antigüedad, todo
						de acuerdo a tu capacidad de pago para no
						sobreendeudarte.
					</p>
					<p>
						<b>Renovaciones anticipadas: </b>
						<br />
						Si tienes la necesidad de resurtir tu negocio podemos
						renovarte de manera anticipada el crédito.
					</p>
					<p>
						<b>Campañas de bonificaciones:</b>
						<br />Y para nuestros clientes que cumplen con sus pagos
						en tiempo tenemos campañas de bonificaciones por su
						excelente historial de pagos.
					</p>
				</div>
				<img
					src={Mujer}
					decoding="async"
					alt="Cliente Crédito Grupal - Forjadoras de Corazón"
					data-ll-status="loaded"
				/>
			</div>

			<div className="service-quote product-quote" id="">
				<h2>
					¿YA TIENES TUS <b>DOCUMENTOS</b> LISTOS?
				</h2>
				<div className="row contact-container">
					<div className="info-container forjadoras">
						<p>
							<big>¡Inicia tu trámite ahora!</big>
							Llámanos al <br />
							<a
								className="highlighted"
								href={
									'tel:' +
									(contacto
										? contacto.telefonoFormateado
										: '800 830 0005')
								}
							>
								{contacto
									? contacto.telefonoFormateado
									: '800 830 0005'}
							</a>
							<img
								decoding="async"
								src={linea_formulario_contrata}
								data-ll-status="loaded"
							/>
							<a
								className="highlighted"
								href={
									'https://api.whatsapp.com/send?phone=521' +
									(contacto
										? contacto.whatsApp
										: '5580507824')
								}
								target="_blank"
								rel="noopener"
							>
								<img
									className="info-icon"
									decoding="async"
									src={icono_whats_contactanos}
									data-ll-status="loaded"
								/>
								{contacto
									? contacto.whatsAppFormateado
									: '55 8039 8668'}
							</a>
							<a
								className="highlighted"
								href={
									contacto
										? 'mailto:' + contacto.correo
										: 'mailto:atencionaclientes@forjadores.com.mx'
								}
							>
								<img
									className="info-icon"
									decoding="async"
									src={icono_correo_contactanos}
									data-ll-status="loaded"
								/>
								{contacto
									? contacto.correo
									: 'atencionaclientes@forjadores.com.mx'}
							</a>
							Déjanos tus datos y empieza desde hoy tu proceso
						</p>
					</div>
					<div className="form-container forjadoras">
						<form onSubmit={handleEmailSubmit}>
							<div className="row" style={{ gap: '25px' }}>
								<div className="form-item">
									<label htmlFor="nombre">
										Nombre
										<span className="erf-required">*</span>
									</label>
									<input
										type="text"
										id="nombre"
										name="nombre"
										value={emailNombre}
										onChange={(e) =>
											setEmailNombre(e.target.value)
										}
										required
									/>
								</div>
								<div className="form-item">
									<label htmlFor="apellido">
										Apellido
										<span className="erf-required">*</span>
									</label>
									<input
										type="text"
										id="apellido"
										name="apellido"
										value={emailApellido}
										onChange={(e) =>
											setEmailApellido(e.target.value)
										}
										required
									/>
								</div>
							</div>

							<div className="row" style={{ gap: '25px' }}>
								<div className="form-item">
									<label htmlFor="email">
										Email
										<span className="erf-required">*</span>
									</label>
									<input
										type="text"
										id="email"
										name="email"
										value={emailEmail}
										onChange={(e) =>
											setEmailEmail(e.target.value)
										}
										required
									/>
								</div>

								<div className="form-item">
									<label htmlFor="telefono">
										{' '}
										Teléfono{' '}
										<span className="erf-required">
											*
										</span>{' '}
									</label>

									<div class="intl-tel-input allow-dropdown separate-dial-code iti-sdc-3">
										<div class="flag-container">
											<select
												class="country-list"
												id="country-listbox"
												aria-expanded="false"
												role="listbox"
												aria-activedescendant="iti-item-mx"
												onChange={(e) => {
													const selectedOption =
														e.target.options[
															e.target
																.selectedIndex
														];

													const dialCode =
														selectedOption.getAttribute(
															'data-dial-code',
														);
													const countryCode =
														selectedOption.getAttribute(
															'data-country-code',
														);
													const newEmailPais =
														countryCode +
														' +' +
														dialCode;

													setEmailPais(newEmailPais);
												}}
											>
												<option
													selected="true"
													class="country preferred"
													id="iti-item-us"
													role="option"
													data-dial-code="1"
													data-country-code="us"
												>
													<div class="flag-box">
														<div class="iti-flag us"></div>
													</div>
													<span class="country-name">
														United States
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>
												<option
													class="country preferred"
													id="iti-item-gb"
													role="option"
													data-dial-code="44"
													data-country-code="gb"
												>
													<div class="flag-box">
														<div class="iti-flag gb"></div>
													</div>
													<span class="country-name">
														United Kingdom
													</span>
													<span class="dial-code">
														+44
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-af"
													role="option"
													data-dial-code="93"
													data-country-code="af"
												>
													<div class="flag-box">
														<div class="iti-flag af"></div>
													</div>
													<span class="country-name">
														Afghanistan
														(‫افغانستان‬‎)
													</span>
													<span class="dial-code">
														+93
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-al"
													role="option"
													data-dial-code="355"
													data-country-code="al"
												>
													<div class="flag-box">
														<div class="iti-flag al"></div>
													</div>
													<span class="country-name">
														Albania (Shqipëri)
													</span>
													<span class="dial-code">
														+355
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-dz"
													role="option"
													data-dial-code="213"
													data-country-code="dz"
												>
													<div class="flag-box">
														<div class="iti-flag dz"></div>
													</div>
													<span class="country-name">
														Algeria (‫الجزائر‬‎)
													</span>
													<span class="dial-code">
														+213
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-as"
													role="option"
													data-dial-code="1684"
													data-country-code="as"
												>
													<div class="flag-box">
														<div class="iti-flag as"></div>
													</div>
													<span class="country-name">
														American Samoa
													</span>
													<span class="dial-code">
														+1684
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ad"
													role="option"
													data-dial-code="376"
													data-country-code="ad"
												>
													<div class="flag-box">
														<div class="iti-flag ad"></div>
													</div>
													<span class="country-name">
														Andorra
													</span>
													<span class="dial-code">
														+376
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ao"
													role="option"
													data-dial-code="244"
													data-country-code="ao"
												>
													<div class="flag-box">
														<div class="iti-flag ao"></div>
													</div>
													<span class="country-name">
														Angola
													</span>
													<span class="dial-code">
														+244
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ai"
													role="option"
													data-dial-code="1264"
													data-country-code="ai"
												>
													<div class="flag-box">
														<div class="iti-flag ai"></div>
													</div>
													<span class="country-name">
														Anguilla
													</span>
													<span class="dial-code">
														+1264
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ag"
													role="option"
													data-dial-code="1268"
													data-country-code="ag"
												>
													<div class="flag-box">
														<div class="iti-flag ag"></div>
													</div>
													<span class="country-name">
														Antigua and Barbuda
													</span>
													<span class="dial-code">
														+1268
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ar"
													role="option"
													data-dial-code="54"
													data-country-code="ar"
												>
													<div class="flag-box">
														<div class="iti-flag ar"></div>
													</div>
													<span class="country-name">
														Argentina
													</span>
													<span class="dial-code">
														+54
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-am"
													role="option"
													data-dial-code="374"
													data-country-code="am"
												>
													<div class="flag-box">
														<div class="iti-flag am"></div>
													</div>
													<span class="country-name">
														Armenia (Հայաստան)
													</span>
													<span class="dial-code">
														+374
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-aw"
													role="option"
													data-dial-code="297"
													data-country-code="aw"
												>
													<div class="flag-box">
														<div class="iti-flag aw"></div>
													</div>
													<span class="country-name">
														Aruba
													</span>
													<span class="dial-code">
														+297
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-au"
													role="option"
													data-dial-code="61"
													data-country-code="au"
												>
													<div class="flag-box">
														<div class="iti-flag au"></div>
													</div>
													<span class="country-name">
														Australia
													</span>
													<span class="dial-code">
														+61
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-at"
													role="option"
													data-dial-code="43"
													data-country-code="at"
												>
													<div class="flag-box">
														<div class="iti-flag at"></div>
													</div>
													<span class="country-name">
														Austria (Österreich)
													</span>
													<span class="dial-code">
														+43
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-az"
													role="option"
													data-dial-code="994"
													data-country-code="az"
												>
													<div class="flag-box">
														<div class="iti-flag az"></div>
													</div>
													<span class="country-name">
														Azerbaijan (Azərbaycan)
													</span>
													<span class="dial-code">
														+994
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bs"
													role="option"
													data-dial-code="1242"
													data-country-code="bs"
												>
													<div class="flag-box">
														<div class="iti-flag bs"></div>
													</div>
													<span class="country-name">
														Bahamas
													</span>
													<span class="dial-code">
														+1242
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bh"
													role="option"
													data-dial-code="973"
													data-country-code="bh"
												>
													<div class="flag-box">
														<div class="iti-flag bh"></div>
													</div>
													<span class="country-name">
														Bahrain (‫البحرين‬‎)
													</span>
													<span class="dial-code">
														+973
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bd"
													role="option"
													data-dial-code="880"
													data-country-code="bd"
												>
													<div class="flag-box">
														<div class="iti-flag bd"></div>
													</div>
													<span class="country-name">
														Bangladesh (বাংলাদেশ)
													</span>
													<span class="dial-code">
														+880
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bb"
													role="option"
													data-dial-code="1246"
													data-country-code="bb"
												>
													<div class="flag-box">
														<div class="iti-flag bb"></div>
													</div>
													<span class="country-name">
														Barbados
													</span>
													<span class="dial-code">
														+1246
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-by"
													role="option"
													data-dial-code="375"
													data-country-code="by"
												>
													<div class="flag-box">
														<div class="iti-flag by"></div>
													</div>
													<span class="country-name">
														Belarus (Беларусь)
													</span>
													<span class="dial-code">
														+375
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-be"
													role="option"
													data-dial-code="32"
													data-country-code="be"
												>
													<div class="flag-box">
														<div class="iti-flag be"></div>
													</div>
													<span class="country-name">
														Belgium (België)
													</span>
													<span class="dial-code">
														+32
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bz"
													role="option"
													data-dial-code="501"
													data-country-code="bz"
												>
													<div class="flag-box">
														<div class="iti-flag bz"></div>
													</div>
													<span class="country-name">
														Belize
													</span>
													<span class="dial-code">
														+501
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bj"
													role="option"
													data-dial-code="229"
													data-country-code="bj"
												>
													<div class="flag-box">
														<div class="iti-flag bj"></div>
													</div>
													<span class="country-name">
														Benin (Bénin)
													</span>
													<span class="dial-code">
														+229
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bm"
													role="option"
													data-dial-code="1441"
													data-country-code="bm"
												>
													<div class="flag-box">
														<div class="iti-flag bm"></div>
													</div>
													<span class="country-name">
														Bermuda
													</span>
													<span class="dial-code">
														+1441
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bt"
													role="option"
													data-dial-code="975"
													data-country-code="bt"
												>
													<div class="flag-box">
														<div class="iti-flag bt"></div>
													</div>
													<span class="country-name">
														Bhutan (འབྲུག)
													</span>
													<span class="dial-code">
														+975
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bo"
													role="option"
													data-dial-code="591"
													data-country-code="bo"
												>
													<div class="flag-box">
														<div class="iti-flag bo"></div>
													</div>
													<span class="country-name">
														Bolivia
													</span>
													<span class="dial-code">
														+591
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ba"
													role="option"
													data-dial-code="387"
													data-country-code="ba"
												>
													<div class="flag-box">
														<div class="iti-flag ba"></div>
													</div>
													<span class="country-name">
														Bosnia and Herzegovina
														(Босна и Херцеговина)
													</span>
													<span class="dial-code">
														+387
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bw"
													role="option"
													data-dial-code="267"
													data-country-code="bw"
												>
													<div class="flag-box">
														<div class="iti-flag bw"></div>
													</div>
													<span class="country-name">
														Botswana
													</span>
													<span class="dial-code">
														+267
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-br"
													role="option"
													data-dial-code="55"
													data-country-code="br"
												>
													<div class="flag-box">
														<div class="iti-flag br"></div>
													</div>
													<span class="country-name">
														Brazil (Brasil)
													</span>
													<span class="dial-code">
														+55
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-io"
													role="option"
													data-dial-code="246"
													data-country-code="io"
												>
													<div class="flag-box">
														<div class="iti-flag io"></div>
													</div>
													<span class="country-name">
														British Indian Ocean
														Territory
													</span>
													<span class="dial-code">
														+246
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-vg"
													role="option"
													data-dial-code="1284"
													data-country-code="vg"
												>
													<div class="flag-box">
														<div class="iti-flag vg"></div>
													</div>
													<span class="country-name">
														British Virgin Islands
													</span>
													<span class="dial-code">
														+1284
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bn"
													role="option"
													data-dial-code="673"
													data-country-code="bn"
												>
													<div class="flag-box">
														<div class="iti-flag bn"></div>
													</div>
													<span class="country-name">
														Brunei
													</span>
													<span class="dial-code">
														+673
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bg"
													role="option"
													data-dial-code="359"
													data-country-code="bg"
												>
													<div class="flag-box">
														<div class="iti-flag bg"></div>
													</div>
													<span class="country-name">
														Bulgaria (България)
													</span>
													<span class="dial-code">
														+359
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bf"
													role="option"
													data-dial-code="226"
													data-country-code="bf"
												>
													<div class="flag-box">
														<div class="iti-flag bf"></div>
													</div>
													<span class="country-name">
														Burkina Faso
													</span>
													<span class="dial-code">
														+226
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bi"
													role="option"
													data-dial-code="257"
													data-country-code="bi"
												>
													<div class="flag-box">
														<div class="iti-flag bi"></div>
													</div>
													<span class="country-name">
														Burundi (Uburundi)
													</span>
													<span class="dial-code">
														+257
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kh"
													role="option"
													data-dial-code="855"
													data-country-code="kh"
												>
													<div class="flag-box">
														<div class="iti-flag kh"></div>
													</div>
													<span class="country-name">
														Cambodia (កម្ពុជា)
													</span>
													<span class="dial-code">
														+855
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cm"
													role="option"
													data-dial-code="237"
													data-country-code="cm"
												>
													<div class="flag-box">
														<div class="iti-flag cm"></div>
													</div>
													<span class="country-name">
														Cameroon (Cameroun)
													</span>
													<span class="dial-code">
														+237
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ca"
													role="option"
													data-dial-code="1"
													data-country-code="ca"
												>
													<div class="flag-box">
														<div class="iti-flag ca"></div>
													</div>
													<span class="country-name">
														Canada
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cv"
													role="option"
													data-dial-code="238"
													data-country-code="cv"
												>
													<div class="flag-box">
														<div class="iti-flag cv"></div>
													</div>
													<span class="country-name">
														Cape Verde (Kabu Verdi)
													</span>
													<span class="dial-code">
														+238
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bq"
													role="option"
													data-dial-code="599"
													data-country-code="bq"
												>
													<div class="flag-box">
														<div class="iti-flag bq"></div>
													</div>
													<span class="country-name">
														Caribbean Netherlands
													</span>
													<span class="dial-code">
														+599
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ky"
													role="option"
													data-dial-code="1345"
													data-country-code="ky"
												>
													<div class="flag-box">
														<div class="iti-flag ky"></div>
													</div>
													<span class="country-name">
														Cayman Islands
													</span>
													<span class="dial-code">
														+1345
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cf"
													role="option"
													data-dial-code="236"
													data-country-code="cf"
												>
													<div class="flag-box">
														<div class="iti-flag cf"></div>
													</div>
													<span class="country-name">
														Central African Republic
														(République
														centrafricaine)
													</span>
													<span class="dial-code">
														+236
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-td"
													role="option"
													data-dial-code="235"
													data-country-code="td"
												>
													<div class="flag-box">
														<div class="iti-flag td"></div>
													</div>
													<span class="country-name">
														Chad (Tchad)
													</span>
													<span class="dial-code">
														+235
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cl"
													role="option"
													data-dial-code="56"
													data-country-code="cl"
												>
													<div class="flag-box">
														<div class="iti-flag cl"></div>
													</div>
													<span class="country-name">
														Chile
													</span>
													<span class="dial-code">
														+56
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cn"
													role="option"
													data-dial-code="86"
													data-country-code="cn"
												>
													<div class="flag-box">
														<div class="iti-flag cn"></div>
													</div>
													<span class="country-name">
														China (中国)
													</span>
													<span class="dial-code">
														+86
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cx"
													role="option"
													data-dial-code="61"
													data-country-code="cx"
												>
													<div class="flag-box">
														<div class="iti-flag cx"></div>
													</div>
													<span class="country-name">
														Christmas Island
													</span>
													<span class="dial-code">
														+61
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cc"
													role="option"
													data-dial-code="61"
													data-country-code="cc"
												>
													<div class="flag-box">
														<div class="iti-flag cc"></div>
													</div>
													<span class="country-name">
														Cocos (Keeling) Islands
													</span>
													<span class="dial-code">
														+61
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-co"
													role="option"
													data-dial-code="57"
													data-country-code="co"
												>
													<div class="flag-box">
														<div class="iti-flag co"></div>
													</div>
													<span class="country-name">
														Colombia
													</span>
													<span class="dial-code">
														+57
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-km"
													role="option"
													data-dial-code="269"
													data-country-code="km"
												>
													<div class="flag-box">
														<div class="iti-flag km"></div>
													</div>
													<span class="country-name">
														Comoros (‫جزر القمر‬‎)
													</span>
													<span class="dial-code">
														+269
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cd"
													role="option"
													data-dial-code="243"
													data-country-code="cd"
												>
													<div class="flag-box">
														<div class="iti-flag cd"></div>
													</div>
													<span class="country-name">
														Congo (DRC) (Jamhuri ya
														Kidemokrasia ya Kongo)
													</span>
													<span class="dial-code">
														+243
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cg"
													role="option"
													data-dial-code="242"
													data-country-code="cg"
												>
													<div class="flag-box">
														<div class="iti-flag cg"></div>
													</div>
													<span class="country-name">
														Congo (Republic)
														(Congo-Brazzaville)
													</span>
													<span class="dial-code">
														+242
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ck"
													role="option"
													data-dial-code="682"
													data-country-code="ck"
												>
													<div class="flag-box">
														<div class="iti-flag ck"></div>
													</div>
													<span class="country-name">
														Cook Islands
													</span>
													<span class="dial-code">
														+682
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cr"
													role="option"
													data-dial-code="506"
													data-country-code="cr"
												>
													<div class="flag-box">
														<div class="iti-flag cr"></div>
													</div>
													<span class="country-name">
														Costa Rica
													</span>
													<span class="dial-code">
														+506
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ci"
													role="option"
													data-dial-code="225"
													data-country-code="ci"
												>
													<div class="flag-box">
														<div class="iti-flag ci"></div>
													</div>
													<span class="country-name">
														Côte d’Ivoire
													</span>
													<span class="dial-code">
														+225
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-hr"
													role="option"
													data-dial-code="385"
													data-country-code="hr"
												>
													<div class="flag-box">
														<div class="iti-flag hr"></div>
													</div>
													<span class="country-name">
														Croatia (Hrvatska)
													</span>
													<span class="dial-code">
														+385
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cu"
													role="option"
													data-dial-code="53"
													data-country-code="cu"
												>
													<div class="flag-box">
														<div class="iti-flag cu"></div>
													</div>
													<span class="country-name">
														Cuba
													</span>
													<span class="dial-code">
														+53
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cw"
													role="option"
													data-dial-code="599"
													data-country-code="cw"
												>
													<div class="flag-box">
														<div class="iti-flag cw"></div>
													</div>
													<span class="country-name">
														Curaçao
													</span>
													<span class="dial-code">
														+599
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cy"
													role="option"
													data-dial-code="357"
													data-country-code="cy"
												>
													<div class="flag-box">
														<div class="iti-flag cy"></div>
													</div>
													<span class="country-name">
														Cyprus (Κύπρος)
													</span>
													<span class="dial-code">
														+357
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-cz"
													role="option"
													data-dial-code="420"
													data-country-code="cz"
												>
													<div class="flag-box">
														<div class="iti-flag cz"></div>
													</div>
													<span class="country-name">
														Czech Republic (Česká
														republika)
													</span>
													<span class="dial-code">
														+420
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-dk"
													role="option"
													data-dial-code="45"
													data-country-code="dk"
												>
													<div class="flag-box">
														<div class="iti-flag dk"></div>
													</div>
													<span class="country-name">
														Denmark (Danmark)
													</span>
													<span class="dial-code">
														+45
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-dj"
													role="option"
													data-dial-code="253"
													data-country-code="dj"
												>
													<div class="flag-box">
														<div class="iti-flag dj"></div>
													</div>
													<span class="country-name">
														Djibouti
													</span>
													<span class="dial-code">
														+253
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-dm"
													role="option"
													data-dial-code="1767"
													data-country-code="dm"
												>
													<div class="flag-box">
														<div class="iti-flag dm"></div>
													</div>
													<span class="country-name">
														Dominica
													</span>
													<span class="dial-code">
														+1767
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-do"
													role="option"
													data-dial-code="1"
													data-country-code="do"
												>
													<div class="flag-box">
														<div class="iti-flag do"></div>
													</div>
													<span class="country-name">
														Dominican Republic
														(República Dominicana)
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ec"
													role="option"
													data-dial-code="593"
													data-country-code="ec"
												>
													<div class="flag-box">
														<div class="iti-flag ec"></div>
													</div>
													<span class="country-name">
														Ecuador
													</span>
													<span class="dial-code">
														+593
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-eg"
													role="option"
													data-dial-code="20"
													data-country-code="eg"
												>
													<div class="flag-box">
														<div class="iti-flag eg"></div>
													</div>
													<span class="country-name">
														Egypt (‫مصر‬‎)
													</span>
													<span class="dial-code">
														+20
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-sv"
													role="option"
													data-dial-code="503"
													data-country-code="sv"
												>
													<div class="flag-box">
														<div class="iti-flag sv"></div>
													</div>
													<span class="country-name">
														El Salvador
													</span>
													<span class="dial-code">
														+503
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gq"
													role="option"
													data-dial-code="240"
													data-country-code="gq"
												>
													<div class="flag-box">
														<div class="iti-flag gq"></div>
													</div>
													<span class="country-name">
														Equatorial Guinea
														(Guinea Ecuatorial)
													</span>
													<span class="dial-code">
														+240
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-er"
													role="option"
													data-dial-code="291"
													data-country-code="er"
												>
													<div class="flag-box">
														<div class="iti-flag er"></div>
													</div>
													<span class="country-name">
														Eritrea
													</span>
													<span class="dial-code">
														+291
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ee"
													role="option"
													data-dial-code="372"
													data-country-code="ee"
												>
													<div class="flag-box">
														<div class="iti-flag ee"></div>
													</div>
													<span class="country-name">
														Estonia (Eesti)
													</span>
													<span class="dial-code">
														+372
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-et"
													role="option"
													data-dial-code="251"
													data-country-code="et"
												>
													<div class="flag-box">
														<div class="iti-flag et"></div>
													</div>
													<span class="country-name">
														Ethiopia
													</span>
													<span class="dial-code">
														+251
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fk"
													role="option"
													data-dial-code="500"
													data-country-code="fk"
												>
													<div class="flag-box">
														<div class="iti-flag fk"></div>
													</div>
													<span class="country-name">
														Falkland Islands (Islas
														Malvinas)
													</span>
													<span class="dial-code">
														+500
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fo"
													role="option"
													data-dial-code="298"
													data-country-code="fo"
												>
													<div class="flag-box">
														<div class="iti-flag fo"></div>
													</div>
													<span class="country-name">
														Faroe Islands (Føroyar)
													</span>
													<span class="dial-code">
														+298
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fj"
													role="option"
													data-dial-code="679"
													data-country-code="fj"
												>
													<div class="flag-box">
														<div class="iti-flag fj"></div>
													</div>
													<span class="country-name">
														Fiji
													</span>
													<span class="dial-code">
														+679
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fi"
													role="option"
													data-dial-code="358"
													data-country-code="fi"
												>
													<div class="flag-box">
														<div class="iti-flag fi"></div>
													</div>
													<span class="country-name">
														Finland (Suomi)
													</span>
													<span class="dial-code">
														+358
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fr"
													role="option"
													data-dial-code="33"
													data-country-code="fr"
												>
													<div class="flag-box">
														<div class="iti-flag fr"></div>
													</div>
													<span class="country-name">
														France
													</span>
													<span class="dial-code">
														+33
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gf"
													role="option"
													data-dial-code="594"
													data-country-code="gf"
												>
													<div class="flag-box">
														<div class="iti-flag gf"></div>
													</div>
													<span class="country-name">
														French Guiana (Guyane
														française)
													</span>
													<span class="dial-code">
														+594
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pf"
													role="option"
													data-dial-code="689"
													data-country-code="pf"
												>
													<div class="flag-box">
														<div class="iti-flag pf"></div>
													</div>
													<span class="country-name">
														French Polynesia
														(Polynésie française)
													</span>
													<span class="dial-code">
														+689
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ga"
													role="option"
													data-dial-code="241"
													data-country-code="ga"
												>
													<div class="flag-box">
														<div class="iti-flag ga"></div>
													</div>
													<span class="country-name">
														Gabon
													</span>
													<span class="dial-code">
														+241
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gm"
													role="option"
													data-dial-code="220"
													data-country-code="gm"
												>
													<div class="flag-box">
														<div class="iti-flag gm"></div>
													</div>
													<span class="country-name">
														Gambia
													</span>
													<span class="dial-code">
														+220
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ge"
													role="option"
													data-dial-code="995"
													data-country-code="ge"
												>
													<div class="flag-box">
														<div class="iti-flag ge"></div>
													</div>
													<span class="country-name">
														Georgia (საქართველო)
													</span>
													<span class="dial-code">
														+995
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-de"
													role="option"
													data-dial-code="49"
													data-country-code="de"
												>
													<div class="flag-box">
														<div class="iti-flag de"></div>
													</div>
													<span class="country-name">
														Germany (Deutschland)
													</span>
													<span class="dial-code">
														+49
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gh"
													role="option"
													data-dial-code="233"
													data-country-code="gh"
												>
													<div class="flag-box">
														<div class="iti-flag gh"></div>
													</div>
													<span class="country-name">
														Ghana (Gaana)
													</span>
													<span class="dial-code">
														+233
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gi"
													role="option"
													data-dial-code="350"
													data-country-code="gi"
												>
													<div class="flag-box">
														<div class="iti-flag gi"></div>
													</div>
													<span class="country-name">
														Gibraltar
													</span>
													<span class="dial-code">
														+350
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gr"
													role="option"
													data-dial-code="30"
													data-country-code="gr"
												>
													<div class="flag-box">
														<div class="iti-flag gr"></div>
													</div>
													<span class="country-name">
														Greece (Ελλάδα)
													</span>
													<span class="dial-code">
														+30
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gl"
													role="option"
													data-dial-code="299"
													data-country-code="gl"
												>
													<div class="flag-box">
														<div class="iti-flag gl"></div>
													</div>
													<span class="country-name">
														Greenland (Kalaallit
														Nunaat)
													</span>
													<span class="dial-code">
														+299
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gd"
													role="option"
													data-dial-code="1473"
													data-country-code="gd"
												>
													<div class="flag-box">
														<div class="iti-flag gd"></div>
													</div>
													<span class="country-name">
														Grenada
													</span>
													<span class="dial-code">
														+1473
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gp"
													role="option"
													data-dial-code="590"
													data-country-code="gp"
												>
													<div class="flag-box">
														<div class="iti-flag gp"></div>
													</div>
													<span class="country-name">
														Guadeloupe
													</span>
													<span class="dial-code">
														+590
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gu"
													role="option"
													data-dial-code="1671"
													data-country-code="gu"
												>
													<div class="flag-box">
														<div class="iti-flag gu"></div>
													</div>
													<span class="country-name">
														Guam
													</span>
													<span class="dial-code">
														+1671
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gt"
													role="option"
													data-dial-code="502"
													data-country-code="gt"
												>
													<div class="flag-box">
														<div class="iti-flag gt"></div>
													</div>
													<span class="country-name">
														Guatemala
													</span>
													<span class="dial-code">
														+502
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gg"
													role="option"
													data-dial-code="44"
													data-country-code="gg"
												>
													<div class="flag-box">
														<div class="iti-flag gg"></div>
													</div>
													<span class="country-name">
														Guernsey
													</span>
													<span class="dial-code">
														+44
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gn"
													role="option"
													data-dial-code="224"
													data-country-code="gn"
												>
													<div class="flag-box">
														<div class="iti-flag gn"></div>
													</div>
													<span class="country-name">
														Guinea (Guinée)
													</span>
													<span class="dial-code">
														+224
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gw"
													role="option"
													data-dial-code="245"
													data-country-code="gw"
												>
													<div class="flag-box">
														<div class="iti-flag gw"></div>
													</div>
													<span class="country-name">
														Guinea-Bissau (Guiné
														Bissau)
													</span>
													<span class="dial-code">
														+245
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-gy"
													role="option"
													data-dial-code="592"
													data-country-code="gy"
												>
													<div class="flag-box">
														<div class="iti-flag gy"></div>
													</div>
													<span class="country-name">
														Guyana
													</span>
													<span class="dial-code">
														+592
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ht"
													role="option"
													data-dial-code="509"
													data-country-code="ht"
												>
													<div class="flag-box">
														<div class="iti-flag ht"></div>
													</div>
													<span class="country-name">
														Haiti
													</span>
													<span class="dial-code">
														+509
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-hn"
													role="option"
													data-dial-code="504"
													data-country-code="hn"
												>
													<div class="flag-box">
														<div class="iti-flag hn"></div>
													</div>
													<span class="country-name">
														Honduras
													</span>
													<span class="dial-code">
														+504
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-hk"
													role="option"
													data-dial-code="852"
													data-country-code="hk"
												>
													<div class="flag-box">
														<div class="iti-flag hk"></div>
													</div>
													<span class="country-name">
														Hong Kong (香港)
													</span>
													<span class="dial-code">
														+852
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-hu"
													role="option"
													data-dial-code="36"
													data-country-code="hu"
												>
													<div class="flag-box">
														<div class="iti-flag hu"></div>
													</div>
													<span class="country-name">
														Hungary (Magyarország)
													</span>
													<span class="dial-code">
														+36
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-is"
													role="option"
													data-dial-code="354"
													data-country-code="is"
												>
													<div class="flag-box">
														<div class="iti-flag is"></div>
													</div>
													<span class="country-name">
														Iceland (Ísland)
													</span>
													<span class="dial-code">
														+354
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-in"
													role="option"
													data-dial-code="91"
													data-country-code="in"
												>
													<div class="flag-box">
														<div class="iti-flag in"></div>
													</div>
													<span class="country-name">
														India (भारत)
													</span>
													<span class="dial-code">
														+91
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-id"
													role="option"
													data-dial-code="62"
													data-country-code="id"
												>
													<div class="flag-box">
														<div class="iti-flag id"></div>
													</div>
													<span class="country-name">
														Indonesia
													</span>
													<span class="dial-code">
														+62
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ir"
													role="option"
													data-dial-code="98"
													data-country-code="ir"
												>
													<div class="flag-box">
														<div class="iti-flag ir"></div>
													</div>
													<span class="country-name">
														Iran (‫ایران‬‎)
													</span>
													<span class="dial-code">
														+98
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-iq"
													role="option"
													data-dial-code="964"
													data-country-code="iq"
												>
													<div class="flag-box">
														<div class="iti-flag iq"></div>
													</div>
													<span class="country-name">
														Iraq (‫العراق‬‎)
													</span>
													<span class="dial-code">
														+964
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ie"
													role="option"
													data-dial-code="353"
													data-country-code="ie"
												>
													<div class="flag-box">
														<div class="iti-flag ie"></div>
													</div>
													<span class="country-name">
														Ireland
													</span>
													<span class="dial-code">
														+353
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-im"
													role="option"
													data-dial-code="44"
													data-country-code="im"
												>
													<div class="flag-box">
														<div class="iti-flag im"></div>
													</div>
													<span class="country-name">
														Isle of Man
													</span>
													<span class="dial-code">
														+44
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-il"
													role="option"
													data-dial-code="972"
													data-country-code="il"
												>
													<div class="flag-box">
														<div class="iti-flag il"></div>
													</div>
													<span class="country-name">
														Israel (‫ישראל‬‎)
													</span>
													<span class="dial-code">
														+972
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-it"
													role="option"
													data-dial-code="39"
													data-country-code="it"
												>
													<div class="flag-box">
														<div class="iti-flag it"></div>
													</div>
													<span class="country-name">
														Italy (Italia)
													</span>
													<span class="dial-code">
														+39
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-jm"
													role="option"
													data-dial-code="1"
													data-country-code="jm"
												>
													<div class="flag-box">
														<div class="iti-flag jm"></div>
													</div>
													<span class="country-name">
														Jamaica
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-jp"
													role="option"
													data-dial-code="81"
													data-country-code="jp"
												>
													<div class="flag-box">
														<div class="iti-flag jp"></div>
													</div>
													<span class="country-name">
														Japan (日本)
													</span>
													<span class="dial-code">
														+81
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-je"
													role="option"
													data-dial-code="44"
													data-country-code="je"
												>
													<div class="flag-box">
														<div class="iti-flag je"></div>
													</div>
													<span class="country-name">
														Jersey
													</span>
													<span class="dial-code">
														+44
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-jo"
													role="option"
													data-dial-code="962"
													data-country-code="jo"
												>
													<div class="flag-box">
														<div class="iti-flag jo"></div>
													</div>
													<span class="country-name">
														Jordan (‫الأردن‬‎)
													</span>
													<span class="dial-code">
														+962
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kz"
													role="option"
													data-dial-code="7"
													data-country-code="kz"
												>
													<div class="flag-box">
														<div class="iti-flag kz"></div>
													</div>
													<span class="country-name">
														Kazakhstan (Казахстан)
													</span>
													<span class="dial-code">
														+7
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ke"
													role="option"
													data-dial-code="254"
													data-country-code="ke"
												>
													<div class="flag-box">
														<div class="iti-flag ke"></div>
													</div>
													<span class="country-name">
														Kenya
													</span>
													<span class="dial-code">
														+254
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ki"
													role="option"
													data-dial-code="686"
													data-country-code="ki"
												>
													<div class="flag-box">
														<div class="iti-flag ki"></div>
													</div>
													<span class="country-name">
														Kiribati
													</span>
													<span class="dial-code">
														+686
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-xk"
													role="option"
													data-dial-code="383"
													data-country-code="xk"
												>
													<div class="flag-box">
														<div class="iti-flag xk"></div>
													</div>
													<span class="country-name">
														Kosovo
													</span>
													<span class="dial-code">
														+383
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kw"
													role="option"
													data-dial-code="965"
													data-country-code="kw"
												>
													<div class="flag-box">
														<div class="iti-flag kw"></div>
													</div>
													<span class="country-name">
														Kuwait (‫الكويت‬‎)
													</span>
													<span class="dial-code">
														+965
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kg"
													role="option"
													data-dial-code="996"
													data-country-code="kg"
												>
													<div class="flag-box">
														<div class="iti-flag kg"></div>
													</div>
													<span class="country-name">
														Kyrgyzstan (Кыргызстан)
													</span>
													<span class="dial-code">
														+996
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-la"
													role="option"
													data-dial-code="856"
													data-country-code="la"
												>
													<div class="flag-box">
														<div class="iti-flag la"></div>
													</div>
													<span class="country-name">
														Laos (ລາວ)
													</span>
													<span class="dial-code">
														+856
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lv"
													role="option"
													data-dial-code="371"
													data-country-code="lv"
												>
													<div class="flag-box">
														<div class="iti-flag lv"></div>
													</div>
													<span class="country-name">
														Latvia (Latvija)
													</span>
													<span class="dial-code">
														+371
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lb"
													role="option"
													data-dial-code="961"
													data-country-code="lb"
												>
													<div class="flag-box">
														<div class="iti-flag lb"></div>
													</div>
													<span class="country-name">
														Lebanon (‫لبنان‬‎)
													</span>
													<span class="dial-code">
														+961
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ls"
													role="option"
													data-dial-code="266"
													data-country-code="ls"
												>
													<div class="flag-box">
														<div class="iti-flag ls"></div>
													</div>
													<span class="country-name">
														Lesotho
													</span>
													<span class="dial-code">
														+266
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lr"
													role="option"
													data-dial-code="231"
													data-country-code="lr"
												>
													<div class="flag-box">
														<div class="iti-flag lr"></div>
													</div>
													<span class="country-name">
														Liberia
													</span>
													<span class="dial-code">
														+231
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ly"
													role="option"
													data-dial-code="218"
													data-country-code="ly"
												>
													<div class="flag-box">
														<div class="iti-flag ly"></div>
													</div>
													<span class="country-name">
														Libya (‫ليبيا‬‎)
													</span>
													<span class="dial-code">
														+218
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-li"
													role="option"
													data-dial-code="423"
													data-country-code="li"
												>
													<div class="flag-box">
														<div class="iti-flag li"></div>
													</div>
													<span class="country-name">
														Liechtenstein
													</span>
													<span class="dial-code">
														+423
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lt"
													role="option"
													data-dial-code="370"
													data-country-code="lt"
												>
													<div class="flag-box">
														<div class="iti-flag lt"></div>
													</div>
													<span class="country-name">
														Lithuania (Lietuva)
													</span>
													<span class="dial-code">
														+370
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lu"
													role="option"
													data-dial-code="352"
													data-country-code="lu"
												>
													<div class="flag-box">
														<div class="iti-flag lu"></div>
													</div>
													<span class="country-name">
														Luxembourg
													</span>
													<span class="dial-code">
														+352
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mo"
													role="option"
													data-dial-code="853"
													data-country-code="mo"
												>
													<div class="flag-box">
														<div class="iti-flag mo"></div>
													</div>
													<span class="country-name">
														Macau (澳門)
													</span>
													<span class="dial-code">
														+853
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mk"
													role="option"
													data-dial-code="389"
													data-country-code="mk"
												>
													<div class="flag-box">
														<div class="iti-flag mk"></div>
													</div>
													<span class="country-name">
														Macedonia (FYROM)
														(Македонија)
													</span>
													<span class="dial-code">
														+389
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mg"
													role="option"
													data-dial-code="261"
													data-country-code="mg"
												>
													<div class="flag-box">
														<div class="iti-flag mg"></div>
													</div>
													<span class="country-name">
														Madagascar
														(Madagasikara)
													</span>
													<span class="dial-code">
														+261
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mw"
													role="option"
													data-dial-code="265"
													data-country-code="mw"
												>
													<div class="flag-box">
														<div class="iti-flag mw"></div>
													</div>
													<span class="country-name">
														Malawi
													</span>
													<span class="dial-code">
														+265
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-my"
													role="option"
													data-dial-code="60"
													data-country-code="my"
												>
													<div class="flag-box">
														<div class="iti-flag my"></div>
													</div>
													<span class="country-name">
														Malaysia
													</span>
													<span class="dial-code">
														+60
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mv"
													role="option"
													data-dial-code="960"
													data-country-code="mv"
												>
													<div class="flag-box">
														<div class="iti-flag mv"></div>
													</div>
													<span class="country-name">
														Maldives
													</span>
													<span class="dial-code">
														+960
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ml"
													role="option"
													data-dial-code="223"
													data-country-code="ml"
												>
													<div class="flag-box">
														<div class="iti-flag ml"></div>
													</div>
													<span class="country-name">
														Mali
													</span>
													<span class="dial-code">
														+223
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mt"
													role="option"
													data-dial-code="356"
													data-country-code="mt"
												>
													<div class="flag-box">
														<div class="iti-flag mt"></div>
													</div>
													<span class="country-name">
														Malta
													</span>
													<span class="dial-code">
														+356
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mh"
													role="option"
													data-dial-code="692"
													data-country-code="mh"
												>
													<div class="flag-box">
														<div class="iti-flag mh"></div>
													</div>
													<span class="country-name">
														Marshall Islands
													</span>
													<span class="dial-code">
														+692
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mq"
													role="option"
													data-dial-code="596"
													data-country-code="mq"
												>
													<div class="flag-box">
														<div class="iti-flag mq"></div>
													</div>
													<span class="country-name">
														Martinique
													</span>
													<span class="dial-code">
														+596
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mr"
													role="option"
													data-dial-code="222"
													data-country-code="mr"
												>
													<div class="flag-box">
														<div class="iti-flag mr"></div>
													</div>
													<span class="country-name">
														Mauritania
														(‫موريتانيا‬‎)
													</span>
													<span class="dial-code">
														+222
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mu"
													role="option"
													data-dial-code="230"
													data-country-code="mu"
												>
													<div class="flag-box">
														<div class="iti-flag mu"></div>
													</div>
													<span class="country-name">
														Mauritius (Moris)
													</span>
													<span class="dial-code">
														+230
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-yt"
													role="option"
													data-dial-code="262"
													data-country-code="yt"
												>
													<div class="flag-box">
														<div class="iti-flag yt"></div>
													</div>
													<span class="country-name">
														Mayotte
													</span>
													<span class="dial-code">
														+262
													</span>
												</option>
												<option
													class="country standard active"
													id="iti-item-mx"
													role="option"
													data-dial-code="52"
													data-country-code="mx"
													aria-selected="true"
												>
													<div class="flag-box">
														<div class="iti-flag mx"></div>
													</div>
													<span class="country-name">
														Mexico (México)
													</span>
													<span class="dial-code">
														+52
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-fm"
													role="option"
													data-dial-code="691"
													data-country-code="fm"
												>
													<div class="flag-box">
														<div class="iti-flag fm"></div>
													</div>
													<span class="country-name">
														Micronesia
													</span>
													<span class="dial-code">
														+691
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-md"
													role="option"
													data-dial-code="373"
													data-country-code="md"
												>
													<div class="flag-box">
														<div class="iti-flag md"></div>
													</div>
													<span class="country-name">
														Moldova (Republica
														Moldova)
													</span>
													<span class="dial-code">
														+373
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mc"
													role="option"
													data-dial-code="377"
													data-country-code="mc"
												>
													<div class="flag-box">
														<div class="iti-flag mc"></div>
													</div>
													<span class="country-name">
														Monaco
													</span>
													<span class="dial-code">
														+377
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mn"
													role="option"
													data-dial-code="976"
													data-country-code="mn"
												>
													<div class="flag-box">
														<div class="iti-flag mn"></div>
													</div>
													<span class="country-name">
														Mongolia (Монгол)
													</span>
													<span class="dial-code">
														+976
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-me"
													role="option"
													data-dial-code="382"
													data-country-code="me"
												>
													<div class="flag-box">
														<div class="iti-flag me"></div>
													</div>
													<span class="country-name">
														Montenegro (Crna Gora)
													</span>
													<span class="dial-code">
														+382
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ms"
													role="option"
													data-dial-code="1664"
													data-country-code="ms"
												>
													<div class="flag-box">
														<div class="iti-flag ms"></div>
													</div>
													<span class="country-name">
														Montserrat
													</span>
													<span class="dial-code">
														+1664
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ma"
													role="option"
													data-dial-code="212"
													data-country-code="ma"
												>
													<div class="flag-box">
														<div class="iti-flag ma"></div>
													</div>
													<span class="country-name">
														Morocco (‫المغرب‬‎)
													</span>
													<span class="dial-code">
														+212
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mz"
													role="option"
													data-dial-code="258"
													data-country-code="mz"
												>
													<div class="flag-box">
														<div class="iti-flag mz"></div>
													</div>
													<span class="country-name">
														Mozambique (Moçambique)
													</span>
													<span class="dial-code">
														+258
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mm"
													role="option"
													data-dial-code="95"
													data-country-code="mm"
												>
													<div class="flag-box">
														<div class="iti-flag mm"></div>
													</div>
													<span class="country-name">
														Myanmar (Burma) (မြန်မာ)
													</span>
													<span class="dial-code">
														+95
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-na"
													role="option"
													data-dial-code="264"
													data-country-code="na"
												>
													<div class="flag-box">
														<div class="iti-flag na"></div>
													</div>
													<span class="country-name">
														Namibia (Namibië)
													</span>
													<span class="dial-code">
														+264
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nr"
													role="option"
													data-dial-code="674"
													data-country-code="nr"
												>
													<div class="flag-box">
														<div class="iti-flag nr"></div>
													</div>
													<span class="country-name">
														Nauru
													</span>
													<span class="dial-code">
														+674
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-np"
													role="option"
													data-dial-code="977"
													data-country-code="np"
												>
													<div class="flag-box">
														<div class="iti-flag np"></div>
													</div>
													<span class="country-name">
														Nepal (नेपाल)
													</span>
													<span class="dial-code">
														+977
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nl"
													role="option"
													data-dial-code="31"
													data-country-code="nl"
												>
													<div class="flag-box">
														<div class="iti-flag nl"></div>
													</div>
													<span class="country-name">
														Netherlands (Nederland)
													</span>
													<span class="dial-code">
														+31
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nc"
													role="option"
													data-dial-code="687"
													data-country-code="nc"
												>
													<div class="flag-box">
														<div class="iti-flag nc"></div>
													</div>
													<span class="country-name">
														New Caledonia
														(Nouvelle-Calédonie)
													</span>
													<span class="dial-code">
														+687
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nz"
													role="option"
													data-dial-code="64"
													data-country-code="nz"
												>
													<div class="flag-box">
														<div class="iti-flag nz"></div>
													</div>
													<span class="country-name">
														New Zealand
													</span>
													<span class="dial-code">
														+64
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ni"
													role="option"
													data-dial-code="505"
													data-country-code="ni"
												>
													<div class="flag-box">
														<div class="iti-flag ni"></div>
													</div>
													<span class="country-name">
														Nicaragua
													</span>
													<span class="dial-code">
														+505
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ne"
													role="option"
													data-dial-code="227"
													data-country-code="ne"
												>
													<div class="flag-box">
														<div class="iti-flag ne"></div>
													</div>
													<span class="country-name">
														Niger (Nijar)
													</span>
													<span class="dial-code">
														+227
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ng"
													role="option"
													data-dial-code="234"
													data-country-code="ng"
												>
													<div class="flag-box">
														<div class="iti-flag ng"></div>
													</div>
													<span class="country-name">
														Nigeria
													</span>
													<span class="dial-code">
														+234
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nu"
													role="option"
													data-dial-code="683"
													data-country-code="nu"
												>
													<div class="flag-box">
														<div class="iti-flag nu"></div>
													</div>
													<span class="country-name">
														Niue
													</span>
													<span class="dial-code">
														+683
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-nf"
													role="option"
													data-dial-code="672"
													data-country-code="nf"
												>
													<div class="flag-box">
														<div class="iti-flag nf"></div>
													</div>
													<span class="country-name">
														Norfolk Island
													</span>
													<span class="dial-code">
														+672
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kp"
													role="option"
													data-dial-code="850"
													data-country-code="kp"
												>
													<div class="flag-box">
														<div class="iti-flag kp"></div>
													</div>
													<span class="country-name">
														North Korea (조선
														민주주의 인민 공화국)
													</span>
													<span class="dial-code">
														+850
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mp"
													role="option"
													data-dial-code="1670"
													data-country-code="mp"
												>
													<div class="flag-box">
														<div class="iti-flag mp"></div>
													</div>
													<span class="country-name">
														Northern Mariana Islands
													</span>
													<span class="dial-code">
														+1670
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-no"
													role="option"
													data-dial-code="47"
													data-country-code="no"
												>
													<div class="flag-box">
														<div class="iti-flag no"></div>
													</div>
													<span class="country-name">
														Norway (Norge)
													</span>
													<span class="dial-code">
														+47
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-om"
													role="option"
													data-dial-code="968"
													data-country-code="om"
												>
													<div class="flag-box">
														<div class="iti-flag om"></div>
													</div>
													<span class="country-name">
														Oman (‫عُمان‬‎)
													</span>
													<span class="dial-code">
														+968
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pk"
													role="option"
													data-dial-code="92"
													data-country-code="pk"
												>
													<div class="flag-box">
														<div class="iti-flag pk"></div>
													</div>
													<span class="country-name">
														Pakistan (‫پاکستان‬‎)
													</span>
													<span class="dial-code">
														+92
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pw"
													role="option"
													data-dial-code="680"
													data-country-code="pw"
												>
													<div class="flag-box">
														<div class="iti-flag pw"></div>
													</div>
													<span class="country-name">
														Palau
													</span>
													<span class="dial-code">
														+680
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ps"
													role="option"
													data-dial-code="970"
													data-country-code="ps"
												>
													<div class="flag-box">
														<div class="iti-flag ps"></div>
													</div>
													<span class="country-name">
														Palestine (‫فلسطين‬‎)
													</span>
													<span class="dial-code">
														+970
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pa"
													role="option"
													data-dial-code="507"
													data-country-code="pa"
												>
													<div class="flag-box">
														<div class="iti-flag pa"></div>
													</div>
													<span class="country-name">
														Panama (Panamá)
													</span>
													<span class="dial-code">
														+507
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pg"
													role="option"
													data-dial-code="675"
													data-country-code="pg"
												>
													<div class="flag-box">
														<div class="iti-flag pg"></div>
													</div>
													<span class="country-name">
														Papua New Guinea
													</span>
													<span class="dial-code">
														+675
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-py"
													role="option"
													data-dial-code="595"
													data-country-code="py"
												>
													<div class="flag-box">
														<div class="iti-flag py"></div>
													</div>
													<span class="country-name">
														Paraguay
													</span>
													<span class="dial-code">
														+595
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pe"
													role="option"
													data-dial-code="51"
													data-country-code="pe"
												>
													<div class="flag-box">
														<div class="iti-flag pe"></div>
													</div>
													<span class="country-name">
														Peru (Perú)
													</span>
													<span class="dial-code">
														+51
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ph"
													role="option"
													data-dial-code="63"
													data-country-code="ph"
												>
													<div class="flag-box">
														<div class="iti-flag ph"></div>
													</div>
													<span class="country-name">
														Philippines
													</span>
													<span class="dial-code">
														+63
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pl"
													role="option"
													data-dial-code="48"
													data-country-code="pl"
												>
													<div class="flag-box">
														<div class="iti-flag pl"></div>
													</div>
													<span class="country-name">
														Poland (Polska)
													</span>
													<span class="dial-code">
														+48
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pt"
													role="option"
													data-dial-code="351"
													data-country-code="pt"
												>
													<div class="flag-box">
														<div class="iti-flag pt"></div>
													</div>
													<span class="country-name">
														Portugal
													</span>
													<span class="dial-code">
														+351
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pr"
													role="option"
													data-dial-code="1"
													data-country-code="pr"
												>
													<div class="flag-box">
														<div class="iti-flag pr"></div>
													</div>
													<span class="country-name">
														Puerto Rico
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-qa"
													role="option"
													data-dial-code="974"
													data-country-code="qa"
												>
													<div class="flag-box">
														<div class="iti-flag qa"></div>
													</div>
													<span class="country-name">
														Qatar (‫قطر‬‎)
													</span>
													<span class="dial-code">
														+974
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-re"
													role="option"
													data-dial-code="262"
													data-country-code="re"
												>
													<div class="flag-box">
														<div class="iti-flag re"></div>
													</div>
													<span class="country-name">
														Réunion (La Réunion)
													</span>
													<span class="dial-code">
														+262
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ro"
													role="option"
													data-dial-code="40"
													data-country-code="ro"
												>
													<div class="flag-box">
														<div class="iti-flag ro"></div>
													</div>
													<span class="country-name">
														Romania (România)
													</span>
													<span class="dial-code">
														+40
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ru"
													role="option"
													data-dial-code="7"
													data-country-code="ru"
												>
													<div class="flag-box">
														<div class="iti-flag ru"></div>
													</div>
													<span class="country-name">
														Russia (Россия)
													</span>
													<span class="dial-code">
														+7
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-rw"
													role="option"
													data-dial-code="250"
													data-country-code="rw"
												>
													<div class="flag-box">
														<div class="iti-flag rw"></div>
													</div>
													<span class="country-name">
														Rwanda
													</span>
													<span class="dial-code">
														+250
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-bl"
													role="option"
													data-dial-code="590"
													data-country-code="bl"
												>
													<div class="flag-box">
														<div class="iti-flag bl"></div>
													</div>
													<span class="country-name">
														Saint Barthélemy
													</span>
													<span class="dial-code">
														+590
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-sh"
													role="option"
													data-dial-code="290"
													data-country-code="sh"
												>
													<div class="flag-box">
														<div class="iti-flag sh"></div>
													</div>
													<span class="country-name">
														Saint Helena
													</span>
													<span class="dial-code">
														+290
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-kn"
													role="option"
													data-dial-code="1869"
													data-country-code="kn"
												>
													<div class="flag-box">
														<div class="iti-flag kn"></div>
													</div>
													<span class="country-name">
														Saint Kitts and Nevis
													</span>
													<span class="dial-code">
														+1869
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-lc"
													role="option"
													data-dial-code="1758"
													data-country-code="lc"
												>
													<div class="flag-box">
														<div class="iti-flag lc"></div>
													</div>
													<span class="country-name">
														Saint Lucia
													</span>
													<span class="dial-code">
														+1758
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-mf"
													role="option"
													data-dial-code="590"
													data-country-code="mf"
												>
													<div class="flag-box">
														<div class="iti-flag mf"></div>
													</div>
													<span class="country-name">
														Saint Martin
														(Saint-Martin (partie
														française))
													</span>
													<span class="dial-code">
														+590
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-pm"
													role="option"
													data-dial-code="508"
													data-country-code="pm"
												>
													<div class="flag-box">
														<div class="iti-flag pm"></div>
													</div>
													<span class="country-name">
														Saint Pierre and
														Miquelon
														(Saint-Pierre-et-Miquelon)
													</span>
													<span class="dial-code">
														+508
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-vc"
													role="option"
													data-dial-code="1784"
													data-country-code="vc"
												>
													<div class="flag-box">
														<div class="iti-flag vc"></div>
													</div>
													<span class="country-name">
														Saint Vincent and the
														Grenadines
													</span>
													<span class="dial-code">
														+1784
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-ws"
													role="option"
													data-dial-code="685"
													data-country-code="ws"
												>
													<div class="flag-box">
														<div class="iti-flag ws"></div>
													</div>
													<span class="country-name">
														Samoa
													</span>
													<span class="dial-code">
														+685
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-sm"
													role="option"
													data-dial-code="378"
													data-country-code="sm"
												>
													<div class="flag-box">
														<div class="iti-flag sm"></div>
													</div>
													<span class="country-name">
														San Marino
													</span>
													<span class="dial-code">
														+378
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-st"
													role="option"
													data-dial-code="239"
													data-country-code="st"
												>
													<div class="flag-box">
														<div class="iti-flag st"></div>
													</div>
													<span class="country-name">
														São Tomé and Príncipe
														(São Tomé e Príncipe)
													</span>
													<span class="dial-code">
														+239
													</span>
												</option>
												<option
													class="country standard"
													id="iti-item-sa"
													role="option"
													data-dial-code="966"
													data-country-code="sa"
												>
													<div class="flag-box">
														<div class="iti-flag sa"></div>
													</div>
													<span class="country-name">
														Saudi Arabia (‫المملكة
														العربية السعودية‬‎)
													</span>
													<span class="dial-code">
														+966
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sn"
													role="option"
													data-dial-code="221"
													data-country-code="sn"
												>
													<div class="flag-box">
														<div class="iti-flag sn"></div>
													</div>
													<span class="country-name">
														Senegal (Sénégal)
													</span>
													<span class="dial-code">
														+221
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-rs"
													role="option"
													data-dial-code="381"
													data-country-code="rs"
												>
													<div class="flag-box">
														<div class="iti-flag rs"></div>
													</div>
													<span class="country-name">
														Serbia (Србија)
													</span>
													<span class="dial-code">
														+381
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sc"
													role="option"
													data-dial-code="248"
													data-country-code="sc"
												>
													<div class="flag-box">
														<div class="iti-flag sc"></div>
													</div>
													<span class="country-name">
														Seychelles
													</span>
													<span class="dial-code">
														+248
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sl"
													role="option"
													data-dial-code="232"
													data-country-code="sl"
												>
													<div class="flag-box">
														<div class="iti-flag sl"></div>
													</div>
													<span class="country-name">
														Sierra Leone
													</span>
													<span class="dial-code">
														+232
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sg"
													role="option"
													data-dial-code="65"
													data-country-code="sg"
												>
													<div class="flag-box">
														<div class="iti-flag sg"></div>
													</div>
													<span class="country-name">
														Singapore
													</span>
													<span class="dial-code">
														+65
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sx"
													role="option"
													data-dial-code="1721"
													data-country-code="sx"
												>
													<div class="flag-box">
														<div class="iti-flag sx"></div>
													</div>
													<span class="country-name">
														Sint Maarten
													</span>
													<span class="dial-code">
														+1721
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sk"
													role="option"
													data-dial-code="421"
													data-country-code="sk"
												>
													<div class="flag-box">
														<div class="iti-flag sk"></div>
													</div>
													<span class="country-name">
														Slovakia (Slovensko)
													</span>
													<span class="dial-code">
														+421
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-si"
													role="option"
													data-dial-code="386"
													data-country-code="si"
												>
													<div class="flag-box">
														<div class="iti-flag si"></div>
													</div>
													<span class="country-name">
														Slovenia (Slovenija)
													</span>
													<span class="dial-code">
														+386
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sb"
													role="option"
													data-dial-code="677"
													data-country-code="sb"
												>
													<div class="flag-box">
														<div class="iti-flag sb"></div>
													</div>
													<span class="country-name">
														Solomon Islands
													</span>
													<span class="dial-code">
														+677
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-so"
													role="option"
													data-dial-code="252"
													data-country-code="so"
												>
													<div class="flag-box">
														<div class="iti-flag so"></div>
													</div>
													<span class="country-name">
														Somalia (Soomaaliya)
													</span>
													<span class="dial-code">
														+252
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-za"
													role="option"
													data-dial-code="27"
													data-country-code="za"
												>
													<div class="flag-box">
														<div class="iti-flag za"></div>
													</div>
													<span class="country-name">
														South Africa
													</span>
													<span class="dial-code">
														+27
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-kr"
													role="option"
													data-dial-code="82"
													data-country-code="kr"
												>
													<div class="flag-box">
														<div class="iti-flag kr"></div>
													</div>
													<span class="country-name">
														South Korea (대한민국)
													</span>
													<span class="dial-code">
														+82
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ss"
													role="option"
													data-dial-code="211"
													data-country-code="ss"
												>
													<div class="flag-box">
														<div class="iti-flag ss"></div>
													</div>
													<span class="country-name">
														South Sudan (‫جنوب
														السودان‬‎)
													</span>
													<span class="dial-code">
														+211
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-es"
													role="option"
													data-dial-code="34"
													data-country-code="es"
												>
													<div class="flag-box">
														<div class="iti-flag es"></div>
													</div>
													<span class="country-name">
														Spain (España)
													</span>
													<span class="dial-code">
														+34
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-lk"
													role="option"
													data-dial-code="94"
													data-country-code="lk"
												>
													<div class="flag-box">
														<div class="iti-flag lk"></div>
													</div>
													<span class="country-name">
														Sri Lanka (ශ්‍රී ලංකාව)
													</span>
													<span class="dial-code">
														+94
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sd"
													role="option"
													data-dial-code="249"
													data-country-code="sd"
												>
													<div class="flag-box">
														<div class="iti-flag sd"></div>
													</div>
													<span class="country-name">
														Sudan (‫السودان‬‎)
													</span>
													<span class="dial-code">
														+249
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sr"
													role="option"
													data-dial-code="597"
													data-country-code="sr"
												>
													<div class="flag-box">
														<div class="iti-flag sr"></div>
													</div>
													<span class="country-name">
														Suriname
													</span>
													<span class="dial-code">
														+597
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sj"
													role="option"
													data-dial-code="47"
													data-country-code="sj"
												>
													<div class="flag-box">
														<div class="iti-flag sj"></div>
													</div>
													<span class="country-name">
														Svalbard and Jan Mayen
													</span>
													<span class="dial-code">
														+47
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sz"
													role="option"
													data-dial-code="268"
													data-country-code="sz"
												>
													<div class="flag-box">
														<div class="iti-flag sz"></div>
													</div>
													<span class="country-name">
														Swaziland
													</span>
													<span class="dial-code">
														+268
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-se"
													role="option"
													data-dial-code="46"
													data-country-code="se"
												>
													<div class="flag-box">
														<div class="iti-flag se"></div>
													</div>
													<span class="country-name">
														Sweden (Sverige)
													</span>
													<span class="dial-code">
														+46
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ch"
													role="option"
													data-dial-code="41"
													data-country-code="ch"
												>
													<div class="flag-box">
														<div class="iti-flag ch"></div>
													</div>
													<span class="country-name">
														Switzerland (Schweiz)
													</span>
													<span class="dial-code">
														+41
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-sy"
													role="option"
													data-dial-code="963"
													data-country-code="sy"
												>
													<div class="flag-box">
														<div class="iti-flag sy"></div>
													</div>
													<span class="country-name">
														Syria (‫سوريا‬‎)
													</span>
													<span class="dial-code">
														+963
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tw"
													role="option"
													data-dial-code="886"
													data-country-code="tw"
												>
													<div class="flag-box">
														<div class="iti-flag tw"></div>
													</div>
													<span class="country-name">
														Taiwan (台灣)
													</span>
													<span class="dial-code">
														+886
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tj"
													role="option"
													data-dial-code="992"
													data-country-code="tj"
												>
													<div class="flag-box">
														<div class="iti-flag tj"></div>
													</div>
													<span class="country-name">
														Tajikistan
													</span>
													<span class="dial-code">
														+992
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tz"
													role="option"
													data-dial-code="255"
													data-country-code="tz"
												>
													<div class="flag-box">
														<div class="iti-flag tz"></div>
													</div>
													<span class="country-name">
														Tanzania
													</span>
													<span class="dial-code">
														+255
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-th"
													role="option"
													data-dial-code="66"
													data-country-code="th"
												>
													<div class="flag-box">
														<div class="iti-flag th"></div>
													</div>
													<span class="country-name">
														Thailand (ไทย)
													</span>
													<span class="dial-code">
														+66
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tl"
													role="option"
													data-dial-code="670"
													data-country-code="tl"
												>
													<div class="flag-box">
														<div class="iti-flag tl"></div>
													</div>
													<span class="country-name">
														Timor-Leste
													</span>
													<span class="dial-code">
														+670
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tg"
													role="option"
													data-dial-code="228"
													data-country-code="tg"
												>
													<div class="flag-box">
														<div class="iti-flag tg"></div>
													</div>
													<span class="country-name">
														Togo
													</span>
													<span class="dial-code">
														+228
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tk"
													role="option"
													data-dial-code="690"
													data-country-code="tk"
												>
													<div class="flag-box">
														<div class="iti-flag tk"></div>
													</div>
													<span class="country-name">
														Tokelau
													</span>
													<span class="dial-code">
														+690
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-to"
													role="option"
													data-dial-code="676"
													data-country-code="to"
												>
													<div class="flag-box">
														<div class="iti-flag to"></div>
													</div>
													<span class="country-name">
														Tonga
													</span>
													<span class="dial-code">
														+676
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tt"
													role="option"
													data-dial-code="1868"
													data-country-code="tt"
												>
													<div class="flag-box">
														<div class="iti-flag tt"></div>
													</div>
													<span class="country-name">
														Trinidad and Tobago
													</span>
													<span class="dial-code">
														+1868
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tn"
													role="option"
													data-dial-code="216"
													data-country-code="tn"
												>
													<div class="flag-box">
														<div class="iti-flag tn"></div>
													</div>
													<span class="country-name">
														Tunisia (‫تونس‬‎)
													</span>
													<span class="dial-code">
														+216
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tr"
													role="option"
													data-dial-code="90"
													data-country-code="tr"
												>
													<div class="flag-box">
														<div class="iti-flag tr"></div>
													</div>
													<span class="country-name">
														Turkey (Türkiye)
													</span>
													<span class="dial-code">
														+90
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tm"
													role="option"
													data-dial-code="993"
													data-country-code="tm"
												>
													<div class="flag-box">
														<div class="iti-flag tm"></div>
													</div>
													<span class="country-name">
														Turkmenistan
													</span>
													<span class="dial-code">
														+993
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tc"
													role="option"
													data-dial-code="1649"
													data-country-code="tc"
												>
													<div class="flag-box">
														<div class="iti-flag tc"></div>
													</div>
													<span class="country-name">
														Turks and Caicos Islands
													</span>
													<span class="dial-code">
														+1649
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-tv"
													role="option"
													data-dial-code="688"
													data-country-code="tv"
												>
													<div class="flag-box">
														<div class="iti-flag tv"></div>
													</div>
													<span class="country-name">
														Tuvalu
													</span>
													<span class="dial-code">
														+688
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-vi"
													role="option"
													data-dial-code="1340"
													data-country-code="vi"
												>
													<div class="flag-box">
														<div class="iti-flag vi"></div>
													</div>
													<span class="country-name">
														U.S. Virgin Islands
													</span>
													<span class="dial-code">
														+1340
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ug"
													role="option"
													data-dial-code="256"
													data-country-code="ug"
												>
													<div class="flag-box">
														<div class="iti-flag ug"></div>
													</div>
													<span class="country-name">
														Uganda
													</span>
													<span class="dial-code">
														+256
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ua"
													role="option"
													data-dial-code="380"
													data-country-code="ua"
												>
													<div class="flag-box">
														<div class="iti-flag ua"></div>
													</div>
													<span class="country-name">
														Ukraine (Україна)
													</span>
													<span class="dial-code">
														+380
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ae"
													role="option"
													data-dial-code="971"
													data-country-code="ae"
												>
													<div class="flag-box">
														<div class="iti-flag ae"></div>
													</div>
													<span class="country-name">
														United Arab Emirates
														(‫الإمارات العربية
														المتحدة‬‎)
													</span>
													<span class="dial-code">
														+971
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-gb"
													role="option"
													data-dial-code="44"
													data-country-code="gb"
												>
													<div class="flag-box">
														<div class="iti-flag gb"></div>
													</div>
													<span class="country-name">
														United Kingdom
													</span>
													<span class="dial-code">
														+44
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-us"
													role="option"
													data-dial-code="1"
													data-country-code="us"
												>
													<div class="flag-box">
														<div class="iti-flag us"></div>
													</div>
													<span class="country-name">
														United States
													</span>
													<span class="dial-code">
														+1
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-uy"
													role="option"
													data-dial-code="598"
													data-country-code="uy"
												>
													<div class="flag-box">
														<div class="iti-flag uy"></div>
													</div>
													<span class="country-name">
														Uruguay
													</span>
													<span class="dial-code">
														+598
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-uz"
													role="option"
													data-dial-code="998"
													data-country-code="uz"
												>
													<div class="flag-box">
														<div class="iti-flag uz"></div>
													</div>
													<span class="country-name">
														Uzbekistan (Oʻzbekiston)
													</span>
													<span class="dial-code">
														+998
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-vu"
													role="option"
													data-dial-code="678"
													data-country-code="vu"
												>
													<div class="flag-box">
														<div class="iti-flag vu"></div>
													</div>
													<span class="country-name">
														Vanuatu
													</span>
													<span class="dial-code">
														+678
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-va"
													role="option"
													data-dial-code="39"
													data-country-code="va"
												>
													<div class="flag-box">
														<div class="iti-flag va"></div>
													</div>
													<span class="country-name">
														Vatican City (Città del
														Vaticano)
													</span>
													<span class="dial-code">
														+39
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ve"
													role="option"
													data-dial-code="58"
													data-country-code="ve"
												>
													<div class="flag-box">
														<div class="iti-flag ve"></div>
													</div>
													<span class="country-name">
														Venezuela
													</span>
													<span class="dial-code">
														+58
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-vn"
													role="option"
													data-dial-code="84"
													data-country-code="vn"
												>
													<div class="flag-box">
														<div class="iti-flag vn"></div>
													</div>
													<span class="country-name">
														Vietnam (Việt Nam)
													</span>
													<span class="dial-code">
														+84
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-wf"
													role="option"
													data-dial-code="681"
													data-country-code="wf"
												>
													<div class="flag-box">
														<div class="iti-flag wf"></div>
													</div>
													<span class="country-name">
														Wallis and Futuna
														(Wallis-et-Futuna)
													</span>
													<span class="dial-code">
														+681
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-eh"
													role="option"
													data-dial-code="212"
													data-country-code="eh"
												>
													<div class="flag-box">
														<div class="iti-flag eh"></div>
													</div>
													<span class="country-name">
														Western Sahara (‫الصحراء
														الغربية‬‎)
													</span>
													<span class="dial-code">
														+212
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ye"
													role="option"
													data-dial-code="967"
													data-country-code="ye"
												>
													<div class="flag-box">
														<div class="iti-flag ye"></div>
													</div>
													<span class="country-name">
														Yemen (‫اليمن‬‎)
													</span>
													<span class="dial-code">
														+967
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-zm"
													role="option"
													data-dial-code="260"
													data-country-code="zm"
												>
													<div class="flag-box">
														<div class="iti-flag zm"></div>
													</div>
													<span class="country-name">
														Zambia
													</span>
													<span class="dial-code">
														+260
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-zw"
													role="option"
													data-dial-code="263"
													data-country-code="zw"
												>
													<div class="flag-box">
														<div class="iti-flag zw"></div>
													</div>
													<span class="country-name">
														Zimbabwe
													</span>
													<span class="dial-code">
														+263
													</span>
												</option>{' '}
												<option
													class="country standard"
													id="iti-item-ax"
													role="option"
													data-dial-code="358"
													data-country-code="ax"
												>
													<div class="flag-box">
														<div class="iti-flag ax"></div>
													</div>
													<span class="country-name">
														Åland Islands
													</span>
													<span class="dial-code">
														+358
													</span>
												</option>
											</select>
										</div>
										<input
											type="tel"
											name="telefono"
											required
											enable-intl="1"
											data-ref-label="Telfono"
											id="telefono"
											autoComplete="off"
											placeholder=""
											value={emailTelefono}
											onChange={(e) =>
												setEmailTelefono(e.target.value)
											}
										/>
									</div>
								</div>
							</div>

							<div className="row" style={{ gap: '25px' }}>
								<div className="form-item">
									<label htmlFor="estado">
										Estado
										<span className="erf-required">*</span>
									</label>
									<select
										id="estado"
										name="estado"
										required
										onChange={(e) =>
											setEmailEstado(e.target.value)
										}
									>
										<option value="Aguascalientes">
											Aguascalientes
										</option>
										<option value="Ciudad de México">
											Ciudad de México
										</option>
										<option value="Estado de México">
											Estado de México
										</option>
										<option value="Guanajuato">
											Guanajuato
										</option>
										<option value="Michoacán">
											Michoacán
										</option>
										<option value="Morelos">Morelos</option>
										<option value="Oaxaca">Oaxaca</option>
										<option value="Puebla">Puebla</option>
										<option value="Querétaro">
											Querétaro
										</option>
										<option value="Tlaxcala">
											Tlaxcala
										</option>
										<option value="Veracruz">
											Veracruz
										</option>
										<option value="Otro">Otro</option>
									</select>
								</div>

								<div className="form-item">
									<label htmlFor="municipio">
										Municipio
										<span className="erf-required">*</span>
									</label>
									<input
										type="tel"
										id="municipio"
										name="municipio"
										value={emailCiudad}
										onChange={(e) =>
											setEmailCiudad(e.target.value)
										}
										required
									/>
								</div>
							</div>

							<div className="row" style={{ gap: '25px' }}>
								<div className="form-item">
									<label htmlFor="tenido">
										¿Has tenido un crédito con Banco
										Forjadores?
										<span className="erf-required">*</span>
									</label>
									<select
										id="tenido"
										name="tenido"
										required
										onChange={(e) => {
											setEmailCreditoPrevio(
												e.target.value,
											);
										}}
									>
										<option value="Sí">Sí</option>
										<option value="No">No</option>
									</select>
								</div>

								<div className="form-item">
									<label htmlFor="ciclo">
										¿Concluiste el ciclo completo de tu
										crédito?
										<span className="erf-required">*</span>
									</label>
									<select
										id="ciclo"
										name="ciclo"
										required
										onChange={(e) => {
											setEmailClicloCompleto(
												e.target.value,
											);
										}}
									>
										<option value="Sí">Sí</option>
										<option value="No">No</option>
									</select>
								</div>
							</div>

							<div
								className="form-item"
								style={{ width: '100%' }}
							>
								<label htmlFor="mensaje">
									Mensaje
									<span className="erf-required">*</span>
								</label>
								<textarea
									style={{ height: 'unset !important' }}
									rows="5"
									id="mensaje"
									name="mensaje"
									onChange={(e) =>
										setEmailMensaje(e.target.value)
									}
									required
								></textarea>
							</div>

							<div
								className="form-item cb"
								style={{ width: '100%' }}
							>
								<input
									type="checkbox"
									name="avisopriv"
									id="aviso"
									value="1"
									aria-invalid="false"
									onChange={(e) =>
										setEmailAceptoPoliticas(
											e.target.checked,
										)
									}
								/>
								<span>
									He leído y acepto el{' '}
									<Link to={'/aviso-de-privacidad/'}>
										Aviso de Privacidad
									</Link>
								</span>
							</div>

							<button
								type="submit"
								className="forjadores-btn"
								disabled={emailEnviando}
								style={{
									opacity: emailEnviando ? 0.5 : 1,
								}}
							>
								Enviar
							</button>

							{emailEnviado && (
								<p style={{ color: '#449b51' }}>
									¡Mensaje enviado con éxito!
								</p>
							)}
						</form>
					</div>
				</div>
			</div>

			<div id="forjadorasLegal" class="product-legal">
				<h2>Términos y condiciones</h2>
				<p>
					Banco Forjadores S.A, INSTITUCIÓN DE BANCA MÚLTIPLE (en
					adelante “Banco Forjadores”). Aplica para el producto
					denominado CRÉDITO GRUPAL. Los términos y requisitos de
					contratación para este producto pueden ser consultados en
					www.forjadores.mx.{' '}
					<b>
						La Tasa fija anual promedio de intereses para este
						producto es de 84.07% (Ochenta y cuatro punto cero siete
						por ciento). EL CAT (Costo Anual Total) promedio para
						este producto es de 177.2% SIN IVA (Ciento setenta y
						siete punto dos por ciento SIN IVA)
					</b>
					, lo anterior para fines informativos y de comparación
					exclusivamente, CAT calculado el 9 de Mayo de 2023 vigente
					al 31 de diciembre del 2023. Leyendas de advertencia: i)
					Incumplir tus obligaciones te puede generar comisiones e
					intereses moratorios, ii) Contratar créditos que excedan tu
					capacidad de pago afecta tu historial crediticio, iii) El
					avalista, obligado solidario y/o coacreditado responderá
					como obligado principal por el total del pago frente a la
					Institución Financiera. Este producto requiere la
					contratación de los seguros que pueden ser consultados en la
					página de internet www. forjadores.mx,{' '}
					<b>
						el cliente tiene derecho a contratar este seguro a
						través de un tercero independiente
					</b>
					. Para mayor información de este producto, consulta la
					página web www.forjadores.mx o llama a los teléfonos
					55.56-11-24-50 ó LADA SIN COSTO 800-830-00-05. Este producto
					se oferta en pesos mexicanos, consulta la cobertura
					geográfica en la página de internet. UNE:
					unidad_especializada@forjadores.com.mx, tel: 55 56 11 24 50,
					800 83 000 05. CONDUSEF: www.condusef.gob.mx, tel: 55 53 40
					09 99 *Se requiere presentar documento probatorio de la tasa
					de interés y monto que se tenga con otra institución
					financiera.
				</p>
			</div>
		</div>
	);
}

export default ForjadorasCorazon;
