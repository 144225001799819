import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import CalRies_2014 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2014.pdf';
import CalRies_2015 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2015.pdf';
import CalRies_2016 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2016.pdf';
import CalRies_2017 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2017.pdf';
import CalRies_2018 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2018.pdf';
import CalRies_2019 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-VERUM-2019.pdf';
import CalRies_2020 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Caificacion-de-Riesgo-VERUM-2020.pdf';
import CalRies_2021 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-VERUM-2021-2022.pdf';
import CalRies_2022 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-VERUM-2022.pdf';
import CalRies_HR_2016 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-HR-RATING-2016.pdf';
import CalRies_HR_2017 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-HR-RATING-2017.pdf';
import CalRies_HR_2018 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-HR-RATING-2018.pdf';
import CalRies_HR_2019 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificación-de-Riesgo-HR-RATING-2019.pdf';
import CalRies_HR_2020 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-HR-RATING-2020.pdf';
import CalRies_HR_2021 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-HR-Comunicado-2021.pdf';
import CalRies_HR_20212 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-HR-RATING-2021-2022.pdf';
import CalRies_HR_2022 from '../../../../assets/documents/InformacionFinanciera/CalificacionRiesgo/Calificacion-de-Riesgo-HR-Rating-2022.pdf';

import '../../../../style/quienessomos.css';

function CalifRiesgo() {
    return (
        <div class="post-inner">
            <div class="entry-content">
                <div class="information-intro">
                    <img src={encabezado} decoding="async" class="lazyloaded" data-ll-status="loaded" />
                    <h1><small>CALIFICACIÓN DE RIESGO</small></h1>
                </div>
                <div class="information-files">
                    <a href={CalRies_2014} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2014</a>
                    <a href={CalRies_2015} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2015</a>
                    <a href={CalRies_2016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2016</a>
                    <a href={CalRies_2017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2017</a>
                    <a href={CalRies_2018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2018</a>
                    <a href={CalRies_2019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2019</a>
                    <a href={CalRies_2020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2020</a>
                    <a href={CalRies_2021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2021-2022</a>
                    <a href={CalRies_2022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />Calificación de Riesgo VERUM 2022</a>

                    <a href={CalRies_HR_2016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2016</a>
                    <a href={CalRies_HR_2017} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2017</a>
                    <a href={CalRies_HR_2018} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2018</a>
                    <a href={CalRies_HR_2019} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2019</a>
                    <a href={CalRies_HR_2020} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2020</a>
                    <a href={CalRies_HR_2021} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2021</a>
                    <a href={CalRies_HR_20212} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2021-2022</a>
                    <a href={CalRies_HR_2022} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> Calificación de Riesgo HR RATING 2022</a>
                </div>

                <p></p>
            </div>
        </div>
    );
}

export default CalifRiesgo;