import { useNavigate } from 'react-router-dom';
import '../../style/programas_de_beneficios.css';
import encabezado_programa_de_beneficios from '../../assets/images/Encabezados/encabezado_programa_de_beneficios.png';
import grafico_recomienda_y_gana from '../../assets/images/grafico-recomienda-y-gana.png';
import UseBeneficiosStore from '../../stores/UseBeneficiosStore';
import { useEffect } from 'react';

function ProgramasDeBeneficios() {
	const { beneficios, loadingBeneficios, getBeneficios } =
		UseBeneficiosStore();
	const navigate = useNavigate();

	useEffect(() => {
		getBeneficios();
	}, []);

	return (
		<div className="programas-de-beneficios">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_programa_de_beneficios}
					alt="Encabezado prorgama de beneficios"
				/>
			</section>
			<section className="beneficios-list">
				{loadingBeneficios ? (
					<p>Cargando beneficios...</p>
				) : beneficios.length === 0 ? (
					<p>No hay beneficios para mostrar</p>
				) : (
					beneficios.map((beneficio) => {
						return (
							<div
								key={beneficio.id_Beneficios}
								className="beneficio"
							>
								<img
									src={grafico_recomienda_y_gana}
									alt="Beneficio image"
								/>
								<h2>{beneficio.titulo}</h2>
								<p>{beneficio.resumen}</p>
								<button
									onClick={() => {
										navigate(
											'/programas-de-beneficios/recomienda-y-gana/',
										);
									}}
								>
									Más informacion
								</button>
							</div>
						);
					})
				)}
			</section>
		</div>
	);
}

export default ProgramasDeBeneficios;
