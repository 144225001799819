import { create } from 'zustand';

const UseAcuerdosAGOA = create((set) => ({
	acuerdos: [],
	añosConDocumentos: [],
	loadingAcuerdos: false,
	getAcuerdos: async (año) => {
		try {
			set({ loadingAcuerdos: true });

			let url =
				process.env.REACT_APP_BACKEND_URL +
				'/api/AcuerdosAGOA/ObtenerDocumentos';

			if (año) {
				url = `${url}?añoDocumento=${año}`;
			}

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				redirect: 'follow',
			});

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ acuerdos: data });
			set({ loadingAcuerdos: false });

			const newAños = [];
			data.map((dato) => {
				const datoAño = dato.fechaDocumento.split('/')[2];

				if (!newAños.includes(datoAño)) {
					newAños.push(datoAño);
				}
			});

			set({ añosConDocumentos: newAños });
		} catch (error) {
			console.error('Error al obtener los acuerdos AGOA');
			set({ loadingAcuerdos: false });
		}
	},
}));

export default UseAcuerdosAGOA;
