import { useNavigate } from 'react-router-dom';
import icono_atencion_clientes from '../../assets/images/Iconos/icono-atencion-clientes.png';
import icono_despachos_cobranza from '../../assets/images/Iconos/icono-despachos-de-cobranza.png';
import icono_puntos_pago from '../../assets/images/Iconos/puntos_pago_icon.png';
import icono_sucursales from '../../assets/images/Iconos/sucursales_icon.png';
import logo_forjadores from '../../assets/images/Forjadores-Logo-1.jpg';
import icono_menu from '../../assets/images/Iconos/menu_hamburguesa.png';
import '../../style/header.css';
import { useEffect, useState } from 'react';
import Menu from '../Menu';
import UseContactoStore from '../../stores/UseContactoStore';

function Header() {
	const [openMenu, setOpenMenu] = useState(false);

	const { contacto, getContacto } = UseContactoStore();
	const navigate = useNavigate();

	useEffect(() => {
		getContacto();
	}, []);

	return (
		<header className="header">
			<div id="top-bar" style={{ fontSize: '0.9em' }}>
				<button
					onClick={() => {
						navigate(
							'/unidad-especializada-de-atencion-a-usuarios/',
						);
					}}
					className="atencion-clientes"
				>
					<img
						src={icono_atencion_clientes}
						alt=""
						className="icon"
						data-ll-status="loaded"
					/>
					Unidad Especializada de Atención a Usuarios
				</button>

				<div className="row">
					<button
						onClick={() => {
							navigate('/despachos-de-cobranza/');
						}}
					>
						<img
							src={icono_despachos_cobranza}
							alt=""
							className="icon"
							data-ll-status="loaded"
						/>
						DESPACHOS DE COBRANZA
					</button>
					<button
						onClick={() => {
							navigate('/puntos-de-pago/');
						}}
					>
						<img
							src={icono_puntos_pago}
							alt=""
							className="icon"
							data-ll-status="loaded"
						/>
						PUNTOS DE PAGO
					</button>
					<button
						onClick={() => {
							navigate('/oficinas-de-servicio/');
						}}
					>
						<img
							src={icono_sucursales}
							alt=""
							className="icon"
							data-ll-status="loaded"
						/>
						OFICINAS DE SERVICIO
					</button>
					<span
						style={{
							width: '2px',
							height: '15px',
							backgroundColor: '#e2bd23',
						}}
					></span>
					<a
						href={
							'tel:' + (contacto.telefonoFormateado
								? contacto.telefonoFormateado
								: '800 830 0005')
						}
						style={{
							fontSize: '19.5px',
							fontWeight: 'semibold',
							color: '#e2bd23',
							textDecoration: 'none',
						}}
					>
						{contacto.telefonoFormateado
							? contacto.telefonoFormateado
							: '800 830 0005'}
					</a>
				</div>
			</div>
			<div id="main-bar">
				<img
					className="forjadores-logo"
					src={logo_forjadores}
					onClick={() => {
						navigate('/');
					}}
				/>
				<button
					onClick={() => {
						setOpenMenu(!openMenu);
					}}
					className="menu-button row"
				>
					<span>Menú</span>
					<img className="menu-icon" src={icono_menu} />
				</button>
			</div>

			<Menu open={openMenu} setOpen={setOpenMenu} />
		</header>
	);
}

export default Header;
