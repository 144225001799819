import { Link, useParams } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import icono_pdf from '../../../assets/images/Iconos/icono_pdf.png';
import { useEffect } from 'react';
import UseAcuerdosAGOA from '../../../stores/UseAcuerdosAGOA';

function AsambleasAccionistasArchivos() {
	const { acuerdos, loadingAcuerdos, getAcuerdos } = UseAcuerdosAGOA();

	const { year } = useParams();

	useEffect(() => {
		getAcuerdos(year);
	}, []);

	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ASAMBLEAS {year}</small>
					</h1>
				</div>
				<div className="estatutos-folders information-files">
					{loadingAcuerdos ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							Cargando estatutos...
						</p>
					) : acuerdos.length === 0 ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							No hay archivo para mostrar
						</p>
					) : (
						acuerdos.map((documento) => {
							return (
								<Link
									key={documento.id}
									className="file"
									target="_blank"
									rel="noopener noreferrer"
									to={documento.documentoRuta}
								>
									<img src={icono_pdf} />
									{documento.nombre}
								</Link>
							);
						})
					)}
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default AsambleasAccionistasArchivos;
