import { create } from 'zustand';

const UseRazonApalancamiento = create((set) => ({
	documentos: [],
	añosConDocumentos: [],
	loadingDocumentos: false,
	getDocumentos: async (año) => {
		try {
			set({ loadingDocumentos: true });

			let url =
				process.env.REACT_APP_BACKEND_URL +
				'/api/RazonApalancamiento/ObtenerDocumentos';

			if (año) {
				url = `${url}?añoDocumento=${año}`;
			}

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				redirect: 'follow',
			});

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ documentos: data });
			set({ loadingDocumentos: false });

			/* OBTENER TODOS LOS AÑOS EN LOS QUE HAY DOCUMENTOS */
			const newAños = [];
			data.map((dato) => {
				const datoAño = dato.fechaDocumento.split('/')[2];

				if (!newAños.includes(datoAño)) {
					newAños.push(datoAño);
				}
			});

			set({ añosConDocumentos: newAños });
		} catch (error) {
			console.error('Error al obtener los documentos');
			set({ loadingDocumentos: false });
		}
	},
}));

export default UseRazonApalancamiento;
