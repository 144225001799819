import { create } from 'zustand';

const UseInformacionCorporativa = create((set) => ({
	estatutosSociales: [],
	modificacionesEstatutos: [],
	loadingEstatutosSociales: false,
	loadingModificacionesEstatutos: false,
	getEstatutosSociales: async () => {
		try {
			set({ loadingEstatutosSociales: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					'/api/InformacionCorporativa/EstatutosSociales/ObtenerDocumentos',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ estatutosSociales: data });
			set({ loadingEstatutosSociales: false });
		} catch (error) {
			console.error('Error al obtener los estatutos sociales');
			set({ loadingEstatutosSociales: false });
		}
	},
	getModificacionesEstatutos: async () => {
		try {
			set({ loadingModificacionesEstatutos: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					'/api/InformacionCorporativa/ModificacionesEstatutos/ObtenerDocumentos',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ modificacionesEstatutos: data });
			set({ loadingModificacionesEstatutos: false });
		} catch (error) {
			console.error(
				'Error al obtener las modificaciones de los estatutos',
			);
			set({ loadingModificacionesEstatutos: false });
		}
	},
}));

export default UseInformacionCorporativa;
