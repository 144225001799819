import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import carpeta from '../../../assets/images/Iconos/informacion_carpeta_financiera.png';
import { useEffect } from 'react';
import UseAcuerdosAGOA from '../../../stores/UseAcuerdosAGOA';

function AsambleasAccionistas() {
	const { añosConDocumentos, loadingAcuerdos, getAcuerdos } =
    UseAcuerdosAGOA();

	useEffect(() => {
		getAcuerdos();
	}, []);

	return (
		<div className="post-inner">
			<div className="entry-content">
				<div className="information-intro">
					<img
						src={encabezado}
						decoding="async"
						className="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ASAMBLEAS DE ACCIONISTAS</small>
					</h1>
				</div>
				<div className="estatutos-folders folders">
					{loadingAcuerdos ? (
						<p
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							Cargando documentos...
						</p>
					) : añosConDocumentos.length === 0 ? (
						<p
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							No hay documentos para mostrar
						</p>
					) : (
						añosConDocumentos?.map((año) => {
							return (
								<Link
									key={año}
									to={
										'/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-accionistas/' +
										año
									}
								>
									<img src={carpeta} />
									{año}
								</Link>
							);
						})
					)}
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default AsambleasAccionistas;
