import isotipo from '../../assets/images/Iconos/isotipo.png';
import inversion_lineas_verde from '../../assets/images/Inversion_lineas_verde.png';
import inversion_logoIPAB from '../../assets/images/Inversion_logoIPAB.png';
import '../../style/costos_comisiones.css';
import Modal from '../../components/Modal';
import { useEffect, useState } from 'react';
import UseGATStore from '../../stores/UseGATStore';
import DOMPurify from 'dompurify';
import UseCATStore from '../../stores/UseCATStore';

function CostosComisiones() {
	const [pagareForjadores, setPagareForjadores] = useState(null);
	const [forjadoresCede, setForjadoresCede] = useState(null);
	const [openModalSegurosVida, setOpenModalSegurosVida] = useState(false);
	const [openModalSeguroRobo, setOpenModalSeguroRobo] = useState(false);

	const {
		inversionesGAT,
		vigencias,
		loadingVigenias,
		loadingInversiones,
		getInversionesGAT,
		getVigencias,
	} = UseGATStore();

	const { CATDatos, loadingCAT, getCAT } = UseCATStore();

	useEffect(() => {
		getCAT();

		getInversionesGAT();
		getVigencias();
	}, []);

	useEffect(() => {
		const newPagareforjadores = inversionesGAT.find((inversion) => {
			return inversion.nombre === 'Pagare Forjadores';
		});
		const newForjadoresCede = inversionesGAT.find((inversion) => {
			return inversion.nombre === 'Forjadores cede';
		});

		setPagareForjadores(newPagareforjadores);
		setForjadoresCede(newForjadoresCede);
	}, [inversionesGAT]);

	return (
		<div className="costos-comisiones-container">
			{/* COSTOS Y COMISIONES */}
			<div style={{ marginBottom: '76px' }}>
				<h1 className="row title-1">
					<img src={isotipo} />
					Costos y Comisiones de productos de Crédito
				</h1>
				<p className="simple-text">
					Banco Forjadores, S.A., Institución de Banca Múltiple te da
					a conocer los costos y comisiones de sus productos de
					crédito.
				</p>

				<div
					className="table-scroll"
					style={{
						marginBottom: '22px',
					}}
				>
					{loadingCAT ? (
						<p
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							Cargado información...
						</p>
					) : !CATDatos ? (
						<p
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							No información para mostrar
						</p>
					) : (
						<table
							className="cat-table"
							style={{
								marginBottom: '0px',
							}}
						>
							<thead>
								<tr>
									<th>Nombre del Producto</th>
									<th>Tasa Promedio</th>
									<th>CAT Promedio</th>
									<th>Fecha Cálculo CAT</th>
									<th>
										Tasa de interés ordinaria anual fija
										desde
									</th>
									<th>
										Tasa de interés ordinaria anual fija
										hasta
									</th>
									<th>
										Tasa de interés moratoria anual fija
									</th>
									<th>Comisión apertura de crédito</th>
									<th>Comisión por pago tardío</th>
									<th>Comisión por disposición</th>
									<th>
										Comisión por pago a través de
										comisionistas
									</th>
								</tr>
							</thead>
							<tbody>
								{CATDatos.map((dato, index) => {
									return (
										<tr key={dato.id_CAT}>
											<td>{dato.nombreProducto}</td>
											<td>
												<b>{dato.tasaPromedio}</b>
											</td>
											<td>
												<b>{dato.catPromedio}</b>
											</td>
											<td>{dato.fechaCalculoCAT}</td>
											<td>
												<b>
													{
														dato.tasaInteresOrdinariaAnualFijaDesde
													}
												</b>
											</td>
											<td>
												<b>
													{
														dato.tasaInteresOrdinariaAnualFijaHasta
													}
												</b>
											</td>
											<td>
												<b>
													{
														dato.tasaInteresMoratoriaAnualFija
													}
												</b>
											</td>
											<td>{dato.comisionApertura}</td>
											<td>{dato.comisionPagoTardio}</td>
											<td>{dato.comisionDisposicion}</td>
											<td>
												{dato.comisionPagoComisionistas}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
				<p className="simple-text">
					Vigencia del CAT: 6 meses a partir de su fecha de cálculo
					<br />
					Leyendas sobre riesgos:
					<br />
					“Incumplir tus obligaciones te puede generar comisiones e
					intereses moratorios”
					<br />
					“Contratar créditos que excedan tu capacidad de pago afecta
					tu historial crediticio”
					<br />
					“El avalista, obligado solidario o coacreditado responderá
					como obligado principal por el total del pago frente a la
					Institución Financiera”
					<br />
					“Las operaciones realizadas a través de los comisionistas
					bancarios podrán generar una comisión, consulte antes de
					realizar su operación”
				</p>
			</div>

			{/* COSTOS DE SEGUROS */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="row title-1">
					<img src={isotipo} />
					Costos de Seguros
				</h2>
				<p className="simple-text">
					Nuestros productos de crédito requieren la contratación de
					los siguientes seguros (se marca con una x el seguro
					aplicable a cada crédito). SE LE INFORMA QUE EL CLIENTE
					PUEDE CONTRATAR LOS SEGUROS APLICABLES A SU CRÉDITO, A
					TRAVÉS DE UN TERCERO INDEPENDIENTE.
				</p>
				<div className="table-scroll">
					<table
						className="simple-table mb-10"
						border="1"
						style={{ marginTop: '70px' }}
					>
						<thead>
							<tr>
								<th>Producto</th>
								<th style={{ width: '206px' }}>
									<button
										onClick={() => {
											setOpenModalSegurosVida(true);
										}}
										className="over-button-table"
									>
										Ver características (A)
									</button>{' '}
									Seguro de Vida
								</th>
								<th style={{ width: '206px' }}>
									<button
										onClick={() => {
											setOpenModalSeguroRobo(true);
										}}
										className="over-button-table"
									>
										Ver características (B)
									</button>{' '}
									Seguro de Robo <br />
									con violencia
								</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td></td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="wht">Grupal</td>
								<td className="wht">$205.00</td>
								<td className="wht">$94.00</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
							<tr>
								<td className="wht">
									Microcrédito individual montos menores*
								</td>
								<td className="wht">$1.4036 x/c $1000</td>
								<td className="wht">N/A</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
							<tr>
								<td className="wht">
									Microcrédito individual montos mayores*
								</td>
								<td className="wht">$1.4036 x/c $1000</td>
								<td className="wht">N/A</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
						</tbody>
					</table>
				</div>
				<h3
					className="row"
					style={{
						fontSize: '26px',
						fontWeight: '600',
						justifyContent: 'center',
						marginBottom: '22px',
					}}
				>
					<span
						style={{
							display: 'block',
							width: '50px',
							height: '50px',
							backgroundColor: '#449b51',
							borderRadius: '100%',
						}}
					></span>{' '}
					Requisito
				</h3>
				<p className="simple-text">
					*DATOS VALIDOS PARA PRODUCTOS CONTRATADOS A PARTIR DEL 20 DE
					JUNIO DE 2023.
				</p>
			</div>

			{/* OPERACIONES PASIVAS */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="row title-1">
					<img src={isotipo} />
					Operaciones Pasivas
				</h2>

				<h3 className="subtitulo-1">
					<b>PAGARÉ </b>
					Forjadores
					<small>®</small>
				</h3>
				<p className="simple-text center w-2-3">
					Pagaré forjadores es una inversión que te permite ganar
					atractivos rendimientos en función al plazo y monto que
					elijas, al vencimiento tú decides si el capital y los
					intereses los reinviertes o se depositan a la cuenta
					bancaria que nos hayas proporcionado.
				</p>

				<h4 className="subtitulo-2">RENDIMIENTO FORJADORES</h4>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 28, 56, 91, 182, 280 y hasta 360 días
				</p>

				<div className="table-container">
					<div className="table-scroll">
						{loadingInversiones ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								Cargando datos...
							</p>
						) : inversionesGAT.length === 0 ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								No hay datos apara mostrar{' '}
							</p>
						) : (
							pagareForjadores && (
								<table
									className="simple-table mb-10"
									border="1"
								>
									<thead>
										<tr>
											<th colSpan="2">
												Monto minimo de inversión
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{pagareForjadores.montoMinimo}
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{pagareForjadores.montoMaximo}
											</th>
										</tr>
										<tr>
											<td colSpan="2"></td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
										</tr>
									</thead>
									<tbody>
										{pagareForjadores?.datosInversion
											?.length > 0 &&
											pagareForjadores.datosInversion.map(
												(dato) => {
													return (
														<tr className="transition-all hover:bg-gray-100">
															<td colSpan="2">
																{dato.plazo}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMin +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMin +
																	'%'}
															</td>
															<td>
																{dato.gatRealMin +
																	'%'}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMax +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMax +
																	'%'}
															</td>
															<td>
																{dato.gatRealMax +
																	'%'}
															</td>
														</tr>
													);
												},
											)}
									</tbody>
								</table>
							)
						)}
					</div>
					<p
						className="simple-text center"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								pagareForjadores?.pie ?? '',
							),
						}}
					></p>
				</div>
			</div>

			{/* GAT Y VIGENCIA PAGARE FORJADORES */}
			<div
				style={{ marginBottom: '76px' }}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(
						pagareForjadores?.descripcion ?? '',
					),
				}}
			></div>

			{/* FORJADORES CEDE */}
			<div style={{ marginBottom: '76px' }}>
				<h3 className="subtitulo-1">
					Forjadores{' '}
					<b>
						CEDE<small>®</small>
					</b>
				</h3>

				<p className="simple-text center w-2-3">
					Forjadores Cede es una inversión flexible que te permite
					disponer de los intereses generados por tu dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que nos hayas proporcionado.
				</p>

				<h4 className="subtitulo-2">RENDIMIENTO FORJADORES</h4>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 60, 90, 180, 270 y hasta 360 días
				</p>

				<div className="table-container">
					<div className="table-scroll">
						{loadingInversiones ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								Cargando datos...
							</p>
						) : inversionesGAT.length === 0 ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								No hay datos apara mostrar{' '}
							</p>
						) : (
							forjadoresCede && (
								<table
									className="simple-table mb-10"
									border="1"
								>
									<thead>
										<tr>
											<th colSpan="2">
												Monto minimo de inversión
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{forjadoresCede.montoMinimo}
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{forjadoresCede.montoMaximo}
											</th>
										</tr>
										<tr>
											<td colSpan="2"></td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
										</tr>
									</thead>
									<tbody>
										{forjadoresCede?.datosInversion
											?.length > 0 &&
											forjadoresCede.datosInversion.map(
												(dato) => {
													return (
														<tr className="transition-all hover:bg-gray-100">
															<td colSpan="2">
																{dato.plazo}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMin +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMin +
																	'%'}
															</td>
															<td>
																{dato.gatRealMin +
																	'%'}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMax +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMax +
																	'%'}
															</td>
															<td>
																{dato.gatRealMax +
																	'%'}
															</td>
														</tr>
													);
												},
											)}
									</tbody>
								</table>
							)
						)}
					</div>
					<p
						className="simple-text center"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								forjadoresCede?.pie ?? '',
							),
						}}
					></p>
				</div>
			</div>

			{/* GAT Y VIGENCIA FORJADORES CEDE */}
			<div
				style={{ marginBottom: '76px' }}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(
						forjadoresCede?.descripcion ?? '',
					),
				}}
			></div>

			<img
				style={{ width: '100%', margin: 'auto', display: 'block' }}
				decoding="async"
				src={inversion_lineas_verde}
				data-ll-status="loaded"
			/>

			{/* REQUISITOS */}
			<div style={{ marginBottom: '76px' }}>
				<div className="p-big">
					<h3 className="subtitulo-3">REQUISITOS:</h3>
					<p
						className="simple-text green-dots"
						style={{ marginBottom: '50px' }}
					>
						<b>•</b> Ser mayor de edad.
						<br />
						<b>•</b> Contar con identificación oficial vigente y
						comprobante de domicilio (no mayor a tres meses)
						<br />
						<b>•</b> Suscribir el contrato de adhesión
						correspondiente y hacer la transferencia a la cuenta que
						Banco Forjadores te proporcione.
					</p>

					<h3 className="subtitulo-3">CONCEPTOS DEL PRODUCTO:</h3>
					<p className="simple-text green-dots">
						<b>Banco Forjadores.-</b> Banco Forjadores S.A.,
						Institución de Banca Múltiple, que para su constitución
						como tal, requirió autorización de la Secretaria de
						Hacienda y Crédito Público, con opinión del Banco de
						México y su supervisión está regulada por la Comisión
						Nacional Bancaria y de Valores
						<br />
						<br />
						<b>Pagaré Forjadores.-</b> Inversión que permite ganar
						atractivos rendimientos en función al plazo y monto que
						se elija, al vencimiento que se decida si el capital y
						los intereses se reinvierten o se depositan a la cuenta
						bancaria que se haya proporcionado.
						<br />
						<br />
						<b>Forjadores CEDE.-</b> Inversión flexible que permite
						disponer de los intereses generados por el dinero mes a
						mes. Al término del plazo pactado el capital se deposita
						a la cuenta bancaria que se haya proporcionado.
						<br />
						<br />
						<b>Comisión.-</b> Para este producto no se aplica
						comisión alguna
						<br />
						<br />
						<b>UDI.-</b> Es un valor de referencia de valor real
						constante que el Banco de México publica en el Diario
						Oficial de la Federación el valor, en moneda nacional,
						de la unidad de inversión, para cada día del mes
						<br />
						<br />
						<b>GAT.-</b> La Ganancia Anual Total Neta expresada en
						términos porcentuales anuales, para fines informativos y
						de comparación, incorpora los intereses nominales
						capitalizables que generen las operaciones pasivas a
						plazo, retirables en días preestablecidos y de ahorro,
						que celebren las instituciones de crédito y las
						entidades de ahorro y crédito popular con sus clientes,
						menos todos los costos relacionados con la operación,
						incluidos los de apertura, de conformidad con las
						disposiciones que al efecto emita el Banco de México
						<br />
						<br />
						<b>Tasa de Interés.-</b> Es la tasa de rendimiento,
						expresada en términos anuales simples, la cual es fija
						durante el plazo de la inversión antes de impuestos
						<br />
						<br />
						<b>IPAB.-</b> Instituto para la Protección al Ahorro
						Bancario
					</p>
				</div>
			</div>

			<img
				style={{ width: '100%', marginBottom: '50px' }}
				decoding="async"
				src={inversion_lineas_verde}
				data-ll-status="loaded"
			/>

			{loadingVigenias ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
						marginBottom: '50px',
					}}
				>
					Cargando infromación...
				</p>
			) : vigencias.length === 0 ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
						marginBottom: '50px',
					}}
				>
					No hay información para mostrar
				</p>
			) : (
				<div style={{ marginBottom: '76px' }}>
					<div className="row ipab">
						<img
							style={{
								height: '130px',
								marginLeft: 'auto',
							}}
							decoding="async"
							src={inversion_logoIPAB}
							data-ll-status="loaded"
						/>
						<p
							className="simple-text"
							style={{ margin: '0' }}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(
									vigencias?.parrafo1 ?? '',
								),
							}}
						></p>
					</div>
					<p
						className="simple-text center green-dots"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								vigencias?.parrafo2 ?? '',
							),
						}}
					></p>
				</div>
			)}

			{/* MODAL SEGURO DE VIDA */}
			<Modal
				open={openModalSegurosVida}
				setOpen={setOpenModalSegurosVida}
			>
				<h2
					className="subtitulo-3"
					style={{ color: '#449b51', textAlign: 'center' }}
				>
					Seguro Protección de Deuda / Vida / Desgravamen
				</h2>
				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Grupal
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Servicio y/o programa de asistencia</td>
							<td>Seguro de vida deudores emprendedores</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo del crédito que
								los clientes mantengan con Banco Forjadores
								siempre que exista el pago de la prima
								correspondiente al período en que esté vigente
								el crédito
							</td>
						</tr>
						<tr>
							<td>CoberturaFallecimiento </td>
							<td>
								Fallecimiento del titular, Gastos funerarios
								para el titular y familiares directos
							</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								Saldo insoluto del crédito Saldo insoluto y con
								máxima de $60,000.00 MN por fallecimiento del
								titular
								<br />
								Mod 1: Gastos funerarios con suma asegurada de
								$12,000.00 MN para el Titular y $10,000.00 MN
								para familiares directos. Un evento
								cuatrimestral
							</td>
						</tr>
						<tr>
							<td>
								Suma asegurada máxima por crédito y en el
								agregado sin examen médico
							</td>
							<td>
								No se requiere examen médico hasta el límite
								máximo indicado en el apartado anterior.
							</td>
						</tr>
						<tr>
							<td>Límites de edad</td>
							<td>
								Titulares 18 a 70 años, se aceptan máximo 99
								años hasta el 5% de la base <br />
								Cónyuge de 18 a 70 años, se aceptan máximo 79
								años hasta el 5% de la base <br />
								Hijos que dependan económicamente del titular de
								1 mes hasta 25 años
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Ampara pre existencia
								<br />
								No aplican deducibles ni períodos de espera o
								carencia.
								<br />
								Atención preferencial
							</td>
						</tr>
					</tbody>
				</table>

				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Microcrédito individual montos menores, Microcrédito
					individual montos mayores
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Programa</td>
							<td>Seguro de vida deudores PYMES</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								gual a la duración del ciclo del crédito que los
								clientes mantengan con Banco Forjadores siempre
								que exista el pago de la prima correspondiente
								al período en que esté vigente el crédito
							</td>
						</tr>
						<tr>
							<td>Cobertura</td>
							<td>Fallecimiento del Titular Obligatorio.</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								Saldo insoluto del crédito
								<br />
								Saldo insoluto con máximo de $80,000.00 MN para
								PYMES
								<br />
								El beneficiario será Banco Forjadores
							</td>
						</tr>
						<tr>
							<td>
								Suma asegurada máxima por crédito y en el
								agregado sin examen médico
							</td>
							<td>
								No se requiere examen médico hasta el límite
								máximo indicado en el apartado anterior.
								<br />
								En caso que algún acreditado exceda la suma
								asegurada indicada, Banco Forjadores deberá
								reportar caso por caso para que sea evaluado por
								la ASEGURADORA y el titular atienda los
								requerimientos de valoración necesarios
								(examenes médicos, pruebas de laboratorio,
								informes médicos, entre otros) y en su caso
								pagar la extra prima correspondiente
							</td>
						</tr>
						<tr>
							<td>Límites de Edad</td>
							<td>
								Titulares de 18 a 75 años con cancelación
								automática a los 76 años
							</td>
						</tr>
						<tr>
							<td>Otras Condiciones</td>
							<td>
								Ampara pre existencia
								<br />
								No aplican deducibles ni períodos de espera o
								carencia.
								<br />
								Atención preferencial.
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>

			{/* MODAL SEGURO DE ROBO */}
			<Modal open={openModalSeguroRobo} setOpen={setOpenModalSeguroRobo}>
				<h2
					className="subtitulo-3"
					style={{ color: '#449b51', textAlign: 'center' }}
				>
					Seguro de Robo con violencia
				</h2>
				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Crédito Grupal
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Programa</td>
							<td>Seguro de Robo deudores emprendedores</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo del crédito que
								los clientes mantengan con Banco Forjadores
								siempre que exista el pago de la prima
								correspondiente al período en que esté vigente
								el crédito
							</td>
						</tr>
						<tr>
							<td>Cobertura</td>
							<td>
								La suma asegurada corresponde al 10% del saldo
								insoluto del monto inicial a pagar de manera
								grupal
							</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								1 evento durante la temporalidad del crédito
							</td>
						</tr>
						<tr>
							<td>Suma asegurada máxima por crédito</td>
							<td>
								La suma asegurada corresponde al 10% del total a
								pagar por el grupo con un monto máximo de hasta
								$60,000.00 MN
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Pago de reembolso de 10 a 15 días, con
								documentación completa presentando original o
								copia certificada del acta ante el Ministerio
								Público que ampare la o las denuncias ante las
								autoridades correspondiente acreditando el robo
								con violencia, así como la propiedad de lo
								declarado ante la autoridad competente.
								<br />
								Asistencias:
								<br />– Legal telefónica ilimitada
							</td>
						</tr>
					</tbody>
				</table>

				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Microcrédito individual montos menores, Microcrédito
					individual montos mayores, Crédito Industrial PF y Crédito
					Industrial PM
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Contratante</td>
							<td>
								Banco Forjadores S, .A. Institución de Banca
								Múltiple y/o sus acreditados.
							</td>
						</tr>
						<tr>
							<td>Programa</td>
							<td>Seguro de Robo Deudores PYMES</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo de crédito que los
								clientes mantengan con Banco Forjadores siempre
								que exista el pago de la prima correspondiente
								al periodo en que este vigente el crédito.
							</td>
						</tr>
						<tr>
							<td>Coberturas</td>
							<td>
								La Suma Asegurada corresponde al 10% del saldo
								insoluto
							</td>
						</tr>
						<tr>
							<td>Regla de Suma asegurada</td>
							<td>
								1 evento durante la temporalidad del crédito (de
								1 mes a 12 meses)
								<br />2 eventos durante la temporalidad del
								crédito (de 13 meses a 24 meses)
							</td>
						</tr>
						<tr>
							<td>
								Suma Asegurada Máxima por crédito y en el
								agregado sin examen medico
							</td>
							<td>
								La Suma Asegurada corresponde al 10% del saldo
								insoluto hasta $30,000.00
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Pago de reembolso de 10 a 15 días con
								documentación completa, presentando original o
								copia certificada del acta ante el Ministerio
								Publico que ampare la o las denuncias ante las
								autoridades correspondientes, acreditando el
								robo con violencia, así como la propiedad de lo
								declarado ante la autoridad competente.
								<br />
								Asistencias:
								<br />
								Legal telefónica ilimitada
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>
		</div>
	);
}

export default CostosComisiones;
