import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import carpeta from '../../../assets/images/Iconos/informacion_carpeta_financiera.png';

function AsambleasTenedores() {
	return (
		<div className="post-inner">
			<div className="entry-content">
				<div className="information-intro">
					<img
						src={encabezado}
						decoding="async"
						className="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ASAMBLEAS DE TENEDORES</small>
					</h1>
				</div>
				<div className="estatutos-folders folders">
					<Link
						to="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-tenedores/asambleas-de-tenedores-2022/"
					>
						<img src={carpeta} />
						Asambleas de Tenedores 2022
					</Link>
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default AsambleasTenedores;
