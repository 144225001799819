import '../../style/infohead.css';
/* import DespachoCobranzaPDF from '../../assets/documents/Despachos-de-Cobranza-septiembre-2024.pdf'; */
import UseDespachosCobranzaStore from '../../stores/UseDespachosCobranzaStore';
import { useEffect } from 'react';

function DespachoCobranza() {
	const { archivo, loadingArchivo, getDespachosCobranza } =
		UseDespachosCobranzaStore();

	useEffect(() => {
		getDespachosCobranza();
	}, []);

	return (
		<div className="entry-content">
			<div className="officesContainer">
				<h1>
					Consulta{' '}
					<a
						href={archivo?.documentoRuta}
						target="_blank"
						rel="noopener"
					>
						aquí
					</a>{' '}
					los despachos de cobranza
				</h1>

				<p
					style={{
						marginBottom: '20px',
					}}
				>
					A continuación se señala la información para que pueda
					identificar y localizar a los despachos de cobranza
					designados por Banco Forjadores para realizar gestiones de
					cobranza.
				</p>

				{loadingArchivo ? (
					<p
						style={{
							width: '100%',
							textAlign: 'center',
						}}
					>
						Cargando archivo...
					</p>
				) : !archivo ? (
					<a
						className="forjadores-btn"
						rel="noopener"
						style={{
							opacity: 0.5,
						}}
					>
						CONSULTAR DATOS DE DESPACHO
					</a>
				) : (
					<a
						className="forjadores-btn"
						href={archivo?.documentoRuta}
						target="_blank"
						rel="noopener"
					>
						CONSULTAR DATOS DE DESPACHO
					</a>
				)}
			</div>
		</div>
	);
}

export default DespachoCobranza;
