import { Link } from 'react-router-dom';
import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import '../../../../style/quienessomos.css';
import { useEffect } from 'react';
import UseRazonApalancamiento from '../../../../stores/UseRazonApalancamiento';

// import Rev_dic_2016 from '../../../../assets/documents/InformacionFinanciera/RevInfoRelativa/Revelación-de-información-relativa-a-la-razón-de-Apalancamiento-Diciembre-2016.pdf';

function RevInfo() {
	const { documentos, loadingDocumentos, getDocumentos } =
		UseRazonApalancamiento();

	useEffect(() => {
		getDocumentos();
	}, []);
	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>
							REVELACIÓN DE INFORMACIÓN RELATIVA A LA RAZÓN DE
							APALANCAMIENTO
						</small>
					</h1>
				</div>
				<div class="information-files">
					{loadingDocumentos ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							Cargando documentos...
						</p>
					) : documentos.length === 0 ? (
						<p
							style={{
								textAlign: 'center',
								padding: '1em',
							}}
						>
							No hay documentos para mostrar
						</p>
					) : (
						documentos.map((docuemnto) => {
							return (
								<Link
									key={docuemnto.id}
									className="file"
									target="_blank"
									rel="noopener noreferrer"
									to={docuemnto.documentoRuta}
								>
									<img src={icono_pdf} />
									{docuemnto.nombre}
								</Link>
							);
						})
					)}
					{/* <a href={Rev_dic_2016} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />  Revelación de información relativa a la razón de Apalancamiento Diciembre 2016</a> */}
				</div>
			</div>
		</div>
	);
}

export default RevInfo;
