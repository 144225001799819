import { create } from 'zustand';

const UseProductosStore = create((set) => ({
	productos: [],
	loadingProductos: false,
	getProductos: async () => {
		try {
			set({ loadingProductos: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					'/api/Informacion/Productos',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: ``,
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ productos: data });
			set({ loadingProductos: false });

			console.log(data);
		} catch (error) {
			console.error('Error al obtener los productos');
			set({ loadingProductos: false });
		}
	},
}));

export default UseProductosStore;
