import encabezado from '../../../../assets/images/Encabezados/encabezado_informacion_financiera.png';
import icono_pdf from '../../../../assets/images/Iconos/icono_pdf.png';

import Obliga1 from '../../../../assets/documents/InformacionFinanciera/ObligacionesSubordinadas/EPT_Banco.Forjadores_Obligaciones-subordinadas_BFORJA-1-20.pdf';
import Obliga2 from '../../../../assets/documents/InformacionFinanciera/ObligacionesSubordinadas/EPT_Banco.Forjadores_Obligaciones-subordinadas_BFORJA1-21.pdf';
import Obliga3 from '../../../../assets/documents/InformacionFinanciera/ObligacionesSubordinadas/EPT_Banco.Forjadores_Obligaciones-subordinadas_BFORJA.1.22.pdf';
import Obliga4 from '../../../../assets/documents/InformacionFinanciera/ObligacionesSubordinadas/EPT_Banco.Forjadores_Obligaciones-subordinadas_BFORJA.1.22.pdf';


import '../../../../style/quienessomos.css';

function ObligSubor() {
    return (
        <div class="post-inner">
            <div class="entry-content">
                <div class="information-intro">
                    <img src={encabezado} decoding="async" class="lazyloaded" data-ll-status="loaded" />
                    <h1><small>OBLIGACIONES SUBORDINADAS</small></h1>
                </div>
                <div class="information-files">
                    <a href={Obliga1} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" /> EPT_Banco.Forjadores_Obligaciones subordinadas_BFORJA 1-20</a>
                    <a href={Obliga4} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />  EPT_Banco.Forjadores_Obligaciones subordinadas_BFORJA 2-20</a>
                    <a href={Obliga2} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />  EPT_Banco.Forjadores_Obligaciones subordinadas_BFORJA 1-21</a>
                    <a href={Obliga3} target="_blank" rel="noopener"><img src={icono_pdf} decoding="async" class="lazyloaded" data-ll-status="loaded" />  EPT_Banco.Forjadores_Obligaciones subordinadas_BFORJA 1-22</a>
                </div>        
                <p></p>
            </div>
        </div>
    );
}

export default ObligSubor;