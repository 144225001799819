import { useNavigate, Link } from 'react-router-dom';
import encabezado from '../../assets/images/Encabezados/encabezado-relacion-con-inversionistas.png';
import carpetagris from '../../assets/images/Iconos/informacion_carpeta_financiera.png';
import carpetaverde from '../../assets/images/Iconos/informacion_carpeta_corporativa.png';
import informacion_linea1 from '../../assets/images/informacion_linea_1.png';
import informacion_linea2 from '../../assets/images/informacion_linea_2.png';

import '../../style/quienessomos.css';

function RelacionInversionistas() {
	return (
		<div className="RelacionInversionistas">
			<section className="encabezado">
				<img src={encabezado} />
			</section>
			<div className="informationFinancial">
				<h2>INFORMACIÓN FINANCIERA</h2>
				<Link to="/quienes-somos/informacion-de-interes/informacion-trimestral/">
					<img src={carpetagris} /> Información Trimestral
				</Link>

				<Link to="/quienes-somos/informacion-de-interes/estados-financieros-dictaminados/">
					<img src={carpetagris} /> Estados Financieros Dictaminados
				</Link>

				<Link to="/quienes-somos/informacion-de-interes/reporte-de-administracion-anual/">
					<img src={carpetagris} /> Reporte de Administración Anual
				</Link>

				<Link to="/quienes-somos/informacion-de-interes/revelacion-de-informacion-relativa-a-la-razon-de-apalancamiento/">
					<img src={carpetagris} /> Revelación de información relativa
					a la razón de Apalancamiento
				</Link>

				<Link to="/quienes-somos/informacion-de-interes/calificacion-de-riesgo/">
					<img src={carpetagris} /> Calificación de Riesgo
				</Link>

				<Link to="/quienes-somos/informacion-de-interes/obligaciones-subordinadas/">
					<img src={carpetagris} /> Obligaciones Subordinadas
				</Link>

				<img src={informacion_linea1} />
			</div>
			<div className="informationCorporate">
				<h2>INFORMACIÓN CORPORATIVA</h2>
				<Link to="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/">
					<img src={carpetaverde} />
					Estatutos Sociales
				</Link>

				<Link to="/quienes-somos/relacion-con-inversionistas/asambleas/">
					<img src={carpetaverde} /> Asambleas
				</Link>

				<Link to="/quienes-somos/relacion-con-inversionistas/informe-de-remuneraciones/">
					<img src={carpetaverde} /> Informe de Remuneraciones
				</Link>

				<img src={informacion_linea2} />
			</div>

			<div className="informationContact">
				<p>
					Para mayor información:
					<br />
					Tel: <a href="5556112450">55 5611 2450</a> / Ext: 1440 y
					1450
				</p>
			</div>
		</div>
	);
}

export default RelacionInversionistas;
