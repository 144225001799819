import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import carpetaverde from '../../../assets/images/Iconos/informacion_carpeta_corporativa.png';

function Asambleas() {
	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>ASAMBLEAS</small>
					</h1>
				</div>
				<div className="estatutos-folders folders">
					<Link to="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-accionistas/">
						<img src={carpetaverde} />
						Asambleas de Accionistas
					</Link>

					<Link to="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-tenedores/">
						<img src={carpetaverde} />
						Asambleas de Tenedores
					</Link>
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default Asambleas;
