import { create } from 'zustand';

const UseAvisoPrivacidadStore = create((set) => ({
	avisoPrivacidad: {},
	loadingAvisoPrivacidad: false,
	getAvisoPrivacidad: async () => {
		try {
			set({ loadingAvisoPrivacidad: true });

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					'/api/AvisoPrivacidad/ObtenerAviso',
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					redirect: 'follow',
				},
			);

			if (response.status === 401) {
				console.log('ocurrio un error');
			}

			const data = await response.json();
			set({ avisoPrivacidad: data });
			set({ loadingAvisoPrivacidad: false });
		} catch (error) {
			console.error('Error al obtener los CAT');
			set({ loadingAvisoPrivacidad: false });
		}
	},
}));

export default UseAvisoPrivacidadStore;
