import encabezado_forjadores_cede from '../../assets/images/Encabezados/encabezado_forjadores_cede.png';
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';
import individual_lineas_contrata from '../../assets/images/Individual_lineas_contrata.png';
import inversion_lineas3 from '../../assets/images/Inversion_lineas3.png';
import inversion_lineas_verde from '../../assets/images/Inversion_lineas_verde.png';
import inversion_logoIPAB from '../../assets/images/Inversion_logoIPAB.png';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UseCorreosStore from '../../stores/UseCorreosStore';
import UseContactoStore from '../../stores/UseContactoStore';
import UseGATStore from '../../stores/UseGATStore';
import DOMPurify from 'dompurify';

function ForjadoresCede() {
	const [forjadoresCede, setForjadoresCede] = useState(null);
	const [emailNombre, setEmailNombre] = useState('');
	const [emailApellido, setEmailApellido] = useState('');
	const [emailEmail, setEmailEmail] = useState('');
	const [emailTelefono, setEmailTelefono] = useState('');
	const [emailPais, setEmailPais] = useState('us +1');
	const [emailEstado, setEmailEstado] = useState('Aguascalientes');
	const [emailCiudad, setEmailCiudad] = useState('');
	const [emailMensaje, setEmailMensaje] = useState('');
	const [emailAceptoPoliticas, setEmailAceptoPoliticas] = useState(0);
	const [emailEnviado, setEmailEnviado] = useState(false);
	const [emailEnviando, setEmailEnviando] = useState(false);

	const { enviarCorreo } = UseCorreosStore();
	const { contacto, getContacto } = UseContactoStore();
	const {
		inversionesGAT,
		vigencias,
		loadingVigenias,
		loadingInversiones,
		getInversionesGAT,
		getVigencias,
	} = UseGATStore();

	useEffect(() => {
		getContacto();

		getInversionesGAT();
		getVigencias();
	}, []);

	useEffect(() => {
		const newForjadoresCede = inversionesGAT.find((inversion) => {
			return inversion.nombre === 'Forjadores cede';
		});

		setForjadoresCede(newForjadoresCede);
	}, [inversionesGAT]);

	async function handleEmailSubmit(e) {
		setEmailEnviando(true);
		e.preventDefault();

		if (!emailAceptoPoliticas) {
			alert('Debes aceptar las políticas de privacidad');
			setEmailEnviando(false);
			return;
		}
		const emailTemplate = `
        <body style="font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0;">
            <div style="width: 80%; margin: 20px auto; background-color: #fff; padding: 20px; border-radius: 5px; box-shadow: 0 2px 3px rgba(0,0,0,0.1);">
                <h2 style="color: #333;">Nuevo Mensaje de Contacto</h2>
                <p><span style="font-weight: bold;">Nombre:</span> ${emailNombre}</p>
                <p><span style="font-weight: bold;">Apellido:</span> ${emailApellido}</p>
                <p><span style="font-weight: bold;">Email:</span> ${emailEmail}</p>
                <p><span style="font-weight: bold;">Teléfono:</span> ${emailPais} ${emailTelefono}</p>
                <p><span style="font-weight: bold;">Estado:</span> ${emailEstado}</p>
                <p><span style="font-weight: bold;">Municipio:</span> ${emailCiudad}</p>
                <p><span style="font-weight: bold;">Mensaje:</span></p>
                <p style="margin-bottom: 10px;">${emailMensaje}</p>
            </div>
        </body>
        `;

		const correo = {
			Asunto: 'Nuevo mensaje de contacto en Crédito Grupal',
			Mensaje: emailTemplate,
		};

		const resultado = await enviarCorreo(correo);

		setEmailEnviado(resultado);
		setEmailEnviando(false);
	}
	return (
		<div className="forjadores-cede">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_forjadores_cede}
					alt="Encabezado forjadores cede"
				/>
			</section>

			<section
				style={{
					padding: '0 5%',
				}}
			>
				<h2 class="subtitulo-1">
					Forjadores <b>CEDE </b>
					<small>®</small>
				</h2>
				<p className="simple-text full-width">
					Forjadores Cede es una inversión flexible que te permite
					disponer de los intereses generados por tu dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que nos hayas proporcionado.
				</p>

				<h3 className="subtitulo-2">RENDIMIENTO FORJADORES</h3>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 60, 90, 180, 270 y hasta 360 días
				</p>

				<div
					className="table-container"
					style={{
						marginBottom: '76px',
					}}
				>
					<div className="table-scroll">
						{loadingInversiones ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								Cargando datos...
							</p>
						) : inversionesGAT.length === 0 ? (
							<p
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								No hay datos apara mostrar{' '}
							</p>
						) : (
							forjadoresCede && (
								<table
									className="simple-table mb-10"
									border="1"
								>
									<thead>
										<tr>
											<th colSpan="2">
												Monto minimo de inversión
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{forjadoresCede.montoMinimo}
											</th>
											<th
												className="text-2xl"
												colSpan="3"
											>
												{forjadoresCede.montoMaximo}
											</th>
										</tr>
										<tr>
											<td colSpan="2"></td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
											<td>
												<b className="font-bold">
													TASA FIJA ANUAL
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Nominal
												</b>
											</td>
											<td>
												<b className="font-bold">
													Gat
													<br /> Real
												</b>
											</td>
										</tr>
									</thead>
									<tbody>
										{forjadoresCede?.datosInversion
											?.length > 0 &&
											forjadoresCede.datosInversion.map(
												(dato) => {
													return (
														<tr className="transition-all hover:bg-gray-100">
															<td colSpan="2">
																{dato.plazo}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMin +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMin +
																	'%'}
															</td>
															<td>
																{dato.gatRealMin +
																	'%'}
															</td>
															<td className="font-bold">
																{dato.tasaFijaAnualMax +
																	'%'}
															</td>
															<td>
																{dato.gatNominalMax +
																	'%'}
															</td>
															<td>
																{dato.gatRealMax +
																	'%'}
															</td>
														</tr>
													);
												},
											)}
									</tbody>
								</table>
							)
						)}
					</div>
					<p
						className="simple-text center"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								forjadoresCede?.pie ?? '',
							),
						}}
					></p>
				</div>

				<div>
					<h2 className="title-2">GAT Y VIGENCIA</h2>
					<p className="simple-text">
						<b>Ganancia Anual Total Nominal (GAT Nominal):</b> La
						Ganancia Anual Total Nominal se encuentra expresada en
						términos porcentuales anuales. 11.13% (antes de
						impuestos), para inversiones a partir de $25,000 pesos
						mexicanos en plazos de 360 días, calculada al 02 de
						septiembre de 2024.
					</p>
					<p className="simple-text">
						<b>•</b> Información sujeta a cambios sin previo aviso.
					</p>
					<p className="simple-text">
						<b>Ganancia Anual Total Real (GAT Real):</b> La Ganancia
						Anual Total Real se encuentra expresada en términos
						porcentuales anuales. La GAT Real es el rendimiento que
						obtendrías después de descontar la inflación estimada
						7.10% (antes de impuestos), para inversiones a partir de
						$25,000 pesos mexicanos en plazos de 360 días, calculada
						al 02 de septiembre de 2024.
					</p>
					<p className="simple-text">
						<b>•</b> Vigencia indefinida, información sujeta a
						cambios sin previo aviso.
					</p>
					<p className="simple-text">
						<b>•</b> Este producto se oferta en todas las oficinas,
						consulta su ubicación en la parte superior de esta
						página.
					</p>
					<p className="simple-text">
						<b>•</b> Estos productos no tienen comisiones.
					</p>
					<p
						className="simple-text"
						style={{ fontSize: '14px', marginBottom: '50px' }}
					>
						Vigencia a partir del 02 de septiembre de 2024 al 30 de
						septiembre de 2024
					</p>
				</div>
			</section>

			<h2 class="banner">
				<b>INVIERTE </b> EN BANCO <br /> FORJADORES
			</h2>

			<section
				className="row contact-container"
				style={{
					padding: '5%',
				}}
			>
				<div className="info-container">
					<h2>
						¡Elige la opción que más se adecue a tus necesidades!
					</h2>

					<div>
						<p className="info-title">Llámanos al</p>
						<a
							className="highlighted"
							href={
								'tel:' +
								(contacto
									? contacto.telefonoFormateado
									: '800 830 0005')
							}
						>
							{contacto
								? contacto.telefonoFormateado
								: '800 830 0005'}
						</a>
					</div>

					<img
						style={{ margin: '-3.5em 0 0.6em' }}
						decoding="async"
						src={individual_lineas_contrata}
						data-ll-status="loaded"
					/>

					<div>
						<a
							className="highlighted"
							href={
								'https://api.whatsapp.com/send?phone=521' +
								(contacto ? contacto.whatsApp : '5580507824')
							}
							target="_blank"
							rel="noopener"
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_whats_contactanos}
								data-ll-status="loaded"
							/>
							{contacto
								? contacto.whatsAppFormateado
								: '55 8039 8668'}
						</a>
						<a
							className="highlighted"
							href={
								contacto
									? 'mailto:' + contacto.correo
									: 'mailto:atencionaclientes@forjadores.com.mx'
							}
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_correo_contactanos}
								data-ll-status="loaded"
							/>
							{contacto
								? contacto.correo
								: 'atencionaclientes@forjadores.com.mx'}
						</a>
						<p className="simple-text">
							Déjanos tus datos y empieza desde hoy tu proceso
						</p>
					</div>
				</div>
				<div className="form-container">
					<form onSubmit={handleEmailSubmit}>
						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="nombre">
									{' '}
									Nombre{' '}
									<span className="erf-required">*</span>{' '}
								</label>
								<input
									type="text"
									id="nombre"
									name="nombre"
									value={emailNombre}
									onChange={(e) =>
										setEmailNombre(e.target.value)
									}
									required
								/>
							</div>
							<div className="form-item">
								<label htmlFor="apellido">
									{' '}
									Apellido{' '}
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="apellido"
									name="apellido"
									value={emailApellido}
									onChange={(e) =>
										setEmailApellido(e.target.value)
									}
									required
								/>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="email">
									{' '}
									Email{' '}
									<span className="erf-required">*</span>{' '}
								</label>
								<input
									type="text"
									id="email"
									name="email"
									value={emailEmail}
									onChange={(e) =>
										setEmailEmail(e.target.value)
									}
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="telefono">
									{' '}
									Teléfono{' '}
									<span className="erf-required">*</span>{' '}
								</label>

								<div className="intl-tel-input allow-dropdown separate-dial-code iti-sdc-3">
									<div className="flag-container">
										<select
											className="country-list"
											id="country-listbox"
											aria-expanded="false"
											role="listbox"
											aria-activedescendant="iti-item-mx"
											onChange={(e) => {
												const selectedOption =
													e.target.options[
														e.target.selectedIndex
													];

												const dialCode =
													selectedOption.getAttribute(
														'data-dial-code',
													);
												const countryCode =
													selectedOption.getAttribute(
														'data-country-code',
													);
												const newEmailPais =
													countryCode +
													' +' +
													dialCode;

												setEmailPais(newEmailPais);
											}}
										>
											<option
												selected="true"
												className="country preferred"
												id="iti-item-us"
												role="option"
												data-dial-code="1"
												data-country-code="us"
											>
												<div className="flag-box">
													<div className="iti-flag us"></div>
												</div>
												<span className="country-name">
													United States
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>
											<option
												className="country preferred"
												id="iti-item-gb"
												role="option"
												data-dial-code="44"
												data-country-code="gb"
											>
												<div className="flag-box">
													<div className="iti-flag gb"></div>
												</div>
												<span className="country-name">
													United Kingdom
												</span>
												<span className="dial-code">
													+44
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-af"
												role="option"
												data-dial-code="93"
												data-country-code="af"
											>
												<div className="flag-box">
													<div className="iti-flag af"></div>
												</div>
												<span className="country-name">
													Afghanistan (‫افغانستان‬‎)
												</span>
												<span className="dial-code">
													+93
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-al"
												role="option"
												data-dial-code="355"
												data-country-code="al"
											>
												<div className="flag-box">
													<div className="iti-flag al"></div>
												</div>
												<span className="country-name">
													Albania (Shqipëri)
												</span>
												<span className="dial-code">
													+355
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-dz"
												role="option"
												data-dial-code="213"
												data-country-code="dz"
											>
												<div className="flag-box">
													<div className="iti-flag dz"></div>
												</div>
												<span className="country-name">
													Algeria (‫الجزائر‬‎)
												</span>
												<span className="dial-code">
													+213
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-as"
												role="option"
												data-dial-code="1684"
												data-country-code="as"
											>
												<div className="flag-box">
													<div className="iti-flag as"></div>
												</div>
												<span className="country-name">
													American Samoa
												</span>
												<span className="dial-code">
													+1684
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ad"
												role="option"
												data-dial-code="376"
												data-country-code="ad"
											>
												<div className="flag-box">
													<div className="iti-flag ad"></div>
												</div>
												<span className="country-name">
													Andorra
												</span>
												<span className="dial-code">
													+376
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ao"
												role="option"
												data-dial-code="244"
												data-country-code="ao"
											>
												<div className="flag-box">
													<div className="iti-flag ao"></div>
												</div>
												<span className="country-name">
													Angola
												</span>
												<span className="dial-code">
													+244
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ai"
												role="option"
												data-dial-code="1264"
												data-country-code="ai"
											>
												<div className="flag-box">
													<div className="iti-flag ai"></div>
												</div>
												<span className="country-name">
													Anguilla
												</span>
												<span className="dial-code">
													+1264
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ag"
												role="option"
												data-dial-code="1268"
												data-country-code="ag"
											>
												<div className="flag-box">
													<div className="iti-flag ag"></div>
												</div>
												<span className="country-name">
													Antigua and Barbuda
												</span>
												<span className="dial-code">
													+1268
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ar"
												role="option"
												data-dial-code="54"
												data-country-code="ar"
											>
												<div className="flag-box">
													<div className="iti-flag ar"></div>
												</div>
												<span className="country-name">
													Argentina
												</span>
												<span className="dial-code">
													+54
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-am"
												role="option"
												data-dial-code="374"
												data-country-code="am"
											>
												<div className="flag-box">
													<div className="iti-flag am"></div>
												</div>
												<span className="country-name">
													Armenia (Հայաստան)
												</span>
												<span className="dial-code">
													+374
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-aw"
												role="option"
												data-dial-code="297"
												data-country-code="aw"
											>
												<div className="flag-box">
													<div className="iti-flag aw"></div>
												</div>
												<span className="country-name">
													Aruba
												</span>
												<span className="dial-code">
													+297
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-au"
												role="option"
												data-dial-code="61"
												data-country-code="au"
											>
												<div className="flag-box">
													<div className="iti-flag au"></div>
												</div>
												<span className="country-name">
													Australia
												</span>
												<span className="dial-code">
													+61
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-at"
												role="option"
												data-dial-code="43"
												data-country-code="at"
											>
												<div className="flag-box">
													<div className="iti-flag at"></div>
												</div>
												<span className="country-name">
													Austria (Österreich)
												</span>
												<span className="dial-code">
													+43
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-az"
												role="option"
												data-dial-code="994"
												data-country-code="az"
											>
												<div className="flag-box">
													<div className="iti-flag az"></div>
												</div>
												<span className="country-name">
													Azerbaijan (Azərbaycan)
												</span>
												<span className="dial-code">
													+994
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bs"
												role="option"
												data-dial-code="1242"
												data-country-code="bs"
											>
												<div className="flag-box">
													<div className="iti-flag bs"></div>
												</div>
												<span className="country-name">
													Bahamas
												</span>
												<span className="dial-code">
													+1242
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bh"
												role="option"
												data-dial-code="973"
												data-country-code="bh"
											>
												<div className="flag-box">
													<div className="iti-flag bh"></div>
												</div>
												<span className="country-name">
													Bahrain (‫البحرين‬‎)
												</span>
												<span className="dial-code">
													+973
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bd"
												role="option"
												data-dial-code="880"
												data-country-code="bd"
											>
												<div className="flag-box">
													<div className="iti-flag bd"></div>
												</div>
												<span className="country-name">
													Bangladesh (বাংলাদেশ)
												</span>
												<span className="dial-code">
													+880
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bb"
												role="option"
												data-dial-code="1246"
												data-country-code="bb"
											>
												<div className="flag-box">
													<div className="iti-flag bb"></div>
												</div>
												<span className="country-name">
													Barbados
												</span>
												<span className="dial-code">
													+1246
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-by"
												role="option"
												data-dial-code="375"
												data-country-code="by"
											>
												<div className="flag-box">
													<div className="iti-flag by"></div>
												</div>
												<span className="country-name">
													Belarus (Беларусь)
												</span>
												<span className="dial-code">
													+375
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-be"
												role="option"
												data-dial-code="32"
												data-country-code="be"
											>
												<div className="flag-box">
													<div className="iti-flag be"></div>
												</div>
												<span className="country-name">
													Belgium (België)
												</span>
												<span className="dial-code">
													+32
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bz"
												role="option"
												data-dial-code="501"
												data-country-code="bz"
											>
												<div className="flag-box">
													<div className="iti-flag bz"></div>
												</div>
												<span className="country-name">
													Belize
												</span>
												<span className="dial-code">
													+501
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bj"
												role="option"
												data-dial-code="229"
												data-country-code="bj"
											>
												<div className="flag-box">
													<div className="iti-flag bj"></div>
												</div>
												<span className="country-name">
													Benin (Bénin)
												</span>
												<span className="dial-code">
													+229
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bm"
												role="option"
												data-dial-code="1441"
												data-country-code="bm"
											>
												<div className="flag-box">
													<div className="iti-flag bm"></div>
												</div>
												<span className="country-name">
													Bermuda
												</span>
												<span className="dial-code">
													+1441
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bt"
												role="option"
												data-dial-code="975"
												data-country-code="bt"
											>
												<div className="flag-box">
													<div className="iti-flag bt"></div>
												</div>
												<span className="country-name">
													Bhutan (འབྲུག)
												</span>
												<span className="dial-code">
													+975
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bo"
												role="option"
												data-dial-code="591"
												data-country-code="bo"
											>
												<div className="flag-box">
													<div className="iti-flag bo"></div>
												</div>
												<span className="country-name">
													Bolivia
												</span>
												<span className="dial-code">
													+591
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ba"
												role="option"
												data-dial-code="387"
												data-country-code="ba"
											>
												<div className="flag-box">
													<div className="iti-flag ba"></div>
												</div>
												<span className="country-name">
													Bosnia and Herzegovina
													(Босна и Херцеговина)
												</span>
												<span className="dial-code">
													+387
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bw"
												role="option"
												data-dial-code="267"
												data-country-code="bw"
											>
												<div className="flag-box">
													<div className="iti-flag bw"></div>
												</div>
												<span className="country-name">
													Botswana
												</span>
												<span className="dial-code">
													+267
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-br"
												role="option"
												data-dial-code="55"
												data-country-code="br"
											>
												<div className="flag-box">
													<div className="iti-flag br"></div>
												</div>
												<span className="country-name">
													Brazil (Brasil)
												</span>
												<span className="dial-code">
													+55
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-io"
												role="option"
												data-dial-code="246"
												data-country-code="io"
											>
												<div className="flag-box">
													<div className="iti-flag io"></div>
												</div>
												<span className="country-name">
													British Indian Ocean
													Territory
												</span>
												<span className="dial-code">
													+246
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-vg"
												role="option"
												data-dial-code="1284"
												data-country-code="vg"
											>
												<div className="flag-box">
													<div className="iti-flag vg"></div>
												</div>
												<span className="country-name">
													British Virgin Islands
												</span>
												<span className="dial-code">
													+1284
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bn"
												role="option"
												data-dial-code="673"
												data-country-code="bn"
											>
												<div className="flag-box">
													<div className="iti-flag bn"></div>
												</div>
												<span className="country-name">
													Brunei
												</span>
												<span className="dial-code">
													+673
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bg"
												role="option"
												data-dial-code="359"
												data-country-code="bg"
											>
												<div className="flag-box">
													<div className="iti-flag bg"></div>
												</div>
												<span className="country-name">
													Bulgaria (България)
												</span>
												<span className="dial-code">
													+359
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bf"
												role="option"
												data-dial-code="226"
												data-country-code="bf"
											>
												<div className="flag-box">
													<div className="iti-flag bf"></div>
												</div>
												<span className="country-name">
													Burkina Faso
												</span>
												<span className="dial-code">
													+226
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bi"
												role="option"
												data-dial-code="257"
												data-country-code="bi"
											>
												<div className="flag-box">
													<div className="iti-flag bi"></div>
												</div>
												<span className="country-name">
													Burundi (Uburundi)
												</span>
												<span className="dial-code">
													+257
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kh"
												role="option"
												data-dial-code="855"
												data-country-code="kh"
											>
												<div className="flag-box">
													<div className="iti-flag kh"></div>
												</div>
												<span className="country-name">
													Cambodia (កម្ពុជា)
												</span>
												<span className="dial-code">
													+855
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cm"
												role="option"
												data-dial-code="237"
												data-country-code="cm"
											>
												<div className="flag-box">
													<div className="iti-flag cm"></div>
												</div>
												<span className="country-name">
													Cameroon (Cameroun)
												</span>
												<span className="dial-code">
													+237
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ca"
												role="option"
												data-dial-code="1"
												data-country-code="ca"
											>
												<div className="flag-box">
													<div className="iti-flag ca"></div>
												</div>
												<span className="country-name">
													Canada
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cv"
												role="option"
												data-dial-code="238"
												data-country-code="cv"
											>
												<div className="flag-box">
													<div className="iti-flag cv"></div>
												</div>
												<span className="country-name">
													Cape Verde (Kabu Verdi)
												</span>
												<span className="dial-code">
													+238
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bq"
												role="option"
												data-dial-code="599"
												data-country-code="bq"
											>
												<div className="flag-box">
													<div className="iti-flag bq"></div>
												</div>
												<span className="country-name">
													Caribbean Netherlands
												</span>
												<span className="dial-code">
													+599
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ky"
												role="option"
												data-dial-code="1345"
												data-country-code="ky"
											>
												<div className="flag-box">
													<div className="iti-flag ky"></div>
												</div>
												<span className="country-name">
													Cayman Islands
												</span>
												<span className="dial-code">
													+1345
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cf"
												role="option"
												data-dial-code="236"
												data-country-code="cf"
											>
												<div className="flag-box">
													<div className="iti-flag cf"></div>
												</div>
												<span className="country-name">
													Central African Republic
													(République centrafricaine)
												</span>
												<span className="dial-code">
													+236
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-td"
												role="option"
												data-dial-code="235"
												data-country-code="td"
											>
												<div className="flag-box">
													<div className="iti-flag td"></div>
												</div>
												<span className="country-name">
													Chad (Tchad)
												</span>
												<span className="dial-code">
													+235
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cl"
												role="option"
												data-dial-code="56"
												data-country-code="cl"
											>
												<div className="flag-box">
													<div className="iti-flag cl"></div>
												</div>
												<span className="country-name">
													Chile
												</span>
												<span className="dial-code">
													+56
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cn"
												role="option"
												data-dial-code="86"
												data-country-code="cn"
											>
												<div className="flag-box">
													<div className="iti-flag cn"></div>
												</div>
												<span className="country-name">
													China (中国)
												</span>
												<span className="dial-code">
													+86
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cx"
												role="option"
												data-dial-code="61"
												data-country-code="cx"
											>
												<div className="flag-box">
													<div className="iti-flag cx"></div>
												</div>
												<span className="country-name">
													Christmas Island
												</span>
												<span className="dial-code">
													+61
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cc"
												role="option"
												data-dial-code="61"
												data-country-code="cc"
											>
												<div className="flag-box">
													<div className="iti-flag cc"></div>
												</div>
												<span className="country-name">
													Cocos (Keeling) Islands
												</span>
												<span className="dial-code">
													+61
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-co"
												role="option"
												data-dial-code="57"
												data-country-code="co"
											>
												<div className="flag-box">
													<div className="iti-flag co"></div>
												</div>
												<span className="country-name">
													Colombia
												</span>
												<span className="dial-code">
													+57
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-km"
												role="option"
												data-dial-code="269"
												data-country-code="km"
											>
												<div className="flag-box">
													<div className="iti-flag km"></div>
												</div>
												<span className="country-name">
													Comoros (‫جزر القمر‬‎)
												</span>
												<span className="dial-code">
													+269
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cd"
												role="option"
												data-dial-code="243"
												data-country-code="cd"
											>
												<div className="flag-box">
													<div className="iti-flag cd"></div>
												</div>
												<span className="country-name">
													Congo (DRC) (Jamhuri ya
													Kidemokrasia ya Kongo)
												</span>
												<span className="dial-code">
													+243
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cg"
												role="option"
												data-dial-code="242"
												data-country-code="cg"
											>
												<div className="flag-box">
													<div className="iti-flag cg"></div>
												</div>
												<span className="country-name">
													Congo (Republic)
													(Congo-Brazzaville)
												</span>
												<span className="dial-code">
													+242
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ck"
												role="option"
												data-dial-code="682"
												data-country-code="ck"
											>
												<div className="flag-box">
													<div className="iti-flag ck"></div>
												</div>
												<span className="country-name">
													Cook Islands
												</span>
												<span className="dial-code">
													+682
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cr"
												role="option"
												data-dial-code="506"
												data-country-code="cr"
											>
												<div className="flag-box">
													<div className="iti-flag cr"></div>
												</div>
												<span className="country-name">
													Costa Rica
												</span>
												<span className="dial-code">
													+506
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ci"
												role="option"
												data-dial-code="225"
												data-country-code="ci"
											>
												<div className="flag-box">
													<div className="iti-flag ci"></div>
												</div>
												<span className="country-name">
													Côte d’Ivoire
												</span>
												<span className="dial-code">
													+225
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-hr"
												role="option"
												data-dial-code="385"
												data-country-code="hr"
											>
												<div className="flag-box">
													<div className="iti-flag hr"></div>
												</div>
												<span className="country-name">
													Croatia (Hrvatska)
												</span>
												<span className="dial-code">
													+385
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cu"
												role="option"
												data-dial-code="53"
												data-country-code="cu"
											>
												<div className="flag-box">
													<div className="iti-flag cu"></div>
												</div>
												<span className="country-name">
													Cuba
												</span>
												<span className="dial-code">
													+53
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cw"
												role="option"
												data-dial-code="599"
												data-country-code="cw"
											>
												<div className="flag-box">
													<div className="iti-flag cw"></div>
												</div>
												<span className="country-name">
													Curaçao
												</span>
												<span className="dial-code">
													+599
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cy"
												role="option"
												data-dial-code="357"
												data-country-code="cy"
											>
												<div className="flag-box">
													<div className="iti-flag cy"></div>
												</div>
												<span className="country-name">
													Cyprus (Κύπρος)
												</span>
												<span className="dial-code">
													+357
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-cz"
												role="option"
												data-dial-code="420"
												data-country-code="cz"
											>
												<div className="flag-box">
													<div className="iti-flag cz"></div>
												</div>
												<span className="country-name">
													Czech Republic (Česká
													republika)
												</span>
												<span className="dial-code">
													+420
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-dk"
												role="option"
												data-dial-code="45"
												data-country-code="dk"
											>
												<div className="flag-box">
													<div className="iti-flag dk"></div>
												</div>
												<span className="country-name">
													Denmark (Danmark)
												</span>
												<span className="dial-code">
													+45
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-dj"
												role="option"
												data-dial-code="253"
												data-country-code="dj"
											>
												<div className="flag-box">
													<div className="iti-flag dj"></div>
												</div>
												<span className="country-name">
													Djibouti
												</span>
												<span className="dial-code">
													+253
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-dm"
												role="option"
												data-dial-code="1767"
												data-country-code="dm"
											>
												<div className="flag-box">
													<div className="iti-flag dm"></div>
												</div>
												<span className="country-name">
													Dominica
												</span>
												<span className="dial-code">
													+1767
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-do"
												role="option"
												data-dial-code="1"
												data-country-code="do"
											>
												<div className="flag-box">
													<div className="iti-flag do"></div>
												</div>
												<span className="country-name">
													Dominican Republic
													(República Dominicana)
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ec"
												role="option"
												data-dial-code="593"
												data-country-code="ec"
											>
												<div className="flag-box">
													<div className="iti-flag ec"></div>
												</div>
												<span className="country-name">
													Ecuador
												</span>
												<span className="dial-code">
													+593
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-eg"
												role="option"
												data-dial-code="20"
												data-country-code="eg"
											>
												<div className="flag-box">
													<div className="iti-flag eg"></div>
												</div>
												<span className="country-name">
													Egypt (‫مصر‬‎)
												</span>
												<span className="dial-code">
													+20
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-sv"
												role="option"
												data-dial-code="503"
												data-country-code="sv"
											>
												<div className="flag-box">
													<div className="iti-flag sv"></div>
												</div>
												<span className="country-name">
													El Salvador
												</span>
												<span className="dial-code">
													+503
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gq"
												role="option"
												data-dial-code="240"
												data-country-code="gq"
											>
												<div className="flag-box">
													<div className="iti-flag gq"></div>
												</div>
												<span className="country-name">
													Equatorial Guinea (Guinea
													Ecuatorial)
												</span>
												<span className="dial-code">
													+240
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-er"
												role="option"
												data-dial-code="291"
												data-country-code="er"
											>
												<div className="flag-box">
													<div className="iti-flag er"></div>
												</div>
												<span className="country-name">
													Eritrea
												</span>
												<span className="dial-code">
													+291
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ee"
												role="option"
												data-dial-code="372"
												data-country-code="ee"
											>
												<div className="flag-box">
													<div className="iti-flag ee"></div>
												</div>
												<span className="country-name">
													Estonia (Eesti)
												</span>
												<span className="dial-code">
													+372
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-et"
												role="option"
												data-dial-code="251"
												data-country-code="et"
											>
												<div className="flag-box">
													<div className="iti-flag et"></div>
												</div>
												<span className="country-name">
													Ethiopia
												</span>
												<span className="dial-code">
													+251
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fk"
												role="option"
												data-dial-code="500"
												data-country-code="fk"
											>
												<div className="flag-box">
													<div className="iti-flag fk"></div>
												</div>
												<span className="country-name">
													Falkland Islands (Islas
													Malvinas)
												</span>
												<span className="dial-code">
													+500
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fo"
												role="option"
												data-dial-code="298"
												data-country-code="fo"
											>
												<div className="flag-box">
													<div className="iti-flag fo"></div>
												</div>
												<span className="country-name">
													Faroe Islands (Føroyar)
												</span>
												<span className="dial-code">
													+298
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fj"
												role="option"
												data-dial-code="679"
												data-country-code="fj"
											>
												<div className="flag-box">
													<div className="iti-flag fj"></div>
												</div>
												<span className="country-name">
													Fiji
												</span>
												<span className="dial-code">
													+679
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fi"
												role="option"
												data-dial-code="358"
												data-country-code="fi"
											>
												<div className="flag-box">
													<div className="iti-flag fi"></div>
												</div>
												<span className="country-name">
													Finland (Suomi)
												</span>
												<span className="dial-code">
													+358
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fr"
												role="option"
												data-dial-code="33"
												data-country-code="fr"
											>
												<div className="flag-box">
													<div className="iti-flag fr"></div>
												</div>
												<span className="country-name">
													France
												</span>
												<span className="dial-code">
													+33
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gf"
												role="option"
												data-dial-code="594"
												data-country-code="gf"
											>
												<div className="flag-box">
													<div className="iti-flag gf"></div>
												</div>
												<span className="country-name">
													French Guiana (Guyane
													française)
												</span>
												<span className="dial-code">
													+594
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pf"
												role="option"
												data-dial-code="689"
												data-country-code="pf"
											>
												<div className="flag-box">
													<div className="iti-flag pf"></div>
												</div>
												<span className="country-name">
													French Polynesia (Polynésie
													française)
												</span>
												<span className="dial-code">
													+689
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ga"
												role="option"
												data-dial-code="241"
												data-country-code="ga"
											>
												<div className="flag-box">
													<div className="iti-flag ga"></div>
												</div>
												<span className="country-name">
													Gabon
												</span>
												<span className="dial-code">
													+241
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gm"
												role="option"
												data-dial-code="220"
												data-country-code="gm"
											>
												<div className="flag-box">
													<div className="iti-flag gm"></div>
												</div>
												<span className="country-name">
													Gambia
												</span>
												<span className="dial-code">
													+220
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ge"
												role="option"
												data-dial-code="995"
												data-country-code="ge"
											>
												<div className="flag-box">
													<div className="iti-flag ge"></div>
												</div>
												<span className="country-name">
													Georgia (საქართველო)
												</span>
												<span className="dial-code">
													+995
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-de"
												role="option"
												data-dial-code="49"
												data-country-code="de"
											>
												<div className="flag-box">
													<div className="iti-flag de"></div>
												</div>
												<span className="country-name">
													Germany (Deutschland)
												</span>
												<span className="dial-code">
													+49
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gh"
												role="option"
												data-dial-code="233"
												data-country-code="gh"
											>
												<div className="flag-box">
													<div className="iti-flag gh"></div>
												</div>
												<span className="country-name">
													Ghana (Gaana)
												</span>
												<span className="dial-code">
													+233
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gi"
												role="option"
												data-dial-code="350"
												data-country-code="gi"
											>
												<div className="flag-box">
													<div className="iti-flag gi"></div>
												</div>
												<span className="country-name">
													Gibraltar
												</span>
												<span className="dial-code">
													+350
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gr"
												role="option"
												data-dial-code="30"
												data-country-code="gr"
											>
												<div className="flag-box">
													<div className="iti-flag gr"></div>
												</div>
												<span className="country-name">
													Greece (Ελλάδα)
												</span>
												<span className="dial-code">
													+30
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gl"
												role="option"
												data-dial-code="299"
												data-country-code="gl"
											>
												<div className="flag-box">
													<div className="iti-flag gl"></div>
												</div>
												<span className="country-name">
													Greenland (Kalaallit Nunaat)
												</span>
												<span className="dial-code">
													+299
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gd"
												role="option"
												data-dial-code="1473"
												data-country-code="gd"
											>
												<div className="flag-box">
													<div className="iti-flag gd"></div>
												</div>
												<span className="country-name">
													Grenada
												</span>
												<span className="dial-code">
													+1473
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gp"
												role="option"
												data-dial-code="590"
												data-country-code="gp"
											>
												<div className="flag-box">
													<div className="iti-flag gp"></div>
												</div>
												<span className="country-name">
													Guadeloupe
												</span>
												<span className="dial-code">
													+590
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gu"
												role="option"
												data-dial-code="1671"
												data-country-code="gu"
											>
												<div className="flag-box">
													<div className="iti-flag gu"></div>
												</div>
												<span className="country-name">
													Guam
												</span>
												<span className="dial-code">
													+1671
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gt"
												role="option"
												data-dial-code="502"
												data-country-code="gt"
											>
												<div className="flag-box">
													<div className="iti-flag gt"></div>
												</div>
												<span className="country-name">
													Guatemala
												</span>
												<span className="dial-code">
													+502
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gg"
												role="option"
												data-dial-code="44"
												data-country-code="gg"
											>
												<div className="flag-box">
													<div className="iti-flag gg"></div>
												</div>
												<span className="country-name">
													Guernsey
												</span>
												<span className="dial-code">
													+44
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gn"
												role="option"
												data-dial-code="224"
												data-country-code="gn"
											>
												<div className="flag-box">
													<div className="iti-flag gn"></div>
												</div>
												<span className="country-name">
													Guinea (Guinée)
												</span>
												<span className="dial-code">
													+224
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gw"
												role="option"
												data-dial-code="245"
												data-country-code="gw"
											>
												<div className="flag-box">
													<div className="iti-flag gw"></div>
												</div>
												<span className="country-name">
													Guinea-Bissau (Guiné Bissau)
												</span>
												<span className="dial-code">
													+245
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-gy"
												role="option"
												data-dial-code="592"
												data-country-code="gy"
											>
												<div className="flag-box">
													<div className="iti-flag gy"></div>
												</div>
												<span className="country-name">
													Guyana
												</span>
												<span className="dial-code">
													+592
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ht"
												role="option"
												data-dial-code="509"
												data-country-code="ht"
											>
												<div className="flag-box">
													<div className="iti-flag ht"></div>
												</div>
												<span className="country-name">
													Haiti
												</span>
												<span className="dial-code">
													+509
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-hn"
												role="option"
												data-dial-code="504"
												data-country-code="hn"
											>
												<div className="flag-box">
													<div className="iti-flag hn"></div>
												</div>
												<span className="country-name">
													Honduras
												</span>
												<span className="dial-code">
													+504
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-hk"
												role="option"
												data-dial-code="852"
												data-country-code="hk"
											>
												<div className="flag-box">
													<div className="iti-flag hk"></div>
												</div>
												<span className="country-name">
													Hong Kong (香港)
												</span>
												<span className="dial-code">
													+852
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-hu"
												role="option"
												data-dial-code="36"
												data-country-code="hu"
											>
												<div className="flag-box">
													<div className="iti-flag hu"></div>
												</div>
												<span className="country-name">
													Hungary (Magyarország)
												</span>
												<span className="dial-code">
													+36
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-is"
												role="option"
												data-dial-code="354"
												data-country-code="is"
											>
												<div className="flag-box">
													<div className="iti-flag is"></div>
												</div>
												<span className="country-name">
													Iceland (Ísland)
												</span>
												<span className="dial-code">
													+354
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-in"
												role="option"
												data-dial-code="91"
												data-country-code="in"
											>
												<div className="flag-box">
													<div className="iti-flag in"></div>
												</div>
												<span className="country-name">
													India (भारत)
												</span>
												<span className="dial-code">
													+91
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-id"
												role="option"
												data-dial-code="62"
												data-country-code="id"
											>
												<div className="flag-box">
													<div className="iti-flag id"></div>
												</div>
												<span className="country-name">
													Indonesia
												</span>
												<span className="dial-code">
													+62
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ir"
												role="option"
												data-dial-code="98"
												data-country-code="ir"
											>
												<div className="flag-box">
													<div className="iti-flag ir"></div>
												</div>
												<span className="country-name">
													Iran (‫ایران‬‎)
												</span>
												<span className="dial-code">
													+98
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-iq"
												role="option"
												data-dial-code="964"
												data-country-code="iq"
											>
												<div className="flag-box">
													<div className="iti-flag iq"></div>
												</div>
												<span className="country-name">
													Iraq (‫العراق‬‎)
												</span>
												<span className="dial-code">
													+964
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ie"
												role="option"
												data-dial-code="353"
												data-country-code="ie"
											>
												<div className="flag-box">
													<div className="iti-flag ie"></div>
												</div>
												<span className="country-name">
													Ireland
												</span>
												<span className="dial-code">
													+353
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-im"
												role="option"
												data-dial-code="44"
												data-country-code="im"
											>
												<div className="flag-box">
													<div className="iti-flag im"></div>
												</div>
												<span className="country-name">
													Isle of Man
												</span>
												<span className="dial-code">
													+44
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-il"
												role="option"
												data-dial-code="972"
												data-country-code="il"
											>
												<div className="flag-box">
													<div className="iti-flag il"></div>
												</div>
												<span className="country-name">
													Israel (‫ישראל‬‎)
												</span>
												<span className="dial-code">
													+972
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-it"
												role="option"
												data-dial-code="39"
												data-country-code="it"
											>
												<div className="flag-box">
													<div className="iti-flag it"></div>
												</div>
												<span className="country-name">
													Italy (Italia)
												</span>
												<span className="dial-code">
													+39
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-jm"
												role="option"
												data-dial-code="1"
												data-country-code="jm"
											>
												<div className="flag-box">
													<div className="iti-flag jm"></div>
												</div>
												<span className="country-name">
													Jamaica
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-jp"
												role="option"
												data-dial-code="81"
												data-country-code="jp"
											>
												<div className="flag-box">
													<div className="iti-flag jp"></div>
												</div>
												<span className="country-name">
													Japan (日本)
												</span>
												<span className="dial-code">
													+81
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-je"
												role="option"
												data-dial-code="44"
												data-country-code="je"
											>
												<div className="flag-box">
													<div className="iti-flag je"></div>
												</div>
												<span className="country-name">
													Jersey
												</span>
												<span className="dial-code">
													+44
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-jo"
												role="option"
												data-dial-code="962"
												data-country-code="jo"
											>
												<div className="flag-box">
													<div className="iti-flag jo"></div>
												</div>
												<span className="country-name">
													Jordan (‫الأردن‬‎)
												</span>
												<span className="dial-code">
													+962
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kz"
												role="option"
												data-dial-code="7"
												data-country-code="kz"
											>
												<div className="flag-box">
													<div className="iti-flag kz"></div>
												</div>
												<span className="country-name">
													Kazakhstan (Казахстан)
												</span>
												<span className="dial-code">
													+7
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ke"
												role="option"
												data-dial-code="254"
												data-country-code="ke"
											>
												<div className="flag-box">
													<div className="iti-flag ke"></div>
												</div>
												<span className="country-name">
													Kenya
												</span>
												<span className="dial-code">
													+254
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ki"
												role="option"
												data-dial-code="686"
												data-country-code="ki"
											>
												<div className="flag-box">
													<div className="iti-flag ki"></div>
												</div>
												<span className="country-name">
													Kiribati
												</span>
												<span className="dial-code">
													+686
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-xk"
												role="option"
												data-dial-code="383"
												data-country-code="xk"
											>
												<div className="flag-box">
													<div className="iti-flag xk"></div>
												</div>
												<span className="country-name">
													Kosovo
												</span>
												<span className="dial-code">
													+383
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kw"
												role="option"
												data-dial-code="965"
												data-country-code="kw"
											>
												<div className="flag-box">
													<div className="iti-flag kw"></div>
												</div>
												<span className="country-name">
													Kuwait (‫الكويت‬‎)
												</span>
												<span className="dial-code">
													+965
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kg"
												role="option"
												data-dial-code="996"
												data-country-code="kg"
											>
												<div className="flag-box">
													<div className="iti-flag kg"></div>
												</div>
												<span className="country-name">
													Kyrgyzstan (Кыргызстан)
												</span>
												<span className="dial-code">
													+996
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-la"
												role="option"
												data-dial-code="856"
												data-country-code="la"
											>
												<div className="flag-box">
													<div className="iti-flag la"></div>
												</div>
												<span className="country-name">
													Laos (ລາວ)
												</span>
												<span className="dial-code">
													+856
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lv"
												role="option"
												data-dial-code="371"
												data-country-code="lv"
											>
												<div className="flag-box">
													<div className="iti-flag lv"></div>
												</div>
												<span className="country-name">
													Latvia (Latvija)
												</span>
												<span className="dial-code">
													+371
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lb"
												role="option"
												data-dial-code="961"
												data-country-code="lb"
											>
												<div className="flag-box">
													<div className="iti-flag lb"></div>
												</div>
												<span className="country-name">
													Lebanon (‫لبنان‬‎)
												</span>
												<span className="dial-code">
													+961
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ls"
												role="option"
												data-dial-code="266"
												data-country-code="ls"
											>
												<div className="flag-box">
													<div className="iti-flag ls"></div>
												</div>
												<span className="country-name">
													Lesotho
												</span>
												<span className="dial-code">
													+266
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lr"
												role="option"
												data-dial-code="231"
												data-country-code="lr"
											>
												<div className="flag-box">
													<div className="iti-flag lr"></div>
												</div>
												<span className="country-name">
													Liberia
												</span>
												<span className="dial-code">
													+231
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ly"
												role="option"
												data-dial-code="218"
												data-country-code="ly"
											>
												<div className="flag-box">
													<div className="iti-flag ly"></div>
												</div>
												<span className="country-name">
													Libya (‫ليبيا‬‎)
												</span>
												<span className="dial-code">
													+218
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-li"
												role="option"
												data-dial-code="423"
												data-country-code="li"
											>
												<div className="flag-box">
													<div className="iti-flag li"></div>
												</div>
												<span className="country-name">
													Liechtenstein
												</span>
												<span className="dial-code">
													+423
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lt"
												role="option"
												data-dial-code="370"
												data-country-code="lt"
											>
												<div className="flag-box">
													<div className="iti-flag lt"></div>
												</div>
												<span className="country-name">
													Lithuania (Lietuva)
												</span>
												<span className="dial-code">
													+370
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lu"
												role="option"
												data-dial-code="352"
												data-country-code="lu"
											>
												<div className="flag-box">
													<div className="iti-flag lu"></div>
												</div>
												<span className="country-name">
													Luxembourg
												</span>
												<span className="dial-code">
													+352
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mo"
												role="option"
												data-dial-code="853"
												data-country-code="mo"
											>
												<div className="flag-box">
													<div className="iti-flag mo"></div>
												</div>
												<span className="country-name">
													Macau (澳門)
												</span>
												<span className="dial-code">
													+853
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mk"
												role="option"
												data-dial-code="389"
												data-country-code="mk"
											>
												<div className="flag-box">
													<div className="iti-flag mk"></div>
												</div>
												<span className="country-name">
													Macedonia (FYROM)
													(Македонија)
												</span>
												<span className="dial-code">
													+389
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mg"
												role="option"
												data-dial-code="261"
												data-country-code="mg"
											>
												<div className="flag-box">
													<div className="iti-flag mg"></div>
												</div>
												<span className="country-name">
													Madagascar (Madagasikara)
												</span>
												<span className="dial-code">
													+261
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mw"
												role="option"
												data-dial-code="265"
												data-country-code="mw"
											>
												<div className="flag-box">
													<div className="iti-flag mw"></div>
												</div>
												<span className="country-name">
													Malawi
												</span>
												<span className="dial-code">
													+265
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-my"
												role="option"
												data-dial-code="60"
												data-country-code="my"
											>
												<div className="flag-box">
													<div className="iti-flag my"></div>
												</div>
												<span className="country-name">
													Malaysia
												</span>
												<span className="dial-code">
													+60
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mv"
												role="option"
												data-dial-code="960"
												data-country-code="mv"
											>
												<div className="flag-box">
													<div className="iti-flag mv"></div>
												</div>
												<span className="country-name">
													Maldives
												</span>
												<span className="dial-code">
													+960
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ml"
												role="option"
												data-dial-code="223"
												data-country-code="ml"
											>
												<div className="flag-box">
													<div className="iti-flag ml"></div>
												</div>
												<span className="country-name">
													Mali
												</span>
												<span className="dial-code">
													+223
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mt"
												role="option"
												data-dial-code="356"
												data-country-code="mt"
											>
												<div className="flag-box">
													<div className="iti-flag mt"></div>
												</div>
												<span className="country-name">
													Malta
												</span>
												<span className="dial-code">
													+356
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mh"
												role="option"
												data-dial-code="692"
												data-country-code="mh"
											>
												<div className="flag-box">
													<div className="iti-flag mh"></div>
												</div>
												<span className="country-name">
													Marshall Islands
												</span>
												<span className="dial-code">
													+692
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mq"
												role="option"
												data-dial-code="596"
												data-country-code="mq"
											>
												<div className="flag-box">
													<div className="iti-flag mq"></div>
												</div>
												<span className="country-name">
													Martinique
												</span>
												<span className="dial-code">
													+596
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mr"
												role="option"
												data-dial-code="222"
												data-country-code="mr"
											>
												<div className="flag-box">
													<div className="iti-flag mr"></div>
												</div>
												<span className="country-name">
													Mauritania (‫موريتانيا‬‎)
												</span>
												<span className="dial-code">
													+222
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mu"
												role="option"
												data-dial-code="230"
												data-country-code="mu"
											>
												<div className="flag-box">
													<div className="iti-flag mu"></div>
												</div>
												<span className="country-name">
													Mauritius (Moris)
												</span>
												<span className="dial-code">
													+230
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-yt"
												role="option"
												data-dial-code="262"
												data-country-code="yt"
											>
												<div className="flag-box">
													<div className="iti-flag yt"></div>
												</div>
												<span className="country-name">
													Mayotte
												</span>
												<span className="dial-code">
													+262
												</span>
											</option>
											<option
												className="country standard active"
												id="iti-item-mx"
												role="option"
												data-dial-code="52"
												data-country-code="mx"
												aria-selected="true"
											>
												<div className="flag-box">
													<div className="iti-flag mx"></div>
												</div>
												<span className="country-name">
													Mexico (México)
												</span>
												<span className="dial-code">
													+52
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-fm"
												role="option"
												data-dial-code="691"
												data-country-code="fm"
											>
												<div className="flag-box">
													<div className="iti-flag fm"></div>
												</div>
												<span className="country-name">
													Micronesia
												</span>
												<span className="dial-code">
													+691
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-md"
												role="option"
												data-dial-code="373"
												data-country-code="md"
											>
												<div className="flag-box">
													<div className="iti-flag md"></div>
												</div>
												<span className="country-name">
													Moldova (Republica Moldova)
												</span>
												<span className="dial-code">
													+373
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mc"
												role="option"
												data-dial-code="377"
												data-country-code="mc"
											>
												<div className="flag-box">
													<div className="iti-flag mc"></div>
												</div>
												<span className="country-name">
													Monaco
												</span>
												<span className="dial-code">
													+377
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mn"
												role="option"
												data-dial-code="976"
												data-country-code="mn"
											>
												<div className="flag-box">
													<div className="iti-flag mn"></div>
												</div>
												<span className="country-name">
													Mongolia (Монгол)
												</span>
												<span className="dial-code">
													+976
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-me"
												role="option"
												data-dial-code="382"
												data-country-code="me"
											>
												<div className="flag-box">
													<div className="iti-flag me"></div>
												</div>
												<span className="country-name">
													Montenegro (Crna Gora)
												</span>
												<span className="dial-code">
													+382
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ms"
												role="option"
												data-dial-code="1664"
												data-country-code="ms"
											>
												<div className="flag-box">
													<div className="iti-flag ms"></div>
												</div>
												<span className="country-name">
													Montserrat
												</span>
												<span className="dial-code">
													+1664
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ma"
												role="option"
												data-dial-code="212"
												data-country-code="ma"
											>
												<div className="flag-box">
													<div className="iti-flag ma"></div>
												</div>
												<span className="country-name">
													Morocco (‫المغرب‬‎)
												</span>
												<span className="dial-code">
													+212
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mz"
												role="option"
												data-dial-code="258"
												data-country-code="mz"
											>
												<div className="flag-box">
													<div className="iti-flag mz"></div>
												</div>
												<span className="country-name">
													Mozambique (Moçambique)
												</span>
												<span className="dial-code">
													+258
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mm"
												role="option"
												data-dial-code="95"
												data-country-code="mm"
											>
												<div className="flag-box">
													<div className="iti-flag mm"></div>
												</div>
												<span className="country-name">
													Myanmar (Burma) (မြန်မာ)
												</span>
												<span className="dial-code">
													+95
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-na"
												role="option"
												data-dial-code="264"
												data-country-code="na"
											>
												<div className="flag-box">
													<div className="iti-flag na"></div>
												</div>
												<span className="country-name">
													Namibia (Namibië)
												</span>
												<span className="dial-code">
													+264
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nr"
												role="option"
												data-dial-code="674"
												data-country-code="nr"
											>
												<div className="flag-box">
													<div className="iti-flag nr"></div>
												</div>
												<span className="country-name">
													Nauru
												</span>
												<span className="dial-code">
													+674
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-np"
												role="option"
												data-dial-code="977"
												data-country-code="np"
											>
												<div className="flag-box">
													<div className="iti-flag np"></div>
												</div>
												<span className="country-name">
													Nepal (नेपाल)
												</span>
												<span className="dial-code">
													+977
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nl"
												role="option"
												data-dial-code="31"
												data-country-code="nl"
											>
												<div className="flag-box">
													<div className="iti-flag nl"></div>
												</div>
												<span className="country-name">
													Netherlands (Nederland)
												</span>
												<span className="dial-code">
													+31
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nc"
												role="option"
												data-dial-code="687"
												data-country-code="nc"
											>
												<div className="flag-box">
													<div className="iti-flag nc"></div>
												</div>
												<span className="country-name">
													New Caledonia
													(Nouvelle-Calédonie)
												</span>
												<span className="dial-code">
													+687
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nz"
												role="option"
												data-dial-code="64"
												data-country-code="nz"
											>
												<div className="flag-box">
													<div className="iti-flag nz"></div>
												</div>
												<span className="country-name">
													New Zealand
												</span>
												<span className="dial-code">
													+64
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ni"
												role="option"
												data-dial-code="505"
												data-country-code="ni"
											>
												<div className="flag-box">
													<div className="iti-flag ni"></div>
												</div>
												<span className="country-name">
													Nicaragua
												</span>
												<span className="dial-code">
													+505
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ne"
												role="option"
												data-dial-code="227"
												data-country-code="ne"
											>
												<div className="flag-box">
													<div className="iti-flag ne"></div>
												</div>
												<span className="country-name">
													Niger (Nijar)
												</span>
												<span className="dial-code">
													+227
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ng"
												role="option"
												data-dial-code="234"
												data-country-code="ng"
											>
												<div className="flag-box">
													<div className="iti-flag ng"></div>
												</div>
												<span className="country-name">
													Nigeria
												</span>
												<span className="dial-code">
													+234
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nu"
												role="option"
												data-dial-code="683"
												data-country-code="nu"
											>
												<div className="flag-box">
													<div className="iti-flag nu"></div>
												</div>
												<span className="country-name">
													Niue
												</span>
												<span className="dial-code">
													+683
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-nf"
												role="option"
												data-dial-code="672"
												data-country-code="nf"
											>
												<div className="flag-box">
													<div className="iti-flag nf"></div>
												</div>
												<span className="country-name">
													Norfolk Island
												</span>
												<span className="dial-code">
													+672
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kp"
												role="option"
												data-dial-code="850"
												data-country-code="kp"
											>
												<div className="flag-box">
													<div className="iti-flag kp"></div>
												</div>
												<span className="country-name">
													North Korea (조선 민주주의
													인민 공화국)
												</span>
												<span className="dial-code">
													+850
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mp"
												role="option"
												data-dial-code="1670"
												data-country-code="mp"
											>
												<div className="flag-box">
													<div className="iti-flag mp"></div>
												</div>
												<span className="country-name">
													Northern Mariana Islands
												</span>
												<span className="dial-code">
													+1670
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-no"
												role="option"
												data-dial-code="47"
												data-country-code="no"
											>
												<div className="flag-box">
													<div className="iti-flag no"></div>
												</div>
												<span className="country-name">
													Norway (Norge)
												</span>
												<span className="dial-code">
													+47
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-om"
												role="option"
												data-dial-code="968"
												data-country-code="om"
											>
												<div className="flag-box">
													<div className="iti-flag om"></div>
												</div>
												<span className="country-name">
													Oman (‫عُمان‬‎)
												</span>
												<span className="dial-code">
													+968
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pk"
												role="option"
												data-dial-code="92"
												data-country-code="pk"
											>
												<div className="flag-box">
													<div className="iti-flag pk"></div>
												</div>
												<span className="country-name">
													Pakistan (‫پاکستان‬‎)
												</span>
												<span className="dial-code">
													+92
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pw"
												role="option"
												data-dial-code="680"
												data-country-code="pw"
											>
												<div className="flag-box">
													<div className="iti-flag pw"></div>
												</div>
												<span className="country-name">
													Palau
												</span>
												<span className="dial-code">
													+680
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ps"
												role="option"
												data-dial-code="970"
												data-country-code="ps"
											>
												<div className="flag-box">
													<div className="iti-flag ps"></div>
												</div>
												<span className="country-name">
													Palestine (‫فلسطين‬‎)
												</span>
												<span className="dial-code">
													+970
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pa"
												role="option"
												data-dial-code="507"
												data-country-code="pa"
											>
												<div className="flag-box">
													<div className="iti-flag pa"></div>
												</div>
												<span className="country-name">
													Panama (Panamá)
												</span>
												<span className="dial-code">
													+507
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pg"
												role="option"
												data-dial-code="675"
												data-country-code="pg"
											>
												<div className="flag-box">
													<div className="iti-flag pg"></div>
												</div>
												<span className="country-name">
													Papua New Guinea
												</span>
												<span className="dial-code">
													+675
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-py"
												role="option"
												data-dial-code="595"
												data-country-code="py"
											>
												<div className="flag-box">
													<div className="iti-flag py"></div>
												</div>
												<span className="country-name">
													Paraguay
												</span>
												<span className="dial-code">
													+595
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pe"
												role="option"
												data-dial-code="51"
												data-country-code="pe"
											>
												<div className="flag-box">
													<div className="iti-flag pe"></div>
												</div>
												<span className="country-name">
													Peru (Perú)
												</span>
												<span className="dial-code">
													+51
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ph"
												role="option"
												data-dial-code="63"
												data-country-code="ph"
											>
												<div className="flag-box">
													<div className="iti-flag ph"></div>
												</div>
												<span className="country-name">
													Philippines
												</span>
												<span className="dial-code">
													+63
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pl"
												role="option"
												data-dial-code="48"
												data-country-code="pl"
											>
												<div className="flag-box">
													<div className="iti-flag pl"></div>
												</div>
												<span className="country-name">
													Poland (Polska)
												</span>
												<span className="dial-code">
													+48
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pt"
												role="option"
												data-dial-code="351"
												data-country-code="pt"
											>
												<div className="flag-box">
													<div className="iti-flag pt"></div>
												</div>
												<span className="country-name">
													Portugal
												</span>
												<span className="dial-code">
													+351
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pr"
												role="option"
												data-dial-code="1"
												data-country-code="pr"
											>
												<div className="flag-box">
													<div className="iti-flag pr"></div>
												</div>
												<span className="country-name">
													Puerto Rico
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-qa"
												role="option"
												data-dial-code="974"
												data-country-code="qa"
											>
												<div className="flag-box">
													<div className="iti-flag qa"></div>
												</div>
												<span className="country-name">
													Qatar (‫قطر‬‎)
												</span>
												<span className="dial-code">
													+974
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-re"
												role="option"
												data-dial-code="262"
												data-country-code="re"
											>
												<div className="flag-box">
													<div className="iti-flag re"></div>
												</div>
												<span className="country-name">
													Réunion (La Réunion)
												</span>
												<span className="dial-code">
													+262
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ro"
												role="option"
												data-dial-code="40"
												data-country-code="ro"
											>
												<div className="flag-box">
													<div className="iti-flag ro"></div>
												</div>
												<span className="country-name">
													Romania (România)
												</span>
												<span className="dial-code">
													+40
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ru"
												role="option"
												data-dial-code="7"
												data-country-code="ru"
											>
												<div className="flag-box">
													<div className="iti-flag ru"></div>
												</div>
												<span className="country-name">
													Russia (Россия)
												</span>
												<span className="dial-code">
													+7
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-rw"
												role="option"
												data-dial-code="250"
												data-country-code="rw"
											>
												<div className="flag-box">
													<div className="iti-flag rw"></div>
												</div>
												<span className="country-name">
													Rwanda
												</span>
												<span className="dial-code">
													+250
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-bl"
												role="option"
												data-dial-code="590"
												data-country-code="bl"
											>
												<div className="flag-box">
													<div className="iti-flag bl"></div>
												</div>
												<span className="country-name">
													Saint Barthélemy
												</span>
												<span className="dial-code">
													+590
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-sh"
												role="option"
												data-dial-code="290"
												data-country-code="sh"
											>
												<div className="flag-box">
													<div className="iti-flag sh"></div>
												</div>
												<span className="country-name">
													Saint Helena
												</span>
												<span className="dial-code">
													+290
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-kn"
												role="option"
												data-dial-code="1869"
												data-country-code="kn"
											>
												<div className="flag-box">
													<div className="iti-flag kn"></div>
												</div>
												<span className="country-name">
													Saint Kitts and Nevis
												</span>
												<span className="dial-code">
													+1869
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-lc"
												role="option"
												data-dial-code="1758"
												data-country-code="lc"
											>
												<div className="flag-box">
													<div className="iti-flag lc"></div>
												</div>
												<span className="country-name">
													Saint Lucia
												</span>
												<span className="dial-code">
													+1758
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-mf"
												role="option"
												data-dial-code="590"
												data-country-code="mf"
											>
												<div className="flag-box">
													<div className="iti-flag mf"></div>
												</div>
												<span className="country-name">
													Saint Martin (Saint-Martin
													(partie française))
												</span>
												<span className="dial-code">
													+590
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-pm"
												role="option"
												data-dial-code="508"
												data-country-code="pm"
											>
												<div className="flag-box">
													<div className="iti-flag pm"></div>
												</div>
												<span className="country-name">
													Saint Pierre and Miquelon
													(Saint-Pierre-et-Miquelon)
												</span>
												<span className="dial-code">
													+508
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-vc"
												role="option"
												data-dial-code="1784"
												data-country-code="vc"
											>
												<div className="flag-box">
													<div className="iti-flag vc"></div>
												</div>
												<span className="country-name">
													Saint Vincent and the
													Grenadines
												</span>
												<span className="dial-code">
													+1784
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-ws"
												role="option"
												data-dial-code="685"
												data-country-code="ws"
											>
												<div className="flag-box">
													<div className="iti-flag ws"></div>
												</div>
												<span className="country-name">
													Samoa
												</span>
												<span className="dial-code">
													+685
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-sm"
												role="option"
												data-dial-code="378"
												data-country-code="sm"
											>
												<div className="flag-box">
													<div className="iti-flag sm"></div>
												</div>
												<span className="country-name">
													San Marino
												</span>
												<span className="dial-code">
													+378
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-st"
												role="option"
												data-dial-code="239"
												data-country-code="st"
											>
												<div className="flag-box">
													<div className="iti-flag st"></div>
												</div>
												<span className="country-name">
													São Tomé and Príncipe (São
													Tomé e Príncipe)
												</span>
												<span className="dial-code">
													+239
												</span>
											</option>
											<option
												className="country standard"
												id="iti-item-sa"
												role="option"
												data-dial-code="966"
												data-country-code="sa"
											>
												<div className="flag-box">
													<div className="iti-flag sa"></div>
												</div>
												<span className="country-name">
													Saudi Arabia (‫المملكة
													العربية السعودية‬‎)
												</span>
												<span className="dial-code">
													+966
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sn"
												role="option"
												data-dial-code="221"
												data-country-code="sn"
											>
												<div className="flag-box">
													<div className="iti-flag sn"></div>
												</div>
												<span className="country-name">
													Senegal (Sénégal)
												</span>
												<span className="dial-code">
													+221
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-rs"
												role="option"
												data-dial-code="381"
												data-country-code="rs"
											>
												<div className="flag-box">
													<div className="iti-flag rs"></div>
												</div>
												<span className="country-name">
													Serbia (Србија)
												</span>
												<span className="dial-code">
													+381
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sc"
												role="option"
												data-dial-code="248"
												data-country-code="sc"
											>
												<div className="flag-box">
													<div className="iti-flag sc"></div>
												</div>
												<span className="country-name">
													Seychelles
												</span>
												<span className="dial-code">
													+248
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sl"
												role="option"
												data-dial-code="232"
												data-country-code="sl"
											>
												<div className="flag-box">
													<div className="iti-flag sl"></div>
												</div>
												<span className="country-name">
													Sierra Leone
												</span>
												<span className="dial-code">
													+232
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sg"
												role="option"
												data-dial-code="65"
												data-country-code="sg"
											>
												<div className="flag-box">
													<div className="iti-flag sg"></div>
												</div>
												<span className="country-name">
													Singapore
												</span>
												<span className="dial-code">
													+65
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sx"
												role="option"
												data-dial-code="1721"
												data-country-code="sx"
											>
												<div className="flag-box">
													<div className="iti-flag sx"></div>
												</div>
												<span className="country-name">
													Sint Maarten
												</span>
												<span className="dial-code">
													+1721
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sk"
												role="option"
												data-dial-code="421"
												data-country-code="sk"
											>
												<div className="flag-box">
													<div className="iti-flag sk"></div>
												</div>
												<span className="country-name">
													Slovakia (Slovensko)
												</span>
												<span className="dial-code">
													+421
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-si"
												role="option"
												data-dial-code="386"
												data-country-code="si"
											>
												<div className="flag-box">
													<div className="iti-flag si"></div>
												</div>
												<span className="country-name">
													Slovenia (Slovenija)
												</span>
												<span className="dial-code">
													+386
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sb"
												role="option"
												data-dial-code="677"
												data-country-code="sb"
											>
												<div className="flag-box">
													<div className="iti-flag sb"></div>
												</div>
												<span className="country-name">
													Solomon Islands
												</span>
												<span className="dial-code">
													+677
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-so"
												role="option"
												data-dial-code="252"
												data-country-code="so"
											>
												<div className="flag-box">
													<div className="iti-flag so"></div>
												</div>
												<span className="country-name">
													Somalia (Soomaaliya)
												</span>
												<span className="dial-code">
													+252
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-za"
												role="option"
												data-dial-code="27"
												data-country-code="za"
											>
												<div className="flag-box">
													<div className="iti-flag za"></div>
												</div>
												<span className="country-name">
													South Africa
												</span>
												<span className="dial-code">
													+27
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-kr"
												role="option"
												data-dial-code="82"
												data-country-code="kr"
											>
												<div className="flag-box">
													<div className="iti-flag kr"></div>
												</div>
												<span className="country-name">
													South Korea (대한민국)
												</span>
												<span className="dial-code">
													+82
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ss"
												role="option"
												data-dial-code="211"
												data-country-code="ss"
											>
												<div className="flag-box">
													<div className="iti-flag ss"></div>
												</div>
												<span className="country-name">
													South Sudan (‫جنوب
													السودان‬‎)
												</span>
												<span className="dial-code">
													+211
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-es"
												role="option"
												data-dial-code="34"
												data-country-code="es"
											>
												<div className="flag-box">
													<div className="iti-flag es"></div>
												</div>
												<span className="country-name">
													Spain (España)
												</span>
												<span className="dial-code">
													+34
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-lk"
												role="option"
												data-dial-code="94"
												data-country-code="lk"
											>
												<div className="flag-box">
													<div className="iti-flag lk"></div>
												</div>
												<span className="country-name">
													Sri Lanka (ශ්‍රී ලංකාව)
												</span>
												<span className="dial-code">
													+94
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sd"
												role="option"
												data-dial-code="249"
												data-country-code="sd"
											>
												<div className="flag-box">
													<div className="iti-flag sd"></div>
												</div>
												<span className="country-name">
													Sudan (‫السودان‬‎)
												</span>
												<span className="dial-code">
													+249
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sr"
												role="option"
												data-dial-code="597"
												data-country-code="sr"
											>
												<div className="flag-box">
													<div className="iti-flag sr"></div>
												</div>
												<span className="country-name">
													Suriname
												</span>
												<span className="dial-code">
													+597
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sj"
												role="option"
												data-dial-code="47"
												data-country-code="sj"
											>
												<div className="flag-box">
													<div className="iti-flag sj"></div>
												</div>
												<span className="country-name">
													Svalbard and Jan Mayen
												</span>
												<span className="dial-code">
													+47
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sz"
												role="option"
												data-dial-code="268"
												data-country-code="sz"
											>
												<div className="flag-box">
													<div className="iti-flag sz"></div>
												</div>
												<span className="country-name">
													Swaziland
												</span>
												<span className="dial-code">
													+268
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-se"
												role="option"
												data-dial-code="46"
												data-country-code="se"
											>
												<div className="flag-box">
													<div className="iti-flag se"></div>
												</div>
												<span className="country-name">
													Sweden (Sverige)
												</span>
												<span className="dial-code">
													+46
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ch"
												role="option"
												data-dial-code="41"
												data-country-code="ch"
											>
												<div className="flag-box">
													<div className="iti-flag ch"></div>
												</div>
												<span className="country-name">
													Switzerland (Schweiz)
												</span>
												<span className="dial-code">
													+41
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-sy"
												role="option"
												data-dial-code="963"
												data-country-code="sy"
											>
												<div className="flag-box">
													<div className="iti-flag sy"></div>
												</div>
												<span className="country-name">
													Syria (‫سوريا‬‎)
												</span>
												<span className="dial-code">
													+963
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tw"
												role="option"
												data-dial-code="886"
												data-country-code="tw"
											>
												<div className="flag-box">
													<div className="iti-flag tw"></div>
												</div>
												<span className="country-name">
													Taiwan (台灣)
												</span>
												<span className="dial-code">
													+886
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tj"
												role="option"
												data-dial-code="992"
												data-country-code="tj"
											>
												<div className="flag-box">
													<div className="iti-flag tj"></div>
												</div>
												<span className="country-name">
													Tajikistan
												</span>
												<span className="dial-code">
													+992
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tz"
												role="option"
												data-dial-code="255"
												data-country-code="tz"
											>
												<div className="flag-box">
													<div className="iti-flag tz"></div>
												</div>
												<span className="country-name">
													Tanzania
												</span>
												<span className="dial-code">
													+255
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-th"
												role="option"
												data-dial-code="66"
												data-country-code="th"
											>
												<div className="flag-box">
													<div className="iti-flag th"></div>
												</div>
												<span className="country-name">
													Thailand (ไทย)
												</span>
												<span className="dial-code">
													+66
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tl"
												role="option"
												data-dial-code="670"
												data-country-code="tl"
											>
												<div className="flag-box">
													<div className="iti-flag tl"></div>
												</div>
												<span className="country-name">
													Timor-Leste
												</span>
												<span className="dial-code">
													+670
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tg"
												role="option"
												data-dial-code="228"
												data-country-code="tg"
											>
												<div className="flag-box">
													<div className="iti-flag tg"></div>
												</div>
												<span className="country-name">
													Togo
												</span>
												<span className="dial-code">
													+228
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tk"
												role="option"
												data-dial-code="690"
												data-country-code="tk"
											>
												<div className="flag-box">
													<div className="iti-flag tk"></div>
												</div>
												<span className="country-name">
													Tokelau
												</span>
												<span className="dial-code">
													+690
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-to"
												role="option"
												data-dial-code="676"
												data-country-code="to"
											>
												<div className="flag-box">
													<div className="iti-flag to"></div>
												</div>
												<span className="country-name">
													Tonga
												</span>
												<span className="dial-code">
													+676
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tt"
												role="option"
												data-dial-code="1868"
												data-country-code="tt"
											>
												<div className="flag-box">
													<div className="iti-flag tt"></div>
												</div>
												<span className="country-name">
													Trinidad and Tobago
												</span>
												<span className="dial-code">
													+1868
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tn"
												role="option"
												data-dial-code="216"
												data-country-code="tn"
											>
												<div className="flag-box">
													<div className="iti-flag tn"></div>
												</div>
												<span className="country-name">
													Tunisia (‫تونس‬‎)
												</span>
												<span className="dial-code">
													+216
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tr"
												role="option"
												data-dial-code="90"
												data-country-code="tr"
											>
												<div className="flag-box">
													<div className="iti-flag tr"></div>
												</div>
												<span className="country-name">
													Turkey (Türkiye)
												</span>
												<span className="dial-code">
													+90
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tm"
												role="option"
												data-dial-code="993"
												data-country-code="tm"
											>
												<div className="flag-box">
													<div className="iti-flag tm"></div>
												</div>
												<span className="country-name">
													Turkmenistan
												</span>
												<span className="dial-code">
													+993
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tc"
												role="option"
												data-dial-code="1649"
												data-country-code="tc"
											>
												<div className="flag-box">
													<div className="iti-flag tc"></div>
												</div>
												<span className="country-name">
													Turks and Caicos Islands
												</span>
												<span className="dial-code">
													+1649
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-tv"
												role="option"
												data-dial-code="688"
												data-country-code="tv"
											>
												<div className="flag-box">
													<div className="iti-flag tv"></div>
												</div>
												<span className="country-name">
													Tuvalu
												</span>
												<span className="dial-code">
													+688
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-vi"
												role="option"
												data-dial-code="1340"
												data-country-code="vi"
											>
												<div className="flag-box">
													<div className="iti-flag vi"></div>
												</div>
												<span className="country-name">
													U.S. Virgin Islands
												</span>
												<span className="dial-code">
													+1340
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ug"
												role="option"
												data-dial-code="256"
												data-country-code="ug"
											>
												<div className="flag-box">
													<div className="iti-flag ug"></div>
												</div>
												<span className="country-name">
													Uganda
												</span>
												<span className="dial-code">
													+256
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ua"
												role="option"
												data-dial-code="380"
												data-country-code="ua"
											>
												<div className="flag-box">
													<div className="iti-flag ua"></div>
												</div>
												<span className="country-name">
													Ukraine (Україна)
												</span>
												<span className="dial-code">
													+380
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ae"
												role="option"
												data-dial-code="971"
												data-country-code="ae"
											>
												<div className="flag-box">
													<div className="iti-flag ae"></div>
												</div>
												<span className="country-name">
													United Arab Emirates
													(‫الإمارات العربية
													المتحدة‬‎)
												</span>
												<span className="dial-code">
													+971
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-gb"
												role="option"
												data-dial-code="44"
												data-country-code="gb"
											>
												<div className="flag-box">
													<div className="iti-flag gb"></div>
												</div>
												<span className="country-name">
													United Kingdom
												</span>
												<span className="dial-code">
													+44
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-us"
												role="option"
												data-dial-code="1"
												data-country-code="us"
											>
												<div className="flag-box">
													<div className="iti-flag us"></div>
												</div>
												<span className="country-name">
													United States
												</span>
												<span className="dial-code">
													+1
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-uy"
												role="option"
												data-dial-code="598"
												data-country-code="uy"
											>
												<div className="flag-box">
													<div className="iti-flag uy"></div>
												</div>
												<span className="country-name">
													Uruguay
												</span>
												<span className="dial-code">
													+598
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-uz"
												role="option"
												data-dial-code="998"
												data-country-code="uz"
											>
												<div className="flag-box">
													<div className="iti-flag uz"></div>
												</div>
												<span className="country-name">
													Uzbekistan (Oʻzbekiston)
												</span>
												<span className="dial-code">
													+998
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-vu"
												role="option"
												data-dial-code="678"
												data-country-code="vu"
											>
												<div className="flag-box">
													<div className="iti-flag vu"></div>
												</div>
												<span className="country-name">
													Vanuatu
												</span>
												<span className="dial-code">
													+678
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-va"
												role="option"
												data-dial-code="39"
												data-country-code="va"
											>
												<div className="flag-box">
													<div className="iti-flag va"></div>
												</div>
												<span className="country-name">
													Vatican City (Città del
													Vaticano)
												</span>
												<span className="dial-code">
													+39
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ve"
												role="option"
												data-dial-code="58"
												data-country-code="ve"
											>
												<div className="flag-box">
													<div className="iti-flag ve"></div>
												</div>
												<span className="country-name">
													Venezuela
												</span>
												<span className="dial-code">
													+58
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-vn"
												role="option"
												data-dial-code="84"
												data-country-code="vn"
											>
												<div className="flag-box">
													<div className="iti-flag vn"></div>
												</div>
												<span className="country-name">
													Vietnam (Việt Nam)
												</span>
												<span className="dial-code">
													+84
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-wf"
												role="option"
												data-dial-code="681"
												data-country-code="wf"
											>
												<div className="flag-box">
													<div className="iti-flag wf"></div>
												</div>
												<span className="country-name">
													Wallis and Futuna
													(Wallis-et-Futuna)
												</span>
												<span className="dial-code">
													+681
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-eh"
												role="option"
												data-dial-code="212"
												data-country-code="eh"
											>
												<div className="flag-box">
													<div className="iti-flag eh"></div>
												</div>
												<span className="country-name">
													Western Sahara (‫الصحراء
													الغربية‬‎)
												</span>
												<span className="dial-code">
													+212
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ye"
												role="option"
												data-dial-code="967"
												data-country-code="ye"
											>
												<div className="flag-box">
													<div className="iti-flag ye"></div>
												</div>
												<span className="country-name">
													Yemen (‫اليمن‬‎)
												</span>
												<span className="dial-code">
													+967
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-zm"
												role="option"
												data-dial-code="260"
												data-country-code="zm"
											>
												<div className="flag-box">
													<div className="iti-flag zm"></div>
												</div>
												<span className="country-name">
													Zambia
												</span>
												<span className="dial-code">
													+260
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-zw"
												role="option"
												data-dial-code="263"
												data-country-code="zw"
											>
												<div className="flag-box">
													<div className="iti-flag zw"></div>
												</div>
												<span className="country-name">
													Zimbabwe
												</span>
												<span className="dial-code">
													+263
												</span>
											</option>{' '}
											<option
												className="country standard"
												id="iti-item-ax"
												role="option"
												data-dial-code="358"
												data-country-code="ax"
											>
												<div className="flag-box">
													<div className="iti-flag ax"></div>
												</div>
												<span className="country-name">
													Åland Islands
												</span>
												<span className="dial-code">
													+358
												</span>
											</option>
										</select>
									</div>
									<input
										type="tel"
										name="telefono"
										required
										enable-intl="1"
										data-ref-label="Telfono"
										id="telefono"
										autoComplete="off"
										placeholder=""
										value={emailTelefono}
										onChange={(e) =>
											setEmailTelefono(e.target.value)
										}
									/>
								</div>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="estado">
									Estado
									<span className="erf-required">*</span>
								</label>
								<select
									id="estado"
									name="estado"
									required
									onChange={(e) =>
										setEmailEstado(e.target.value)
									}
								>
									<option value="Aguascalientes">
										Aguascalientes
									</option>
									<option value="Ciudad de México">
										Ciudad de México
									</option>
									<option value="Estado de México">
										Estado de México
									</option>
									<option value="Guanajuato">
										Guanajuato
									</option>
									<option value="Michoacán">Michoacán</option>
									<option value="Morelos">Morelos</option>
									<option value="Oaxaca">Oaxaca</option>
									<option value="Puebla">Puebla</option>
									<option value="Querétaro">Querétaro</option>
									<option value="Tlaxcala">Tlaxcala</option>
									<option value="Veracruz">Veracruz</option>
									<option value="Otro">Otro</option>
								</select>
							</div>

							<div className="form-item">
								<label htmlFor="municipio">
									Municipio
									<span className="erf-required">*</span>
								</label>
								<input
									type="tel"
									id="municipio"
									name="municipio"
									value={emailCiudad}
									onChange={(e) =>
										setEmailCiudad(e.target.value)
									}
									required
								/>
							</div>
						</div>

						<div className="form-item" style={{ width: '100%' }}>
							<label htmlFor="mensaje">
								Mensaje
								<span className="erf-required">*</span>
							</label>
							<textarea
								style={{ height: 'unset !important' }}
								rows="5"
								id="mensaje"
								required
								onChange={(e) =>
									setEmailMensaje(e.target.value)
								}
							></textarea>
						</div>

						<div className="form-item cb" style={{ width: '100%' }}>
							<input
								type="checkbox"
								name="avisopriv"
								id="aviso"
								aria-invalid="false"
								value={emailAceptoPoliticas}
								onChange={(e) =>
									setEmailAceptoPoliticas(e.target.checked)
								}
							/>
							<span>
								He leído y acepto el{' '}
								<Link to={'/aviso-de-privacidad/'}>
									Aviso de Privacidad
								</Link>
							</span>
						</div>

						<button
							type="submit"
							className="forjadores-btn"
							disabled={emailEnviando}
							style={{
								opacity: emailEnviando ? 0.5 : 1,
							}}
						>
							Enviar
						</button>

						{emailEnviado && (
							<p style={{ color: '#449b51' }}>
								¡Mensaje enviado con éxito!
							</p>
						)}
					</form>
				</div>
			</section>

			<img
				src={inversion_lineas3}
				alt="lienas"
				style={{
					padding: '0 15%',
					boxSizing: 'border-box',
					maxWidth: '100%',
				}}
			/>

			<section className="requisitos">
				<h3 class="subtitulo-3">REQUISITOS:</h3>
				<p
					class="simple-text green-dots"
					style={{ marginBottom: '50px' }}
				>
					<b>•</b> Ser mayor de edad.
					<br />
					<b>•</b> Contar con identificación oficial vigente y
					comprobante de domicilio (no mayor a tres meses)
					<br />
					<b>•</b> Suscribir el contrato de adhesión correspondiente y
					hacer la transferencia a la cuenta que Banco Forjadores te
					proporcione.
				</p>

				<h3 className="subtitulo-3">CONCEPTOS DEL PRODUCTO:</h3>
				<p className="simple-text green-dots">
					<b>Banco Forjadores.-</b> Banco Forjadores S.A., Institución
					de Banca Múltiple, que para su constitución como tal,
					requirió autorización de la Secretaria de Hacienda y Crédito
					Público, con opinión del Banco de México y su supervisión
					está regulada por la Comisión Nacional Bancaria y de Valores
					<br />
					<br />
					<b>Pagaré Forjadores.-</b> Inversión que permite ganar
					atractivos rendimientos en función al plazo y monto que se
					elija, al vencimiento que se decida si el capital y los
					intereses se reinvierten o se depositan a la cuenta bancaria
					que se haya proporcionado.
					<br />
					<br />
					<b>Forjadores CEDE.-</b> Inversión flexible que permite
					disponer de los intereses generados por el dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que se haya proporcionado.
					<br />
					<br />
					<b>Comisión.-</b> Para este producto no se aplica comisión
					alguna
					<br />
					<br />
					<b>UDI.-</b> Es un valor de referencia de valor real
					constante que el Banco de México publica en el Diario
					Oficial de la Federación el valor, en moneda nacional, de la
					unidad de inversión, para cada día del mes
					<br />
					<br />
					<b>GAT.-</b> La Ganancia Anual Total Neta expresada en
					términos porcentuales anuales, para fines informativos y de
					comparación, incorpora los intereses nominales
					capitalizables que generen las operaciones pasivas a plazo,
					retirables en días preestablecidos y de ahorro, que celebren
					las instituciones de crédito y las entidades de ahorro y
					crédito popular con sus clientes, menos todos los costos
					relacionados con la operación, incluidos los de apertura, de
					conformidad con las disposiciones que al efecto emita el
					Banco de México
					<br />
					<br />
					<b>Tasa de Interés.-</b> Es la tasa de rendimiento,
					expresada en términos anuales simples, la cual es fija
					durante el plazo de la inversión antes de impuestos
					<br />
					<br />
					<b>IPAB.-</b> Instituto para la Protección al Ahorro
					Bancario
				</p>
			</section>

			<img
				style={{ width: '100%' }}
				decoding="async"
				src={inversion_lineas_verde}
				data-ll-status="loaded"
			/>

{loadingVigenias ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
						marginBottom: '50px',
					}}
				>
					Cargando infromación...
				</p>
			) : vigencias.length === 0 ? (
				<p
					style={{
						width: '100%',
						textAlign: 'center',
						marginBottom: '50px',
					}}
				>
					No hay información para mostrar
				</p>
			) : (
				<div style={{ marginBottom: '76px' }}>
					<div className="row ipab">
						<img
							style={{
								height: '130px',
								marginLeft: 'auto',
							}}
							decoding="async"
							src={inversion_logoIPAB}
							data-ll-status="loaded"
						/>
						<p
							className="simple-text"
							style={{ margin: '0' }}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(
									vigencias?.parrafo1 ?? '',
								),
							}}
						></p>
					</div>
					<p
						className="simple-text center green-dots"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								vigencias?.parrafo2 ?? '',
							),
						}}
					></p>
				</div>
			)}
		</div>
	);
}

export default ForjadoresCede;
