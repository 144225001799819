import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from "./components/ScrollTop";

import Inicio from './pages/Inicio';
import Contacto from './pages/Contacto';
import Header from './components/Header';
import Footer from './components/Footer';
import CostosComisiones from './pages/CostosComisiones';
import QuienesSomos from './pages/QuienesSomos';
import NuestraHistoria from './pages/QuienesSomos/nuestrahistoria';
import Compromiso from './pages/QuienesSomos/compromiso';
import RelacionInversionistas from './pages/QuienesSomos/relacion';
import TrabajaNostros from './pages/QuienesSomos/trabaja';
import TipsMejorar from './pages/QuienesSomos/tipsmejorar';
import TipsSeguridad from './pages/QuienesSomos/tipsseguridad';
import Cinconsejos from './pages/QuienesSomos/tipsmejorar/cinconsejos';
import Presupuesto from './pages/QuienesSomos/tipsmejorar/presupuesto';
import Ahorro from './pages/QuienesSomos/tipsmejorar/ahorro';
import Inversion from './pages/QuienesSomos/tipsmejorar/inversion';
import Creditos from './pages/QuienesSomos/tipsmejorar/creditos';
import Seguros from './pages/QuienesSomos/tipsmejorar/seguros';
import Consumo from './pages/QuienesSomos/tipsmejorar/consumo';
import TipsABM from './pages/QuienesSomos/tipsseguridad/tipsabm';
import TipsForjadores from './pages/QuienesSomos/tipsseguridad/tipsforjadores';
import ProgramasDeBeneficios from './pages/ProgramasDeBeneficios';
import RecomiendaGana from './pages/RecomiendaGana';
import Contratos from './pages/Contratos';
import Inversiones from './pages/Inversiones';
import PagareForjadores from './pages/PagareForjadores';
import ForjadoresCede from './pages/ForjadoresCede';
import DespachoCobranza from './pages/InfoHeader/DespachoCobranza';
import PuntosDePago from './pages/InfoHeader/PuntosPago';
import OficinaServicio from './pages/InfoHeader/OficinasServicio';
import BuroIdentidades from './pages/Inicio/BuroIdentidades';
import CreditoGrupal from './pages/Creditos/CreditoGrupal';
import CreditoAdicional from './pages/Creditos/CreditoAdicional';
import CreditoInterciclo from './pages/Creditos/CreditoInterciclo';
import Glosario from './pages/Creditos/Glosario';
import Asistencias from './pages/AsistenciaCombo';
import InfoCovid from './pages/Inicio/InfoCovid';
import ForjadorasCorazon from './pages/Inicio/ForjadorasCorazon';
import UnidadEspe from './pages/Inicio/UnidadEspe';
import AvisoPrivacidad from './pages/Inicio/AvisoPrivacidad';
import ConsultaReclamaciones from './pages/Inicio/ConsultaReclamaciones';
import InfoTrim from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/infoTrim';
import EdosFinan from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/edosfinan';
import RepAnim from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/repanim';
import CalifRiesgo from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/califriesgo';
import ObligSubor from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/obligsubor';
import RevInfo from './pages/QuienesSomos/RelacionInversionistas/InfoFinanciera/revinfo';
import EstatutosSociales from './pages/QuienesSomos/EstatutosSociales';
import EstatutosSocialesFile from './pages/QuienesSomos/EstatutosSociales/EstatutosSocialesFile';
import ModificacionesEstatutos from './pages/QuienesSomos/EstatutosSociales/ModificacionesEstatutos';
import Asambleas from './pages/QuienesSomos/Asambleas';
import AsambleasAccionistas from './pages/QuienesSomos/Asambleas/AsambleasAccionistas';
import AsambleasAccionistasArchivos from './pages/QuienesSomos/Asambleas/AsambleasAccionistasArchivos';
import AsambleasTenedores from './pages/QuienesSomos/Asambleas/AsambleasTenedores';
import AsambleasTenedores2022 from './pages/QuienesSomos/Asambleas/AsambleasTenedores2022';
import InformeRemuneraciones from './pages/QuienesSomos/InformeRemuneraciones';


function App() {
	return (		
		<Router>
			<ScrollToTop />
			<Header/>
			<Routes>
				<Route path="/" element={<Inicio />} />
				<Route path="/inicio" element={<Inicio />} />
				<Route path="/buro-de-entidades-financieras/" element={<BuroIdentidades />} />
				<Route path="/seguros/covid/informacion-de-interes-sobre-covid/" element={<InfoCovid />} />
				<Route path="/forjadoras-de-corazon/" element={<ForjadorasCorazon />} />
				<Route path="/unidad-especializada-de-atencion-a-usuarios/" element={<UnidadEspe />} />
				<Route path="/aviso-de-privacidad/" element={<AvisoPrivacidad />} />
				<Route path="/consultas-reclamaciones-y-aclaraciones/" element={<ConsultaReclamaciones />} />

				{/* LINKS HEADER */}
				<Route path='/despachos-de-cobranza' element={<DespachoCobranza />} />
				<Route path='/puntos-de-pago' element={<PuntosDePago />} />
				<Route path='/oficinas-de-servicio' element={<OficinaServicio />} />

				{/* QUIENES SOMOS */}
				<Route path="/quienes-somos" element={<QuienesSomos />} />

				<Route path="/quienes-somos/nuestra-historia/" element={<NuestraHistoria />} />
				<Route path="/quienes-somos/compromiso/" element={<Compromiso />} />

				<Route path="/quienes-somos/relacion-con-inversionistas/" element={<RelacionInversionistas />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/" element={<EstatutosSociales />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/estatutos-sociales/" element={<EstatutosSocialesFile />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/estatutos-sociales/modificacion-a-los-estatutos/" element={<ModificacionesEstatutos />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/asambleas/" element={<Asambleas />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-accionistas/" element={<AsambleasAccionistas />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-accionistas/:year" element={<AsambleasAccionistasArchivos />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-tenedores/" element={<AsambleasTenedores />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/asambleas/asambleas-de-tenedores/asambleas-de-tenedores-2022/" element={<AsambleasTenedores2022 />} />
				<Route path="/quienes-somos/relacion-con-inversionistas/informe-de-remuneraciones/" element={<InformeRemuneraciones />} />

				<Route path="/quienes-somos/informacion-de-interes/informacion-trimestral/" element={<InfoTrim />} />
				<Route path="/quienes-somos/informacion-de-interes/estados-financieros-dictaminados/" element={<EdosFinan />} />
				<Route path="/quienes-somos/informacion-de-interes/reporte-de-administracion-anual/" element={<RepAnim />} />
				<Route path="/quienes-somos/informacion-de-interes/revelacion-de-informacion-relativa-a-la-razon-de-apalancamiento/" element={<RevInfo />} />
				<Route path="/quienes-somos/informacion-de-interes/calificacion-de-riesgo/" element={<CalifRiesgo />} />
				<Route path="/quienes-somos/informacion-de-interes/obligaciones-subordinadas/" element={<ObligSubor />} />

				

				<Route path="/quienes-somos/trabaja-con-nosotros/" element={<TrabajaNostros />} />

				<Route path="/quienes-somos/tips-para-mejorar-tu-negocio/" element={<TipsMejorar />} />
				<Route path="/tips-para-mejorar-tu-negocio/50-consejos-para-emprender-tu-negocio-forjador/" element={<Cinconsejos />} />
				<Route path="/tips-para-mejorar-tu-negocio/presupuesto/" element={<Presupuesto />} />
				<Route path="/tips-para-mejorar-tu-negocio/ahorro/" element={<Ahorro />} />
				<Route path="/tips-para-mejorar-tu-negocio/inversion/" element={<Inversion />} />
				<Route path="/tips-para-mejorar-tu-negocio/credito/" element={<Creditos />} />
				<Route path="/tips-para-mejorar-tu-negocio/seguros/" element={<Seguros />} />
				<Route path="/tips-para-mejorar-tu-negocio/consumo-responsable/" element={<Consumo />} />

				<Route path="/quienes-somos/tips-de-seguridad/" element={<TipsSeguridad />} />
				<Route path="/quienes-somos/tips-de-seguridad/tips-de-la-abm/" element={<TipsABM />} />
				<Route path="/quienes-somos/tips-de-seguridad/tips-de-banco-forjadores/" element={<TipsForjadores />} />

				{/* CREDITOS */}
				<Route path='/creditos/credito-grupal/' element={<CreditoGrupal />} />
				<Route path='/creditos/credito-adicional/' element={<CreditoAdicional />} />
				<Route path='/creditos/credito-interciclo/' element={<CreditoInterciclo />} />
				<Route path='/creditos/credito-grupal/glosario-de-terminologia-legal-credito-grupal/' element={<Glosario />} />

				{/* INVERSIONES */}
				<Route path="/inversiones" element={<Inversiones />} />
				<Route
					path="/inversiones/pagare-forjadores"
					element={<PagareForjadores />}
				/>
				<Route
					path="/inversiones/forjadores-cede"
					element={<ForjadoresCede />}
				/>

				{/* ASISTENCIAS COMBO */}
				<Route path='/asistencias-combo' element={<Asistencias />} />

				{/* BENEFICIOS */}
				<Route
					path="/programas-de-beneficios"
					element={<ProgramasDeBeneficios />}
				/>
				<Route
					path="/programas-de-beneficios/recomienda-y-gana"
					element={<RecomiendaGana />}
				/>

				{/* CONTACTO */}
				<Route path="/contacto" element={<Contacto />} />		

				{/* CONTRATOS */}
				<Route path="/contratos" element={<Contratos />} />
				<Route path="/contratos/:id" element={<Contratos />} />
				<Route path="/contratos/:id/:id2" element={<Contratos />} />

				{/* COSTOS Y COMISIONES */}
				<Route
					path="/costos-y-comisiones-de-productos-de-credito"
					element={<CostosComisiones />}
				/>	
				

			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
