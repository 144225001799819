import { Link } from 'react-router-dom';
import encabezado from '../../../assets/images/Encabezados/encabezado_informacion_corporativa.png';
import icono_pdf from '../../../assets/images/Iconos/icono_pdf.png';

import Informe_de_Remuneraciones_2015 from '../../../assets/documents/Informe-de-Remuneraciones-2015.pdf';
import Informe_de_Remuneraciones_2016 from '../../../assets/documents/Informe-de-Remuneraciones-2016.pdf';
import Informe_de_Remuneraciones_2017 from '../../../assets/documents/Informe-de-Remuneraciones-2017.pdf';
import Informe_de_Remuneraciones_2018 from '../../../assets/documents/Informe-de-Remuneraciones-2018.pdf';
import Informe_de_Remuneraciones_2019 from '../../../assets/documents/Informe-de-Remuneraciones-2019.pdf';
import Informe_de_Remuneraciones_2020 from '../../../assets/documents/Informe-del-Sistema-de-Remuneracion-2020.pdf';
import Informe_de_Remuneraciones_2021 from '../../../assets/documents/Informe-del-Sistema-de-Remuneracion-2021-1.pdf';
import Informe_de_Remuneraciones_2022 from '../../../assets/documents/REMUNERACIONES_2022-A_-ANUAL-20221.pdf';
import Informe_de_Remuneraciones_2023 from '../../../assets/documents/Informe-de-Remuneraciones-2023.pdf';

function InformeRemuneraciones() {
	return (
		<div class="post-inner">
			<div class="entry-content">
				<div class="information-intro">
					<img
						src={encabezado}
						decoding="async"
						class="lazyloaded"
						data-ll-status="loaded"
					/>
					<h1>
						<small>INFORME DE REMUNERACIONES</small>
					</h1>
				</div>
				<div className="estatutos-folders information-files">
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2015}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2015
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2016}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2016
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2017}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2017
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2018}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2018
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2019}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2019
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2020}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2020
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2021}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2021
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2022}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2022
					</Link>
					<Link
						className="file"
						style={{
							width: '15%',
							padding: '2.5%',
						}}
						target="_blank"
						rel="noopener noreferrer"
						to={Informe_de_Remuneraciones_2023}
					>
						<img src={icono_pdf} />
						Informe de Remuneraciones 2023
					</Link>
				</div>
				<p></p>
			</div>
		</div>
	);
}

export default InformeRemuneraciones;
